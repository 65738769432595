<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.identity-message {
    background-color: #fff;
    padding: 10px;
    margin: 0 0 50px;
    .identity-status {
        font-weight: bold;
        span {
            color: #ef3737;
        }
    }
    .message {
        white-space: pre-wrap;
    }
}
.h3title {
    font-weight: bold;
    font-size: 20px;
}
h4 {
    font-weight: bold;
    font-size: 16px;
    margin: 15px 0px;
}
p {
    margin: 0px 0 10px 20px;
}
.identity-text {
    font-weight: bold;
    font-size: 18px;
    margin: 0px 0 20px 20px;
    color: #f10000;
}
form {
    background-color: #fff;
    padding: 30px;
    >div {
        margin: 0 0 20px;
    }
    img {
        height: 150px;
        object-fit: cover;
    }
    p {
        margin: 10px 0 0;
        color: #565656;
        font-size: 14px;
    }
    .btn {
        display: inline-block;
        background-color: $color-main-seller;
        color: #fff;
        width: 200px;
    }
}
</style>
<template>
<div>
    <div class="pageContent">
        <div class="content listing-login">
            <h2 class="h2Product page-title">本人確認書類</h2>
            <div class="identity-message" v-if="origin.identity_status != 0">
                <p v-if="origin.identity_status == 1" class="identity-status">現在の状況：審査中</p>
                <div v-if="origin.identity_status == 2">
                    <p class="identity-status">現在の状況：<span>再提出が必要です</span></p>
                    <p>再提出の理由<br>------------------------------------------</p>
                    <p class="message">{{ origin.identity_message }}</p>
                </div>
                <p v-if="origin.identity_status == 3" class="identity-status">現在の状況：審査完了</p>
            </div>
            <div v-if="origin.identity_status == 0 || origin.identity_status == 2">
                <div class="mainContent">
                    <div class="content-box">
                        <p class="identity-text">出品を行うには、本人確認書類の提出がかならず必要です。</p>
                        <p class="note">本人確認書類は、画像ファイルのアップロードでお送りいただきます。<br>画像に不備があった場合は再度アップロードをお願いすることになりますので、お申し込みの前に下記内容をご確認ください。</p>
                        <p class="note">本人確認書類は、写真や記載内容がはっきりと読み取れる画像をお選びください。また全体が入るように撮影してください。</p>
                        <br>
                        <p class="note">ファイル形式:JPG<br>
                        ファイルサイズ:5MB以内</p>
                        <br>
                        <p class="note">※申し込みは日本国籍のみに限る</p>
                        <h3 class="h3title">本人確認書類として利用できるもの</h3>
                        <h4>（1）運転免許証</h4>
                            <ul>
                                <li>1.顔写真、現住所の記載があるもの</li>
                                <li>2.有効期限内のもの</li>
                                <li>3.各都道府県公安委員会発行のもの（国際免許証は除く）</li>
                            </ul>
                            <p class="note">※免許証の場合、顔写真は必須になります。<br>
                            　不鮮明もしくは顔の一部または全部が隠れて確認できない時は再度ご送付いただきます。</p>
                            <p class="note">※裏面をアップロードする際は、
                            　必ず「臓器提供意思表示欄」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。
                            　該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。</p>
                            <h4>（2）健康保険証または住民基本台帳カード ＋ 公共料金領収書・住民票のいずれか</h4>
                            <h4>（3）日本国パスポート</h4>
                            <ul>
                                <li>1.現住所の記載があるもの</li>
                                <li>2.有効期限内のもの</li>
                            </ul>
                            <p class="note">※パスポートは必ず、「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。<br>
                            該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。</p>

                            <h4>（4）身体障害者手帳</h4>
                            <ul>
                                <li>1.現住所の記載があるもの</li>
                                <li>2.有効期限内のもの</li>
                            </ul>
                            <p class="note">※身体障害者手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。<br>該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。</p>

                            <h4>（5）療育手帳</h4>
                            <ul>
                            <li>1.発行日の記載がある、または有効期限内のもの</li>
                            <li>2.顔写真、生年月日、現住所の記載のあるもの</li>
                            </ul>
                            <p class="note">※療育手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を
                            　無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。<br>該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。</p>

                            <h4>（6）精神障害者保健福祉手帳</h4>
                            <ul>
                            <li>1.発行日の記載がある、または有効期限内のもの</li>
                            <li>2.顔写真、生年月日、現住所の記載のあるもの</li>
                            </ul>
                            <p class="note">※精神障害者保健福祉手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。<br>
                            該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。</p>

                            <h4>（7）マイナンバーカード（個人番号カード）</h4>
                            <ul>
                            <li>1.マイナンバーカードの氏名・住所の記載面（カード表面)</li>
                            </ul>
                            <p class="note">
                            ※マイナンバー記載面（カード裏面）はご利用になれません。送付された場合は破棄させていただきます。<br>
                            ※カード表面に記載の「性別」「臓器提供意思」は無地の紙等で覆い隠した（マスキング）状態で送付ください。<br>
                            表示状態で送付された場合は破棄させていただきます。<br>
                            ※通知カードは本人確認書類としてご利用になれません。</p>

                    </div>
                </div>
                <div>
                    <form class="identification">
                        <div>
                            <label class="contentlabel">表面</label>
                            <input type="file" ref="identity_img1" @change="uploadImage1">
                            <div v-show="identity_url1"><img :src="identity_url1" alt="本人確認書類(表)"></div>
                        </div>
                        <div>
                            <label class="contentlabel">裏面</label>
                            <input type="file" ref="identity_img2" @change="uploadImage2">
                            <div v-show="identity_url2"><img :src="identity_url2" alt="本人確認書類(裏)"></div>
                        </div>
                        <div>
                            <label class="contentlabel">保険証の裏・表、住民票</label>
                            <input type="file" ref="identity_img3" @change="uploadImage3">
                            <div v-show="identity_url3"><img :src="identity_url3" alt="保険証の裏・表、住民票"></div>
                        </div>
                        <p>※最大画像容量は5MB以内です</p>
                        <button type="button" class="btn" @click="submit">送付する</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../store/modules/user-store";

export default {
    data:function() {
        return {
            identity_url1: "",
            identity_url2: "",
            identity_url3: "",
            image1: "",
            image2: "",
            image3: "",
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userID", "origin"])
    },
    methods: {
        uploadImage1() {
            this.identity_url1 = URL.createObjectURL(this.$refs.identity_img1.files[0]);
            this.image1 = this.$refs.identity_img1.files[0];
        },
        uploadImage2() {
            this.identity_url2 = URL.createObjectURL(this.$refs.identity_img2.files[0]);
            this.image2 = this.$refs.identity_img2.files[0];
        },
        uploadImage3() {
            this.identity_url3 = URL.createObjectURL(this.$refs.identity_img3.files[0]);
            this.image3 = this.$refs.identity_img3.files[0];
        },
        submit() {
            const config = {
                header: {
                    "Content-Type": "multipart/form-data"
                }
            };
            var formData = new FormData();
            if(this.image1) {
                formData.append("image1", this.image1);
            }
            if(this.image2) {
                formData.append("image2", this.image2);
            }
            if(this.image3) {
                formData.append("image3", this.image3);
            }
            if(!this.image1) {
                alert("表面のファイルを選択してください")
            } else {
                axios.post(`/seller/identity/${this.userID}`, formData, config)
                .then((res) => {
                    this.$router.push("/seller/identity-complete");
                });
            }
        },
    }
}
</script>