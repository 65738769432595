var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("パートナー利用規約")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg row" }, [
        _c("div", { staticClass: "regMail regContent col-12" }, [
          _c("div", { staticClass: "terms" }, [_c("term-area")], 1)
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }