<style lang="scss" scoped>
@import "../../../sass/variables";
h1 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3px;
    color: #565656;
    padding: 30px 45px 0;
    margin-bottom: 20px;
    &:before {
        content: "";
        position: relative;
        top: 1px;
        left: -14px;
        border-left: 4px solid;
        border-color: $color-main-free;
    }
}
.news-body {
    padding: 30px;
    margin: 50px 30px 0;
    background-color: #fff;
    .news-date {
        font-size: 12px;
        margin: 0 0 10px;
    }
    .news-text {
        min-height: 200px;
        white-space: pre-wrap;
    }
    button {
        width: 100px;
        padding: 8px 0;
        border: none;
        background-color: $color-main-free;
        color: #fff;
        &:hover {
            background-color: #07c0ce;
        }
    }
}
@media screen and (max-width: 896px) {
    h1 {
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        &:before {
            top: 0;
            left: -8px;
        }
    }
    .news-body {
        margin: 20px 0;
    }
}
</style>
<template>
    <div>
        <h1>{{ news.name }}</h1>
        <div class="news-body">
            <p class="news-date">{{ news.start_date }}</p>
            <p class="news-text">{{ news.body }}</p>
            <button @click="$router.go(-1)">戻る</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    props: {
        newsId: Number,
    },
    data() {
        return {
            news: [],
        }
    },
    created() {
        this.getNews();
    },
    methods: {
        getNews() {
            axios.get("/news/api/" + this.newsId).then(response => {
            this.news = response.data;

            this.news.start_date = dayjs(this.news.start_date).format('YYYY/MM/DD');
            });
        },
    },
}
</script>