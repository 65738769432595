<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.vue-table {
    padding: 30px 30px 0;
    .table-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;
        button {
            padding: 10px;
            border: none;
            background-color: $color-main-seller;
            color: #fff;
            &:hover {
                background-color: #8dd08d;
            }
        }
        form>div {
            width: 530px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 0 10px;
            font-size: 15px;
    
            .category-select {
                select {
                    height: 27px;
                    width: 160px;
                }
            }
            button {
                width: 160px;
                padding: 3px;
            }
        }
    }
    .item-total {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
        >div div {
            width: 120px;
            margin: 2px 1px;
            padding: 2px 0;
            border: 1px solid #000;
            text-align: center;
        }
        .total-text {
            font-weight: bold;
        }
        .total-reward {
            margin: 0 0 0 122px;
        }
    }
    .table-cell {
        min-height: 30px;
        border: 1px solid #000;
        padding: 2px;
        text-align: center;
        min-width: 5rem;
    }
    .item-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
@media screen and (max-width: 896px) {
    .pc_cont {
        display: none !important;
    }
    .vue-table {
        padding: 0;
        .table-option {
            display: initial;
            form>div {
                width: auto;
                justify-content: flex-start;
                button {
                    width: 50px;
                    padding: 3px;
                }
            }
            .csv_download {
                position: absolute;
                right: 15px;
                top: 55px;
            }
        }
        .item-total {
            >div div {
                width: 22vw;
            }
            .total-reward {
                margin: 0
            }
        }
        .table-cell {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="pageContent buyer">
    <div class="content no-login">
        <h1 class="page-title">購入者情報</h1>
        <div class="vue-table">
            <div class="table-option">
                <form @submit.prevent="itemFind">
                    <div class="pc_cont">
                        <div>
                            <div>期間</div>
                            <input type="date" v-model="keyword.startDate">〜<input type="date" v-model="keyword.endDate">
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="pc_cont">商品名</div>
                            <input type="text" v-model="keyword.item">
                        </div>
                        <div class="pc_cont">
                            <div>ID検索</div>
                            <input type="text" v-model="keyword.id">
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary">検索</button>
                        </div>
                    </div>
                </form>
                <div class="csv_download">
                    <vue-json-to-csv :json-data="csvData" csv-title="購入者情報" :labels="labels">
                        <button @click="downloadCSV">csvをダウンロード</button>
                    </vue-json-to-csv>
                </div>
            </div>
            <vuetable 
                ref="vuetable"
                noDataTemplate = "購入者情報がありません。"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                :data-manager="dataManager"
                :append-params="moreParams"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                class="table"
            >
                <div class="table-cell" slot="created_at" slot-scope="props">{{ props.rowData.created_at }}</div>
                <div class="table-cell" slot="order_id" slot-scope="props">{{ props.rowData.order_id }}</div>
                <div class="table-cell" slot="customer_id" slot-scope="props">{{ props.rowData.customer_id }}</div>
                <div class="table-cell" slot="customer_name" slot-scope="props">{{ props.rowData.customer_name }}</div>
                <div class="table-cell" slot="customer_email" slot-scope="props">{{ props.rowData.customer_email }}</div>
                <div class="table-cell" slot="item_id" slot-scope="props">{{ props.rowData.item_id }}</div>
                <div class="table-cell item-name" slot="item_name" slot-scope="props">{{ props.rowData.item_name | omittedText }}</div>
                <div class="table-cell" slot="partner_id" slot-scope="props">{{ props.rowData.partner_id }}</div>
                <div class="table-cell" slot="partner_name" slot-scope="props">{{ props.rowData.partner_name }}</div>
                <div class="table-cell" slot="selling_price" slot-scope="props">{{ price(props.rowData.selling_price) }}</div>
                <div class="table-cell" slot="affiliate_margin" slot-scope="props">{{ margin(props.rowData.affiliate_margin) }}</div>
                <div class="table-cell" slot="payment" slot-scope="props">{{ props.rowData.payment }}</div>
                <div class="table-cell" slot="pay_status" slot-scope="props">{{ props.rowData.pay_status }}</div>
            </vuetable>
            <div class="pagenation">
                <vuetable-pagination ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {Vuetable, VuetablePagination} from 'vuetable-2';
import VueJsonToCsv from "vue-json-to-csv";
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import {num2Yen} from "../../common/com-func";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../store/modules/user-store";

export default {
    components: {
        VueJsonToCsv,
        Vuetable,
        VuetablePagination
    },
    data() {
        return {
            keyword: {
                startDate: "",
                endDate: "",
                id: "",
                item: "",
            },
            fields: [
                {name: "created_at",title: '取引日時', sortField: 'created_at'},
                {name: "order_id",title: '購入ID', sortField: 'order_id'},
                {name: 'customer_id',title: '購入者ID'},
                {name: 'customer_name',title: '購入者名'},
                {name: 'customer_email',title: '購入者メールアドレス'},
                {name: "item_id",title: '商品ID'},
                {name: "item_name",title: '商品名'},
                {name: 'partner_id',title: '紹介者ID'},
                {name: 'partner_name',title: '紹介者'},
                {name: 'selling_price',title: '価格'},
                {name: 'affiliate_margin',title: '紹介料'},
                {name: 'payment',title: '支払い方法'},
                {name: 'pay_status',title: '状態'},
            ],
            moreParams: {
                sort: 'order_id',
                sort: 'created_at',
            },
            perPage: 30,
            data: [],
            csvData: [],
            labels: {
                created_at: { title: "取引日時" },
                order_id: { title: "購入ID" },
                customer_id: { title: "購入者ID" },
                customer_name: { title: "購入者名" },
                customer_email: { title: "購入者メールアドレス" },
                item_id: { title: "商品ID" },
                item_name: { title: "商品名" },
                partner_id: { title: "紹介者ID" },
                partner_name: { title: "紹介者" },
                selling_price: { title: "価格" },
                affiliate_margin: { title: "紹介料" },
                payment: { title: "支払い方法" },
                pay_status: { title: "状態" },
            },
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userID"])
    },
    watch: {
        data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
        }
    },
    created() {
        this.getOrder();
    },
    methods: {
        getOrder() {
            axios.get("/order/buyer").then(response => {
            this.data = response.data;

            for(let i=0; i<this.data.length; i++ ) {
                const item_date = new Date(this.data[i].created_at);
                const startDate = new Date(this.keyword.startDate);
                const endDate = new Date(this.keyword.endDate);
                if(this.keyword.startDate != "" && this.data[i]) {
                    if(startDate - item_date > 32400000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.endDate != "" && this.data[i]) {
                    if(endDate - item_date <= -54000000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }

                if(this.keyword.item != "" && this.data[i]) {
                    if(!this.data[i].item_name.includes(this.keyword.item)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.id != "" && this.data[i]) {
                    if(!String(this.data[i].order_id).includes(this.keyword.id)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
            }
            if(this.data.length == 0) {
                this.data = {};
            }

            for(let i=0; i<this.data.length; i++ ) {
                this.data[i].customer_name = this.data[i].customer.family_name +this.data[i].customer.first_name;
                this.data[i].customer_email = this.data[i].customer.email;
                this.data[i].item_id = this.data[i].item.uuid;
                this.data[i].affiliate_margin = this.data[i].item.affiliate_margin;
                if(this.data[i].partner) {
                    this.data[i].partner_name = this.data[i].partner.family_name +this.data[i].partner.first_name;
                }

                if(this.data[i].payment == 0) {this.data[i].payment = "クレジット"} 
                else if (this.data[i].payment == 1) {this.data[i].payment = "銀行"}

                if(this.data[i].pay_status == 0) {this.data[i].pay_status = "未支払い"}
                else if(this.data[i].pay_status == 1) {this.data[i].pay_status = "支払い済"}
                else if(this.data[i].pay_status == 2) {this.data[i].pay_status = "キャンセル"}
                else if(this.data[i].pay_status == 3) {this.data[i].pay_status = "返金済み"}
                else if(this.data[i].pay_status == 4) {this.data[i].pay_status = "継続解除"}

                this.csvData = this.data;
                const csvData = this.csvData[i];
                csvData.created_at = dayjs(this.data[i].created_at).format('YYYY/MM/DD');
            }
            });
            
        },
        itemFind() {
            this.getOrder();
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;
            let local = this.data;

            if (sortOrder.length > 0) {
                local = _.orderBy(
                local,
                sortOrder[0].sortField,
                sortOrder[0].direction
            );
        }

        pagination = this.$refs.vuetable.makePagination(
            local.length,
            this.perPage
            );
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },
        getSortParam(sortOrder) {
            return sortOrder.map(function(sort) {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            }).join(',')
        },
        price:function(price) {
            return num2Yen(price);
        },
        margin:function(margin) {
            if(margin){
                return margin+"%"
            }
        },
        createDay:function(date) {
            return dayjs(date).format('YYYY/MM/DD');
        },
        downloadCSV () {
            this.csvData.push();
        }
    },
    filters: {
        omittedText(text) {
            // 10文字以降は...を表示する
            return text.length > 10 ? text.slice(0, 10) + "..." : text;
        },
    },

}
</script>