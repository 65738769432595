import { render, staticRenderFns } from "./law.vue?vue&type=template&id=3bc7bbaa&scoped=true&"
import script from "./law.vue?vue&type=script&lang=js&"
export * from "./law.vue?vue&type=script&lang=js&"
import style0 from "./law.vue?vue&type=style&index=0&id=3bc7bbaa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc7bbaa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/ryoyoshihara/Documents/WorkSpace/DIGITAB(BaseLayer)/base_layer_user/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bc7bbaa')) {
      api.createRecord('3bc7bbaa', component.options)
    } else {
      api.reload('3bc7bbaa', component.options)
    }
    module.hot.accept("./law.vue?vue&type=template&id=3bc7bbaa&scoped=true&", function () {
      api.rerender('3bc7bbaa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/service/parts/law.vue"
export default component.exports