<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.content {
    padding: 0 1rem;
}
.terms {
    margin: 1rem;
    padding: 1rem;
    border: 1px solid #333;
    height: 20rem;
    overflow-y: auto;
}
.action {
    text-align: center;
    .btn-primary.main {
        background-color: $color-main-seller;
        border-color: $color-main-seller;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">出品者登録</h1>
        <div class="reg row">
            <div class="regMail regContent ">
                <div class="row">
                    <div class="col-12">
                        <div class="terms">
                            <h5>出品者利用規約</h5>
                            <term-area></term-area>
                        </div>
                        <div class="action">
                            <a href="/seller/info"><button type="button" class="btn btn-primary main" @click="decide">規約に合意して登録する</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TermBun from "../service/parts/term-seller.vue";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../store/modules/user-store";
import axios from 'axios';

export default {
    components: {
        "term-area" : TermBun
    },
    data:function() {
        return {
            member: {
                owner_status: 1
            }
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userID"])
    },
    methods: {
        decide:function() {
            axios.put(`/seller/regist/${this.userID}`, this.member);
        }
    }
}
</script>