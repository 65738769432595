<style lang="scss" scoped>
.scroll.high {
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 14px;
    }
    ul {
        padding-left: 22px;
        font-size: 13px;
        list-style: disc;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 896px) {
    p {
        font-size: 12px;
    }
}
</style>
<template>
<div class="scroll high">
    <p>当社は、本個人情報保護方針を遵守し利用者個人に関する情報の重要性を認識し、当社のASP、決済システム・アフィリエイトシステム、などの事業サービスを安全に提供していく上で利用者の取り扱う個人情報を保護し、適切に管理・利用いたします。</p>

    <p>「個人情報保護管理者」を設置し、利用者様の個人情報の取扱いを定めて、個人情報保護管理者の監督のもと適切に管理します。</p>
    <p>当社は、必要な範囲に限定して利用目的を特定し、その目的達成のために必要な限度で公正かつ適正に個人情報の取得、利用及び提供を行います。また、取得した個人情報の目的外利用をしないよう処置を講じます。</p>
    <p>当社が取得した個人情報は、当社「個人情報の取扱いについて」の規定に従って利用いたします。</p>
    <p>個人情報を取り扱う業務を他の会社に委託する場合、個人情報を適正に取り扱うと認められるものを選定し、委託契約において、安全管理措 置、秘密保持、再委託の条件、委託契約終了時の個人情報の返却等その他の個人情報の取り扱いに関する事項について適正に定め、必要かつ適切な監督を実施します。</p>
    <p>当社が保有する個人情報の開示、訂正、提供範囲の変更や削除を本人から依頼された場合、またこの件に関して本人から苦情や相談を受けた場合には、適切な本人確認をさせて頂いた上で、合理的な範囲ですみやかに対応します。</p>
    <p>個人情報の取り扱いに関する法令、国が定める指針その他の規範を、常に最新状態に維持するとともにこれを遵守いたします。</p>
    <p>個人情報を正確かつ最新の状態に保つとともに、個人情報への不正アクセス、個人情報の漏えい、滅失、き損等の予防に努め、情報セキュリティの向上、是正処置を講じます。</p>


    <p>個人情報保護に関する管理規定及び管理体制を整備し、全社員で徹底して運用するとともに定期的な見直しを行い、継続的な改善に努めます。</p>


    <h3>1. 個人情報の取扱事業者の名称</h3>
    <p>株式会社ソリッドアライズ</p>

    <h3>2. 個人情報保護管理連絡先</h3>
    <p>support@digi-tab.net</p>

    <h3>3. 定義</h3>
    <p>「個人情報」とは、利用者や従業員等その他の個人に関する情報であって、当該情報に含まれる住所、氏名、電話番号等によって、特定の個人を識別することが できるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含む。）を指します。</p>

    <h3>4. 個人情報の取扱い</h3>
    <p>当社は、個人情報の取り扱いに関して、個人情報の保護に関する法律（以下「個人情報保護法」といいます）をはじめとする個人情報に関する法令およびその他の規範を遵守します。</p>

    <p>利用者から個人情報をご提供いただく場合は、予め通知又は公表させていただいた目的の達成のために必要な範囲内で利用いたします。本人から直接書面以外で取得した場合は、あらかじめ利用目的を公表または速やかに本人に通知を行います。</p>

    <p>当社は、本人の同意なく利用目的の範囲を超えた個人情報のお取扱を行いません。目的の範囲を超えて利用する必要が生じた 場合は、その旨利用者にご連絡（通知）し、かつ同意をいただいた場合のみ使用します。。また、個人情報を第三者に提供・開示等する場合は、法令の定める手続きに則って行います。</p>

    <h3>5. 取得する個人情報の利用目的</h3>
    <p>当社は、以下の目的のため、その範囲内においてのみ、個人情報を収集・利用（分析・統計等を含みます。）します。</p>

    <h4>個人情報の利用目的</h4>

    <h5>1.顧客情報・弊社サービス登録者情報</h5>
    <ul>
        <li>決済システム・アフィリエイトシステムなど、当社のサービス（以下総称して「当社サービス」といいます）を提供または停止するうえで必要な業務の履行のため</li>
        <li>利用者の本人確認のため</li>
        <li>利用者との取引・契約を適切かつ円滑に履行するため</li>
        <li>既存サービス・既存商品の改善または新サービス・新商品の開発のため</li>
        <li>新サービスの案内、メンテナンス（障害情報を含む）のお知らせ等、利用者に有益かつ必要と思われる情報の提供のため</li>
        <li>当社サービスの解約後、業務遂行上で必要となる当社からの問い合わせ、確認、および当社サービス向上のための意見収集のため</li>
        <li>当社サービスに関する情報等の案内や広告のため</li>
    </ul>

    <h5>2.お問合せ者情報</h5>
    <ul>
        <li>お問合せに回答するため</li>
    </ul>

    <h3>6. 第三者提供</h3>
    <p>当社は、次の場合を除き、利用者の事前のご承認なく個人情報を第三者（個人情報保護法第23条第4項において「第三者」に該当しないとされている者（委託先、事業継承先、共同利用者）を除きます）には提供しません。</p>
    <ul>
        <li>集計および分析等により得られたものを、個人を識別または特定できない態様にて提携先等第三者に開示または提供する場合</li>
        <li>裁判所の発する令状その他裁判所の決定、命令または法令に基づき開示する場合</li>
        <li>検察・警察・監督官庁からの照会があった場合</li>
        <li>人の生命、身体又は財産の保護に必要な場合</li>
        <li>公衆衛生・児童の健全育成に特に必要な場合</li>
    </ul>

    <h3>7. 個人情報のご提出の任意性</h3>
    <p>個人情報のご提出は皆様の自由なご判断に任されます。ただし、必須項目をご提出いただけない場合は、弊社サービスをご利用いただけない場合があります。</p>

    <h3>8. クッキーならびに携帯電話の個体識別番号の利用・外部リンク</h3>
    <p>当社では、クッキーやＩＰアドレス情報ならびに携帯電話の個体識別番号、インターネット利用者識別符号については、それらは単独で個人を特定できる情報であるとは考えません。</p>
    <p>また、それらの取得は利用者により便利に当サイトをご利用いただくためのものであり、利用者のプライバシーを侵害したり、利用者のコンピューターへ悪影響を及ぼすものではありません。</p>
    <p>当社では、クッキーならびに携帯電話の個体識別番号、インターネット利用者識別符号をお申し込み、商品の購入、利用者の動向調査等の為に使用しています。これらを利用者が使用しない設定としている場合、当ウェブサイトで使用できる機能については制限される場合があります。</p>
    <p>当社サービスは、いくつかの外部サイトへのリンクを含みますが、個人情報を共有するものではありません。リンク先ウェブサイトにて行われる個人情報の収集に関しては当社では一切責任を負えませんので、リンク先ウェブサイトの個人情報保護方針を必ずご参照下さい。</p>

    <h3>9. 所属する認定個人情報保護団体の名称および苦情の解決の申し出先</h3>
    <p>なし</p>

    <h3>10. 個人情報の開示等</h3>
    <p>利用者ご自身の情報に関する開示、訂正、追加、利用停止、削除または第三者提供の停止の請求、その他個人情報に関するご質問、ご意見等のお申し出（以下 「開示請求等」といいます）については、以下記載の「開示請求等の窓口」お問合せ窓口または利用者の担当営業部署にご連絡ください。</p>

    <h4>開示等の手続について</h4>
    <p>当社では、開示対象個人情報に関して、本人の情報の開示等（利用目的の通知、開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止）を希望される場合には、お申し出いただいた方が本人或いはその代理人であることを確認した上で、合理的な期間及び範囲で回答、情報の訂正、追加又は削除、利用停止、消去をします。</p>
    <p>ただし、これらの情報の一部又は全部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供ができなくなることがあります。（なお、関係法令に基づき保有しております情報については、利用停止または消去のお申し出に応じられない場合があります）。</p>

    <h4>【開示等の受付方法・窓口】</h4>
    <p>当社では、開示対象個人情報に関して、本人の情報の開示等のお申し出は、以下の方法にて受付けます。<br>
    なお、この方法によらない開示等の求めには応じられない場合がありますのでご了承下さい。</p>

    <h4>受付手続</h4>
    <p>下記の受付窓口まで、当社所定の「開示等に関する請求書」を記入の上、必要書類を同封して送付にてお申し込み下さい。（送付料は請求者のご負担となります。）</p>
    <p>下記方法により、本人（または代理人）であることを確認した上で、書面の交付により回答いたします。</p>

    <div class="box">
    <p>申込方法所定の「開示等に関する請求書」に必要事項を記入の上、本人確認書類または、代理人の場合は代理権限確認書類と共に受付窓口へ送付してください。（手数料はかかりません。）</p>

    <h5>本人確認方法</h5>
    <p>（本人）<br>
    「開示等対象本人確認のための書類」（※１参照）</p>

    <h6>（本人が委任した代理人）</h6>
    <ul>
        <li>「開示等対象本人確認のための書類」（※１参照）</li>
        <li>「代理人確認のための書類」（※１参照）</li>
        <li>（開示等対象本人からの）委任状</li>
        <li>印鑑証明証</li>
    </ul>

    <h6>（親権者の代理人の場合）</h6>
    <ul>
        <li>「開示等対象本人確認のための書類」（※１参照）</li>
        <li>「代理人確認のための書類」（※１参照）</li>
        <li>「本人との続柄を証明する書類」</li>
        <li>（戸籍標本、住民票の謄本、在留カード、外国人が続柄を証明する書類）</li>
    </ul>
    

    <h6>（成年後見人の代理人または未成年代理人の場合）</h6>
    <ul>
        <li>「開示等対象本人確認のための書類」（※１参照）</li>
        <li>「代理人確認のための書類」（※１参照）</li>
        <li>「代理人であることを証明する書類」（登記事項証明書）</li>
    </ul>

    <p>※１　開示等対象本人および代理人確認のための必要書類</p>
    <p>
        運転免許証・健康保険の被保険者証・年金手帳・パスポート・在留カード 等の公的証明書のいずれかのコピー または住民票の写し・本籍地などの機微情報は提出時にマスキングを依頼する事。<br>
    </p>
    </div>


    <h4>【開示請求等の窓口】</h4>
    <p>
        株式会社ソリッドアライズ　個人情報担当窓口 <br>
        〒105-0013 東京都港区浜松町2-2-15 浜松町ダイヤビル2階 <br>
        support@digi-tab.net</p>
</div>
</template>
<script>
export default {
    
}
</script>