var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", {
        attrs: {
          category_select: _vm.categoryNumber,
          search_word: _vm.searchWord
        }
      }),
      _vm._v(" "),
      _c("h2", [_vm._v(_vm._s(_vm.category.name))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.search_items.length == 0,
                  expression: "search_items.length == 0"
                }
              ]
            },
            [_vm._v("商品が見つかりませんでした")]
          ),
          _vm._v(" "),
          _vm._l(_vm.search_items, function(item, index) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.identity_status == 3,
                    expression: "item.identity_status == 3"
                  }
                ],
                key: index
              },
              [
                _c(
                  "div",
                  { staticClass: "item" },
                  [
                    _c("router-link", { attrs: { to: `/item/${item.uuid}` } }, [
                      _c("img", {
                        staticClass: "item_image",
                        attrs: { src: item.item_image, alt: "商品画像" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("router-link", { attrs: { to: `/item/${item.uuid}` } }, [
                      _c("h3", [_vm._v(_vm._s(item.item_name))])
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "categoryName" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "category",
                            attrs: { to: `/category/${_vm.category.id}` }
                          },
                          [_vm._v(_vm._s(_vm.category.name))]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("router-link", { attrs: { to: `/item/${item.uuid}` } }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm._f("omittedText")(item.detail)))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-block" }, [
                      _c(
                        "div",
                        { staticClass: "author-block" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: `/profile/${item.member.id}` } },
                            [
                              _c("img", {
                                staticClass: "authorImage",
                                attrs: { src: `${item.member.image_icon}` }
                              })
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "router-link",
                            { attrs: { to: `/profile/${item.member.id}` } },
                            [
                              _c("span", { staticClass: "authorName" }, [
                                _vm._v(_vm._s(item.member.seller_name))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "price-block" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "price",
                              attrs: { to: `/item/${item.uuid}` }
                            },
                            [
                              _vm._v(
                                "¥" +
                                  _vm._s(item.selling_price.toLocaleString())
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "date" }, [
                            _vm._v(
                              _vm._s(item.updated_at) +
                                "\n                            "
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      item.affiliatable == 1 &&
                                      item.affiliate_margin,
                                    expression:
                                      "item.affiliatable == 1 && item.affiliate_margin"
                                  }
                                ],
                                staticClass: "affi-percent"
                              },
                              [_vm._v(_vm._s(item.affiliate_margin) + "%")]
                            )
                          ])
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }