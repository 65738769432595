var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.news.name))]),
    _vm._v(" "),
    _c("div", { staticClass: "news-body" }, [
      _c("p", { staticClass: "news-date" }, [
        _vm._v(_vm._s(_vm.news.start_date))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "news-text" }, [_vm._v(_vm._s(_vm.news.body))]),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        },
        [_vm._v("戻る")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }