var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("新規会員登録")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg" }, [
        _c("div", { staticClass: "regMail regContent" }, [
          _c(
            "form",
            {
              staticClass: "regMailForm",
              attrs: { action: "/register", method: "POST" },
              on: { submit: _vm.submitRegist }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.token,
                    expression: "token"
                  }
                ],
                attrs: { type: "hidden", name: "_token" },
                domProps: { value: _vm.token },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.token = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: "info@example.com"
                        },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "email", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "pass-rule" }, [
                        _vm._v("8文字以上の半角英数字で入力してください。")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.password,
                            expression: "form.password"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "password", name: "password" },
                        domProps: { value: _vm.form.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "password", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "password", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm._m(2),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.password_confirmation,
                            expression: "form.password_confirmation"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "password",
                          name: "password_confirmation"
                        },
                        domProps: { value: _vm.form.password_confirmation },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "password_confirmation",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: {
                          names: "password_confirmation",
                          errmsg: _vm.errMsgs
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm._m(3),
                      _vm._v(" "),
                      _c("div", { staticClass: "twin-block" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.family_name,
                              expression: "form.family_name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "family_name",
                            placeholder: "姓"
                          },
                          domProps: { value: _vm.form.family_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "family_name",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.first_name,
                              expression: "form.first_name"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "first_name",
                            placeholder: "名"
                          },
                          domProps: { value: _vm.form.first_name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "first_name",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "family_name", errmsg: _vm.errMsgs }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "first_name", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "twin-block" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.family_name_kana,
                              expression: "form.family_name_kana"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "family_name_kana",
                            placeholder: "セイ"
                          },
                          domProps: { value: _vm.form.family_name_kana },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "family_name_kana",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.first_name_kana,
                              expression: "form.first_name_kana"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            name: "first_name_kana",
                            placeholder: "メイ"
                          },
                          domProps: { value: _vm.form.first_name_kana },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "first_name_kana",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: {
                          names: "family_name_kana",
                          errmsg: _vm.errMsgs
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "first_name_kana", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("法人名")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.corp_name,
                            expression: "form.corp_name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "corp_name" },
                        domProps: { value: _vm.form.corp_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "corp_name", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "corp_name", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("法人番号")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.corp_reg_no,
                            expression: "form.corp_reg_no"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "corp_reg_no" },
                        domProps: { value: _vm.form.corp_reg_no },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "corp_reg_no",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "corp_reg_no", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("電話番号")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phone,
                            expression: "form.phone"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "tel", name: "phone" },
                        domProps: { value: _vm.form.phone },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "phone", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "phone", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("郵便番号")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.zip,
                            expression: "form.zip"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "zip" },
                        domProps: { value: _vm.form.zip },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "zip", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "zip", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("住所１（都道府県）")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.pref,
                            expression: "form.pref"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "pref" },
                        domProps: { value: _vm.form.pref },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "pref", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "pref", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("住所２（市区町村）")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.city,
                            expression: "form.city"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "city" },
                        domProps: { value: _vm.form.city },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "city", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "city", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("住所３（番地）")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.address,
                            expression: "form.address"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "address" },
                        domProps: { value: _vm.form.address },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "address", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "address", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("住所４（マンション名など）")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.apartment,
                            expression: "form.apartment"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", name: "apartment" },
                        domProps: { value: _vm.form.apartment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "apartment", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "apartment", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "terms" },
                    [
                      _c("h5", [_vm._v("利用規約")]),
                      _vm._v(" "),
                      _c("term-area")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "term-check" }, [
                    _c("label", { staticClass: "form-check-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.termOk,
                            expression: "termOk"
                          }
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          type: "checkbox",
                          "true-value": "1",
                          "false-value": "0"
                        },
                        domProps: {
                          checked: Array.isArray(_vm.termOk)
                            ? _vm._i(_vm.termOk, null) > -1
                            : _vm._q(_vm.termOk, "1")
                        },
                        on: {
                          change: function($event) {
                            var $$a = _vm.termOk,
                              $$el = $event.target,
                              $$c = $$el.checked ? "1" : "0"
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.termOk = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.termOk = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.termOk = $$c
                            }
                          }
                        }
                      }),
                      _vm._v("利用規約に同意する")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    attrs: {
                      type: "submit",
                      value: "会員登録を確定",
                      disabled: _vm.termOk == "0"
                    }
                  })
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("メールアドレス"),
      _c("span", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("パスワード"),
      _c("span", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("パスワード再入力"),
      _c("span", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("氏名"),
      _c("span", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("フリガナ"),
      _c("span", [_vm._v("※")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }