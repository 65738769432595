var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", "aria-hidden": "true" }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-close",
              attrs: { type: "button" },
              on: { click: _vm.close }
            })
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "modal-body",
            domProps: { innerHTML: _vm._s(_vm.message) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.profileUpdate,
                    expression: "profileUpdate"
                  }
                ],
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.pUpdate }
              },
              [_vm._v("保存しない")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.profileUpdate,
                    expression: "!profileUpdate"
                  }
                ],
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: { click: _vm.close }
              },
              [_vm._v("閉じる")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: { click: _vm.decide }
              },
              [_vm._v("OK")]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }