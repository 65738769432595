<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.actions {
    text-align: right;
    margin: -3.2rem 0 1rem;
}
.sort-btn {
    margin: 0 0 15px;
    .btn {
        min-width: 100px;
    }
}
.content {
    padding: 0 1rem;
}
.header {
    border-bottom: 1px solid #ddd;
}
.btn-primary.main {
    background-color: $color-main-seller;
    border-color: $color-main-seller;
}
@media screen and (max-width: 896px) {
    .sort-btn {
        display: flex;
        justify-content: space-between;
    }
    .header {
        display: none;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">商品管理</h1>
        <div class="reg">
            <div class="actions">
                <button type="button" class="btn btn-primary main" @click="detail()">新規登録</button>
            </div>
            <div class="sort-btn">
                <button type="button" class="btn main" :class="onPublish ? 'btn-primary' : 'btn-secondary'" @click="publish()">公開</button>
                <button type="button" class="btn main" :class="onLimitPublish ? 'btn-primary' : 'btn-secondary'" @click="limitPublish()">限定公開</button>
                <button type="button" class="btn main" :class="onNoPublish ? 'btn-primary' : 'btn-secondary'" @click="noPublish()">非公開</button>
            </div>
            <div class="regMail regContent row">
                <div class="col-12">
                    <div class="row header" v-show="!!list[0]">
                        <div class="col-sm-6">商品</div>
                        <div class="col-sm-1">支払い方式</div>
                        <div class="col-sm-1">価格</div>
                        <div class="col-sm-1">公開設定</div>
                        <div class="col-sm-1">状況</div>
                    </div>
                    <item-row v-for="i in list" v-show="displayStatus(i.display_status)" :key="i.id" :item="i" @open-detail="detail" @open-copy="copy"></item-row>
                </div>
            </div>
        </div>
    </div>
    <item-modal :signal="signal" @loading="setLoading" @research="search"></item-modal>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import Api from "../../common/fetch-wrapper";
import {CACHE_ARIES} from "../../store/modules/cache-store";
import ItemRow from "./parts/item-row";
import ItemModal from "./parts/item-modal";

export default {
    components: {
        "item-row" : ItemRow,
        "item-modal" : ItemModal
    },
    data: function() {
        return {
            list:[],
            signal: {} ,
            toastMsg : {},
            onPublish: true,
            onLimitPublish: true,
            onNoPublish: false,
        };
    },
    computed: {
        ...mapGetters(CACHE_ARIES,["banks","categories", "payments"])
    },
    methods : {
        ...mapActions(CACHE_ARIES,["loadBanks","loadCategories","loadPayments"]),
        search:function() {
            return Api.get("/item/self").then( x => this.list = x);
        },
        research:function() {
            this.setLoading(true);
            this.search().finally(() => this.setLoading(false));
        },
        setLoading:function(val) {
            this.$emit("loading", val);
        },
        detail:function(item = undefined) {
            if(item) {
                this.signal = {
                    form: item,
                    copy: false
                };
                return;
            }
            this.signal = {};
        },
        copy:function(item) {
            this.signal = {
                form: item,
                copy: true
            };
        },
        publish:function() {
            this.onPublish = !this.onPublish
        },
        limitPublish:function() {
            this.onLimitPublish = !this.onLimitPublish
        },
        noPublish:function() {
            this.onNoPublish = !this.onNoPublish
        },
        displayStatus:function(s) {
            let dis0 = ""
            let dis1 = ""
            let dis2 = ""
            if(this.onPublish) {
                dis0 = s == 0
            }
            if(this.onLimitPublish) {
                dis1 = s == 1
            }
            if(this.onNoPublish) {
                dis2 = s == 2
            }
            return dis0 || dis1 || dis2
        }
    },
    mounted: function() {
        this.setLoading(true);
        Promise.all([
            this.search(),
            this.loadBanks(),
            this.loadCategories(),
            this.loadPayments()
        ]).then(x => {

        }).finally(() => {
            this.setLoading(false);
        });
    }
}
</script>