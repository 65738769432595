import Vue from "vue";
import loadPolyfills from "./common/polyfill";
import App from "./app";
import VueClipboard from 'vue-clipboard2';
import VueCookies from 'vue-cookies'

// polifil
loadPolyfills();

Vue.use(VueClipboard);
Vue.use(VueCookies)

// メインフレーム
const app = new Vue({
    components : {
        "app": App
    }
});
app.$mount("#app");