var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "index-part" } }, [
    _c("section", { attrs: { id: "sec2" } }, [
      _c("div", { staticClass: "frame" }, [
        _c("div", { staticClass: "flex" }, [
          _c("figure", [
            _c("img", {
              attrs: {
                src: `${_vm.s3Url}/title.png`,
                alt: "DIGITAB ヘルプページ　利用ガイド"
              }
            })
          ]),
          _vm._v(" "),
          _c("figure", [
            _c("img", {
              attrs: {
                src: `${_vm.s3Url}/logo.png`,
                alt: "DIGITAB ヘルプページ　利用ガイド"
              }
            })
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { attrs: { id: "sec3" } }, [
      _c("div", { staticClass: "frame" }, [
        _c(
          "figure",
          [
            _c("Banner"),
            _vm._v(" "),
            _c("a", { attrs: { href: "https://digi-tab.net/news/18" } }, [
              _c("img", {
                attrs: {
                  src: `${_vm.s3Url}/bana.png`,
                  alt: "デジタブ ベータテストを実施中"
                }
              })
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("section", { attrs: { id: "sec4" } }, [
      _c("div", { staticClass: "frame" }, [
        _c("ul", { staticClass: "sec4-box" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/category/all" } }, [
                _c("img", {
                  attrs: {
                    src: `${_vm.s3Url}/syohin-item.png`,
                    alt: "商品一覧"
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/news" } }, [
                _c("img", {
                  attrs: {
                    src: `${_vm.s3Url}/syohin-item2.png`,
                    alt: "お知らせ"
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/register" } }, [
                _c("img", {
                  attrs: {
                    src: `${_vm.s3Url}/syohin-item3.png`,
                    alt: "新規会員登録"
                  }
                })
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "https://digi-tab-support.com/" } }, [
              _c("img", {
                attrs: {
                  src: `${_vm.s3Url}/syohin-item4.png`,
                  alt: "ヘルプセンター"
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://digi-tab-support.com/help-manual-sell.html"
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.s3Url}/syohin-item5.png`,
                    alt: "出品のやり方"
                  }
                })
              ]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                attrs: {
                  href: "https://digi-tab-support.com/help-manual-partner.html"
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: `${_vm.s3Url}/syohin-item6.png`,
                    alt: "パートナーのやり方"
                  }
                })
              ]
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("section", { attrs: { id: "sec5" } }, [
      _c("div", { staticClass: "frame" }, [
        _c("div", { staticClass: "sec5-box" }, [
          _c("div", { staticClass: "sec5-title" }, [
            _c("img", {
              attrs: { src: `${_vm.s3Url}/subtitle.png`, alt: "DIGITABとは" }
            })
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Digitabでは、気軽に無形商品を販売・購入するためのサービスを行っています。"
            )
          ])
        ]),
        _vm._v(" "),
        _c("figure", [
          _c("img", {
            attrs: { src: `${_vm.s3Url}/imgitem.png`, alt: "決済代行サービス" }
          })
        ]),
        _vm._v(" "),
        _c("figure", [
          _c("img", {
            attrs: { src: `${_vm.s3Url}/imgitem2.png`, alt: "デジタブ" }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { attrs: { id: "sec4" } }, [
      _c(
        "div",
        { staticClass: "frame", staticStyle: { border: "3px solid red" } },
        [
          _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Important Notice")
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v("重要なお知らせ")
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v(
              "\n                平素よりDIGITABをご利用いただき、誠にありがとうございます。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "\n\n                この度、金融商品取引法に抵触する可能性のある商品に関しまして、一部の商品にトラブルが発生いたしました。"
            ),
            _c("br"),
            _vm._v(
              "\n                これに伴い、今後DIGITABでの上記に該当する商品の出品を禁止させていただきます。"
            ),
            _c("br"),
            _c("br"),
            _vm._v(
              "\n\n                ご利用の皆様にはご迷惑をおかけし申し訳ございません。"
            ),
            _c("br"),
            _vm._v(
              "\n                何卒ご理解とご協力を賜りますようお願い申し上げます。\n            "
            )
          ])
        ]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Digitabとは、電子書籍・画像・動画や学習教室加入の権利、製作依頼など無形商品を"
      ),
      _c("br"),
      _vm._v("幅広くを扱う代理販売サイトです。")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "個人で無形商品（実際に形を持たない商品）を売るためには様々な準備が必要となり多"
      ),
      _c("br"),
      _vm._v("大な負担がかかります。")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }