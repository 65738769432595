<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-partner 4px solid;
    }
}
.vue-table {
    padding: 30px 30px 0;
    .table-option {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 10px;
        button {
            padding: 10px;
            border: none;
            background-color: $color-main-partner;
            color: #fff;
            &:hover {
                background-color: #0f95ef;
            }
        }
        form>div {
            width: 530px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 0 10px;
            font-size: 15px;
            .item_search {
                width: 359px;
            }
            .category-select {
                select {
                    height: 27px;
                    width: 160px;
                }
            }
            button {
                width: 160px;
                padding: 3px;
            }
        }
        .right_content {
            display: flex;
            flex-flow: column;
            justify-content: space-between;
            height: 130px;
            div {
                text-align: right;
            }
        }
    }
    .table-cell {
        min-height: 30px;
        border: 1px solid #000;
        padding: 2px 0;
        text-align: center;
    }
    .item-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        a {
            color: #000;
        }
    }
}
@media screen and (max-width: 896px) {
    .pc_cont {
        display: none !important;
    }
    .vue-table {
        padding: 0;
        .table-option {
            form>div {
                width: auto;
                button {
                    width: 50px;
                    padding: 3px;
                }
            }
        }
        .table-cell {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="pageContent buyer">
    <div class="content no-login">
        <h1 class="page-title">売り上げ情報</h1>
        <div class="vue-table">
            <div class="table-option">
                <form @submit.prevent="itemFind">
                    <div class="pc_cont">
                        <div>
                            <div>期間</div>
                            <input type="date" v-model="keyword.startDate">〜<input type="date" v-model="keyword.endDate">
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="pc_cont">商品名</div>
                            <input type="text" class="item_search" v-model="keyword.item">
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary">検索</button>
                        </div>
                    </div>
                </form>
                <div class="right_content">
                    <div class="pc_cont">
                        <vue-json-to-csv :json-data="csvData" csv-title="売り上げ情報" :labels="labels">
                            <button @click="downloadCSV">csvをダウンロード</button>
                        </vue-json-to-csv>
                    </div>
                    <div>総売り上げ額：{{ price(totalPrice) }}</div>
                </div>
            </div>
            <vuetable 
                ref="vuetable"
                noDataTemplate = "商品がありません。"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                :data-manager="dataManager"
                :append-params="moreParams"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                class="table"
            >
                <div class="table-cell" slot="order_date" slot-scope="props">{{ props.rowData.order_date }}</div>
                <div class="table-cell" slot="order_id" slot-scope="props">{{ props.rowData.order_id }}</div>
                <div class="table-cell" slot="uuid" slot-scope="props">{{ props.rowData.uuid }}</div>
                <div class="table-cell item-name" slot="item_name" slot-scope="props"><router-link :to="`/item/${props.rowData.item.uuid}`">{{ props.rowData.item_name | omittedText }}</router-link></div>
                <div class="table-cell" slot="affiliate_margin" slot-scope="props">{{ props.rowData.affiliate_margin }}%</div>
                <div class="table-cell" slot="affiliate_price" slot-scope="props">{{ price(props.rowData.affiliate_price) }}</div>
            </vuetable>
            <div class="pagenation">
                <vuetable-pagination ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {Vuetable, VuetablePagination} from 'vuetable-2';
import VueJsonToCsv from "vue-json-to-csv";
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import {num2Yen} from "../../common/com-func";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../store/modules/user-store";

export default {
    components: {
        VueJsonToCsv,
        Vuetable,
        VuetablePagination
    },
    data() {
        return {
            keyword: {
                startDate: "",
                endDate: "",
                item: "",
            },
            fields: [],
            pc_fields: [
                {name: "order_date",title: '日付', sortField: 'order_date'},
                {name: "order_id",title: '購入ID', sortField: 'order_id'},
                {name: "uuid",title: '商品ID'},
                {name: "item_name",title: '商品名'},
                {name: 'affiliate_margin',title: '報酬割合'},
                {name: 'affiliate_price',title: '報酬額'},
            ],
            sp_fields: [
                {name: "order_date",title: '日付', sortField: 'order_date'},
                {name: 'item_name',title: '商品名'},
                {name: 'affiliate_margin',title: '報酬割合'},
                {name: 'affiliate_price',title: '報酬額'},
            ],
            moreParams: {
                sort: 'order_id',
                sort: 'order_date',
            },
            perPage: 30,
            data: [],
            totalPrice: "",
            csvData: [],
            labels: {
                order_date: { title: "日付" },
                order_id: { title: "購入ID" },
                uuid: { title: "商品ID" },
                item_name: { title: "商品名" },
                affiliate_margin: { title: "報酬割合" },
                affiliate_price: { title: "報酬額" },
            },
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userID"])
    },
    watch: {
        data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
        }
    },
    created() {
        this.getItems();
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize: function() {
            if (window.innerWidth >= 896) {
                this.fields = this.pc_fields
            } else {
                this.fields = this.sp_fields
            }
        },
        getItems() {
            axios.get("/partner/items/").then(response => {
            this.data = response.data;

            for(let i=0; i<this.data.length; i++ ) {
                const item_date = new Date(this.data[i].order_date);
                const startDate = new Date(this.keyword.startDate);
                const endDate = new Date(this.keyword.endDate);
                if(this.keyword.startDate != "" && this.data[i]) {
                    if(startDate - item_date > 32400000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.endDate != "" && this.data[i]) {
                    if(endDate - item_date <= -54000000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }

                if(this.keyword.item != "" && this.data[i]) {
                    if(!this.data[i].item_name.includes(this.keyword.item)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
            }
            if(this.data.length == 0) {
                this.data = {};
            }

            for(let i=0; i<this.data.length; i++ ) {
                this.data[i].uuid = this.data[i].item.uuid;
                this.data[i].affiliate_margin = this.data[i].item.affiliate_margin;
            }

            this.totalPrice = 0
            for(let i=0; i<this.data.length; i++ ) {
                this.csvData = this.data;
                const csvData = this.csvData[i];
                csvData.order_date = dayjs(this.data[i].order_date).format('YYYY/MM/DD');

                this.totalPrice += this.data[i].affiliate_price;
            }
            });
            
        },
        itemFind() {
            this.getItems();
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;
            let local = this.data;

            if (sortOrder.length > 0) {
                local = _.orderBy(
                local,
                sortOrder[0].sortField,
                sortOrder[0].direction
            );
        }

        pagination = this.$refs.vuetable.makePagination(
            local.length,
            this.perPage
            );
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },
        getSortParam(sortOrder) {
            return sortOrder.map(function(sort) {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            }).join(',')
        },
        price:function(price) {
            return num2Yen(price);
        },
        downloadCSV () {
            this.csvData.push();
        }
    },
    filters: {
        omittedText(text) {
            // 10文字以降は...を表示する
            return text.length > 10 ? text.slice(0, 10) + "..." : text;
        },
    },

}
</script>