<style lang="scss" scoped>
@import "../../../sass/variables";
h1 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3px;
    color: #565656;
    padding: 30px 45px 0;
    margin-bottom: 20px;
    &:before {
        content: "";
        position: relative;
        top: 1px;
        left: -14px;
        border-left: 4px solid;
        border-color: $color-main-free;
    }
}
.product_box{
    padding: 0 30px;
    .product-image {
        max-width: 100%;
        object-fit: cover;
    }
    .mainContent {
        width: 70%;
        float: left;
        .product-image {
            width: 100%;
        }
        .product-content {
            background-color: #fff;
            line-height: 1.8;
            padding: 40px 30px;
            border: 1px solid #ccc;
            margin: 20px 0 60px;
            white-space: pre-wrap;
        }
    }
    .sidebarContent {
        width: calc(100% - 72%);
        float: right;
        a {
            text-decoration: none;
        }
        .priceBox {
            padding: 32px 35px;
            background-color: #fff;
            border: 2px solid #00CCDB;
            p {
                text-align: center;
                .priceBoxPrice {
                    font-size: 26px;
                    font-weight: bold;
                    padding-right: 5px;
                    letter-spacing: 2px;
                }
                .priceBoxPrice-op {
                    font-size: 16px;
                    font-weight: bold;
                }
                .priceBoxPrice, .priceBoxPrice-op {
                    color: $color-main-free;
                }
            }
            .buyButton {
                display: block;
                text-align: center;
                padding: 10px 0;
                background-color: $color-main-free;
                color: #fff;
                border-radius: 25px;
                margin-top: 10px;
                font-size: 14px;
                letter-spacing: 1px;
                margin-bottom: 40px;
            }
            .op-buy-style, .op-sell-style, .op-sellstart-style, .op-aff-style {
                font-size: 11px;
                color: #ccc;
                display: block;
                margin-top: 15px;
                .op-link {
                    color: $color-main-free;
                    font-size: 10px;
                    padding-left: 5px;
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
            .op-buy-style-text, .op-sell-style-text, .op-sellstart-style-text, .op-aff-style-text {
                font-size: 15px;
                letter-spacing: 1px;
                color: #565656;
                .author-block {
                    margin-top: 5px;
                    width: 75%;
                    .authorImage {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        border: 1px solid #ccc;
                        float: left;
                    }
                    .authorName {
                        font-size: 12px;
                        padding: 16px 10px;
                        display: block;
                        margin-left: 55px;
                        color: #ababab;
                    }
                }
            }
        }
        h2 {
            font-size: 16px;
            letter-spacing: 1px;
            border-left: 5px solid $color-main-free;
            color: #565656;
            padding: 3px 15px 0;
            margin-top: 40px;
            margin-bottom: 10px;
        }
        .otherProductBox {
            .item {
                margin: 0;
                margin-bottom: 10px;
                padding: 20px;
                border: 1px solid #ccc;
                background-color: #fff;
                .category {
                    display: inline-block;
                    padding: 4px 18px;
                    margin-bottom: 5px;
                    font-size: 10px;
                    border: 1px solid #ccc;
                    border-radius: 25px;
                    color: #ababab;
                }
                .product-image {
                    max-width: 100%;
                    width: 19vw;
                }
                h3 {
                    font-size: 16px;
                    padding: 10px 0 0;
                    line-height: 1.3;
                    letter-spacing: 1px;
                    color: #333;
                }
                .info-block {
                    display: flex;
                    .author-block {
                        margin-top: 12px;
                        width: 75%;
                        .authorImage {
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            border: 1px solid #ccc;
                            float: left;
                        }
                        .authorName {
                            font-size: 12px;
                            padding: 16px 10px;
                            display: block;
                            margin-left: 55px;
                            color: #ababab;
                        }
                    }
                    .price-block {
                        margin-top: 4px;
                        padding: 12px 0px;
                        width: 50%;
                        text-align: center;
                        .price {
                            background-color: $color-main-free;
                            border-color: $color-main-free;
                            color: #fff;
                            display: block;
                            margin: 0 auto;
                            border-radius: 25px;
                            padding: 4px 20px;
                            font-size: 13px;
                        }
                        .date {
                            display: block;
                            font-size: 10px;
                            color: #ababab;
                            padding: 3px 0 0;
                            .affi-percent {
                                border-left: 1px dotted #ccc;
                                margin-left: 5px;
                                padding-left: 5px;
                                .fa, .fas {
                                    font-weight: 900;
                                }
                                .fa-link:before {
                                    content: "\f0c1";
                                }
                            }
                        }
                    }
                }
            }  
        } 
    }
}
@media screen and (max-width: 896px) {
    h1 {
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        padding: 20px 20px 5px;
        &:before {
            top: 0;
            left: -8px;
        }
    }
    .product_box {
        padding: 0;
        .mainContent {
            width: 100%;
            float: none;
            .product-content {
                line-height: 1.6;
                margin: 20px 0;
            }
        }
        .sidebarContent {
            width: 100%;
            float: none;
            .priceBox {
                padding: 10px;
                p {
                    margin: 0;
                }
                .buyButton {
                    margin: 0;
                }
                .authorProfile {
                    float: right;
                    .author-block {
                        width: 100%;
                    }
                }
                .op-sell-style {
                    margin: 0;
                }
                .op-sellstart-style {
                    margin: 5px 0 0;
                }
            }
        }
    }
}
</style>
<template>
    <div>
        <Search />
        <h1>{{ item.item_name }}</h1>
        <div class="product_box">
            <div class="mainContent">
                <img class="product-image" :src="item.item_image" alt="商品画像">
                <div class="product-content">{{ item.detail }}</div>
            </div>
            <div class="sidebarContent">
                <div class="priceBox">
                    <p><span class="priceBoxPrice">{{ price(item) }}</span><span class="priceBoxPrice-op">(税込)</span></p>
                    <router-link v-if="item.pay_mode == 0" :to="`/payment/bank/${item.uuid}`" class="buyButton">銀行振込で購入する</router-link>
                    <router-link v-if="item.pay_mode == 1" :to="`/payment/credit/${item.uuid}`" class="buyButton">クレジット決済で購入する</router-link>
                    <span class="op-buy-style">支払形式:</span>
                    <span class="op-buy-style-text">{{ payment(item) }}</span>
                    <div class="authorProfile">
                        <span class="op-sell-style">出品者:<router-link class="op-link" :to="`/profile/${item.member_id}`">▶プロフィールへ</router-link></span>
                        <span class="op-sell-style-text">
                            <div class="author-block">
                                <img class="authorImage" :src="item.member.image_icon">
                                <span class="authorName">{{ item.member.seller_name }}</span>
                            </div>
                        </span>
                    </div>
                    <span class="op-sellstart-style">出品開始日:</span>
                    <span class="op-sellstart-style-text">{{ publishDay(item) }}</span>
                    <div v-show="item.affiliatable == 1 && item.affiliate_margin">
                        <span class="op-aff-style">紹介料:<div class="op-link" @click="copyUrl(logined ? `/item/${item.uuid}?partner=${userID}` : `a`)">▶ 紹介リンク</div></span>
                        <span class="op-aff-style-text">{{ item.affiliate_margin }}%</span>
                    </div>
                </div>
                <h2>その他の商品</h2>
                <div class="otherProductBox">
                    <div class="item" v-for="(i, index) in linkitems" :key="index">
                        <router-link :to="`/category/${i.category_id}`" class="category" tabindex="0">{{ categoryName(i) }}</router-link>
                        <router-link :to="`/item/${i.uuid}`"><img class="product-image" :src="i.item_image" alt="商品画像"></router-link>
                        <router-link :to="`/item/${i.uuid}`"><h3>{{ i.item_name }}</h3></router-link>
                        <div class="info-block">
                            <div class="author-block">
                                <router-link :to="`/profile/${i.member_id}`"><img class="authorImage" :src="i.member.image_icon"></router-link>
                                <router-link :to="`/profile/${i.member_id}`"><span class="authorName">{{ i.member.seller_name }}</span></router-link>
                            </div>
                            <div class="price-block">
                                <router-link :to="`/item/${i.uuid}`" class="price" tabindex="0">{{ price(i) }}</router-link>
                                <span class="date">{{ publishDay(i) }}
                                    <span v-show="i.affiliatable == 1 && i.affiliate_margin" class="affi-percent">{{ i.affiliate_margin }}%</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <msg-toast :signal="toastMsg"></msg-toast>
    </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import dayjs from 'dayjs';
import Search from "../top/parts/search.vue";
import Api from "../../common/fetch-wrapper";
import {num2Yen,isEmpty} from "../../common/com-func";
import {CACHE_ARIES} from "../../store/modules/cache-store";
import {USER_ARIES} from "../../store/modules/user-store";
import MsgToast from "../../components/msg-toast";

export default {
    components: {
        Search: Search,
        "msg-toast" : MsgToast
    },
    data:function() {
        return {
            itemId: "",
            item: {},
            linkitems: [],
            signal: {},
            toastMsg: {}
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["logined", "userID", "origin"]),
        ...mapGetters(CACHE_ARIES,["categoryMap", "paymentMap"]),
    },
    methods: {
        ...mapActions(CACHE_ARIES,["loadCategories","loadPayments"]),
        getItems:function(id) {
            this.$emit("loading",true);
            Promise.all([
                Api.get("/item/api/" + id),
                Api.get("/category/link/" + id),
                Api.put('/item/api/' + id,undefined,(x) => {}),
                this.loadCategories(),
                this.loadPayments()
            ]).then(x => {
                this.item = x[0];
                this.linkitems = x[1];
                
                if(this.$route.query.partner) {
                    this.setCookie();
                }
            }).finally(() => {
                this.$emit("loading",false);
            });
        },
        setCookie:function() {
            this.$cookies.set('itemCookie', this.item.uuid, 60 * 60 * 24);
            this.$cookies.set('partnerCookie', this.$route.query.partner, 60 * 60 * 24);
        },
        categoryName:function(item) {
            if(!isEmpty(item.category_id) && this.categoryMap[item.category_id]) {
                return this.categoryMap[item.category_id];
            }
            return "";
        },
        payment:function(item) {
            if(item && !isEmpty(item.payment_id)) {
                return this.paymentMap[item.payment_id];
            }
            return "-";
        },
        publishDay:function(item) {
            return dayjs(item.updated_at).format('YYYY-MM-DD');
        },
        price:function(item) {
            return num2Yen(item.selling_price);
        },
        copyUrl:function(url) {
            if(this.logined) {
                const originUrl = location.origin;
                const copyUrl = originUrl+url;
                
                if(this.origin.partner_status == 1) {
                    this.$copyText(copyUrl);
                    this.toastMsg = {message:"紹介用URLをコピーしました。"};
                } else {
                    this.toastMsg = {message:"商品を紹介し、利益を得るためには会員登録後パートナー規約の同意が必要です。"};
                }
            } else {
                this.toastMsg = {message:"商品を紹介し、利益を得るためには会員登録後パートナー規約の同意が必要です。"};
            }
        }
    },
    mounted:function() {
        this.getItems(this.$route.params.id);
    },
    watch: {
        $route(to, from) {
            this.getItems(to.params.id);
        },
    },
}
</script>