<style lang="scss" scoped>
@import "../../../sass/variables";
.breadcrumb-item {
    &:not(.active) {
        cursor: pointer;
        text-decoration: underline;
    }
}
.content {
    padding: 0 1rem;
}
.row {
    &.second {
        margin-top: 3rem;
    }
    .news {
        display: flex;
        p {
            margin: 15px 0 0;
            &:hover {
                cursor: pointer;
            }
            span {
                display: inline-block;
                width: 14px;
                height: 14px;
                background-color: $color-main-free;
                transform: translateY(1.5px);
                margin: 0 10px 0 0;
            }
        }
    }
    .no-news {
        margin: 15px 0 0 10px;
    }
    .link-news {
        display: flex;
        justify-content: flex-end;
        p {
            color: $color-main-free;
            font-size: 12px;
            margin: 0;
            &:hover {
                cursor: pointer;
            }
            span {
                position: relative;
                display: inline-block;
                padding-left: 15px;
                &::before {
                    content: "";
                }
                &::after {
                    content: "";
                    left: 0px;
                    top: -8px;
                    box-sizing: border-box;
                    width: 3px;
                    height: 3px;
                    border: 4px solid transparent;
                    border-left: 6px solid $color-main-free;
                    position: absolute;
                }
            }
        }
    }
}
</style>
<template>
<div class="pageContent">
    <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item" @click="move('/')">トップ</li>
            <li class="breadcrumb-item active" aria-current="page">マイページ</li>
        </ol>
    </nav>
    <div class="content">
        <div class="row">
            <h1 class="page-title">お知らせ</h1>
            <div class="regMail regContent col-12">
                <div class="news" v-for="(news, index) in this.news" :key="index"><p @click="move(`/news/${news.id}`)"><span></span>{{ news.start_date }}: {{ news.name }}</p></div>
                <p class="no-news" v-show="noNewsFlag">{{ noNewsText }}</p>
                <div class="link-news"><p @click="move('/news')"><span></span>一覧はこちら</p></div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            news: [],
            noNewsText: "",
            noNewsFlag: false,
        }
    },
    created() {
        this.getNews();
    },
    methods: {
        getNews() {
            axios.get("/news/api").then(response => {
            this.news = response.data;
            for(let i=0; i<this.news.length; i++ ) {
                
                // start_dateがnullなら配列を削除する
                const nowDate = dayjs();
                if(this.news[i].start_date == null) {
                    this.news.splice(i, 1)
                    i = i-1;
                }
                // start_dateが現在時刻よりも後か、end_dateが現在時刻よりも前か判断し配列を削除
                else if(nowDate.diff(this.news[i].start_date) <= 0 ) {
                    this.news.splice(i, 1)
                    i = i-1;
                } else if(nowDate.diff(this.news[i].end_date) >= 0) {
                    this.news.splice(i, 1)
                    i = i-1;
                } else if((nowDate.diff(this.news[i].start_date) >= 864000000 )) {
                    // start_dateが10日以上前だったら配列を削除(差分をミリ秒で計算)
                    this.news.splice(i, 1)
                    i = i-1;
                } else {
                    this.news[i].start_date = dayjs(this.news[i].start_date).format('YYYY-MM-DD');
                }
            }
            this.news = Object.values(this.news).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            if(this.news.length == 0) {
                this.noNewsText = "新着のお知らせはありません";
                this.noNewsFlag = true;
            }
            });
        },
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        }
    },
}
</script>