<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-transfer 4px solid;
    }
}
.vue-table {
    padding: 30px 30px 0;
    .table-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 10px;
        button {
            padding: 10px;
            border: none;
            background-color: $color-main-seller;
            color: #fff;
            &:hover {
                background-color: #8dd08d;
            }
        }
        form>div {
            width: 530px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 0 10px;
            font-size: 15px;
    
            .category-select {
                select {
                    height: 27px;
                    width: 160px;
                }
            }
            button {
                width: 160px;
                padding: 3px;
            }
        }
    }
}
@media screen and (max-width: 896px) {
    .vue-table {
        padding: 0;
        .table-option {
            form>div {
                width: auto;
                button {
                    width: 50px;
                    padding: 3px;
                }
            }
        }
    }
}
</style>
<template>
<div class="pageContent buyer">
    <div class="content no-login">
        <h1 class="page-title">支払明細書</h1>
        <div class="vue-table">
            <div class="table-option ">
                <form @submit.prevent="itemFind('search')">
                    <div>
                        <div>
                            <div>期間</div>
                            <input type="date" v-model="keyword.startDate">〜<input type="date" v-model="keyword.endDate">
                        </div>
                        <button type="submit" class="btn btn-primary">検索</button>
                    </div>
                </form>
            </div>
            <DetailDownload v-if="searched" :sellerData="sellerData" :affiliateData="affiliateData" />
        </div>
    </div>
</div>
</template>
<script>
import detailDownload from "../seller/parts/detail-download.vue"; 
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import {num2Yen} from "../../common/com-func";
import {mapGetters} from "vuex";
import {USER_ARIES} from "../../store/modules/user-store";

export default {
    components: {
        "DetailDownload": detailDownload,
    },
    data() {
        return {
            keyword: {
                startDate: "",
                endDate: "",
            },
            searched: false,
            data: {},
            partnerData: {},
            csvData: [],
            sellerData: [],
            affiliateData: [],
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["userID"])
    },
    methods: {
        getOrder(search) {
            this.searched = false
            axios.get("/order/buyer").then(response => {
            this.data = response.data;

            // 検索でデータを絞り込み
            for(let i=0; i<this.data.length; i++ ) {
                const item_date = new Date(this.data[i].created_at);
                const startDate = new Date(this.keyword.startDate);
                const endDate = new Date(this.keyword.endDate);
                if(this.keyword.startDate != "" && this.data[i]) {
                    if(startDate - item_date > 32400000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.endDate != "" && this.data[i]) {
                    if(endDate - item_date <= -54000000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
            }
            if(this.data.length == 0) {
                this.data = {};
            }

            // 出品明細データを作成
            this.sellerData = this.data;
            this.sellerData.userId = this.userID;
            this.sellerData.totalPrice = 0
            for(let i=0; i<this.data.length; i++ ) {
                this.sellerData[i].order_date = dayjs(this.data[i].order_date).format('YYYY/MM/DD');
                this.sellerData.totalPrice += this.data[i].earning_price;
            }
            });


            axios.get("/partner/items/").then(response => {
            this.partnerData = response.data;

            // 検索でデータを絞り込み
            for(let i=0; i<this.partnerData.length; i++ ) {
                const item_date = new Date(this.partnerData[i].order_date);
                const startDate = new Date(this.keyword.startDate);
                const endDate = new Date(this.keyword.endDate);
                if(this.keyword.startDate != "" && this.partnerData[i]) {
                    if(startDate - item_date > 32400000) {
                        this.partnerData.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.endDate != "" && this.partnerData[i]) {
                    if(endDate - item_date <= -54000000) {
                        this.partnerData.splice(i, 1);
                        i = i-1;
                    }
                }
            }
            if(this.partnerData.length == 0) {
                this.partnerData = {};
            }

            // パートナー明細データを作成
            this.affiliateData = this.partnerData;
            for(let i=0; i<this.partnerData.length; i++ ) {
                this.affiliateData[i].order_date = dayjs(this.partnerData[i].order_date).format('YYYY/MM/DD');
                this.sellerData.totalPrice += this.partnerData[i].affiliate_price;
            }

            // 明細表示する期間のフォーマット
            if(this.keyword.startDate != "" || this.keyword.endDate != "") {
                let formatStartDate = "";
                let formatEndDate = "";

                if(this.keyword.startDate) {
                    const startDate = new Date(this.keyword.startDate);
                    formatStartDate = dayjs(startDate).format('YYYY年MM月DD日');
                }
                if(this.keyword.endDate) {
                    const endDate = new Date(this.keyword.endDate);
                    formatEndDate = dayjs(endDate).format('YYYY年MM月DD日');
                }

                this.sellerData.date = formatStartDate+" ~ "+formatEndDate;
            } else {
                this.sellerData.date = "全期間"
            }
            if(search) {
                this.searched = true
            }
            });

        },
        itemFind(search) {
            this.getOrder(search);
        },
        price:function(price) {
            return num2Yen(price);
        },
    },
}
</script>