<style lang="scss" scoped>
@import "../../../../sass/variables";
.item-row {
    border-bottom: 1px solid $border-color;
    padding: 0.5rem 0;
    .cell {
        display: inline-block;
        margin-top: 35px;
    }
    .cell-item-id {
        font-size: 12px;
        display: inline-block;
        margin-top: 20px;
    }
    .image-thumb {
        height: 100px;
        img {
            height: 100%;
        }
    }
    .btn-primary.main {
        background-color: $color-main-seller;
        border-color: $color-main-seller;
        width: 93%;
        margin-top: 5px;
        &.half {
            width: 45%;
        }
        &:disabled {
            color: $gray-font;
            background-color: #eaeaea;
            border-color: #eaeaea;
            cursor:unset;
        }
    }
}
@media screen and (min-width: 896px) {
    .sp_cont{
        display: none;
    }
}
@media screen and (max-width: 896px) {
    .item-row {
        .cell {
            margin: 10px 0 0;
        }
    }
}
</style>
<template>
<div class="item-row row">
    <div class="col-sm-2">
        <div class="image-thumb"><img :src="imagePath" /></div>
    </div>
    <div class="col-sm-4">
        <div class="cell-item-id">{{item.uuid}}</div>
        <div><span class="sp_cont">商品：</span>{{item.item_name}}</div>
    </div>
    <div class="col-sm-1">
        <span class="cell"><span class="sp_cont">支払い方式：</span>{{payment}}</span>
    </div>
    <div class="col-sm-1">
        <span class="cell"><span class="sp_cont">価格：</span>{{price}}</span>
    </div>
    <div class="col-sm-1">
        <span class="cell"><span class="sp_cont">公開設定：</span>{{display}}</span>
    </div>
    <div class="col-sm-1">
        <span class="cell"><span class="sp_cont">状況：</span>{{status}}</span>
    </div>
    <div class="col-sm-2">
        <button type="button" class="btn btn-primary main half" @click="detail">詳細</button>
        <button type="button" class="btn btn-primary main half" @click="copy" :disabled="!canCopy">コピー</button>
        <button type="button" class="btn btn-primary main" @click="preview" :disabled="!canCopy">ストアへ</button>
    </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { num2Yen,isEmpty } from "../../../common/com-func";
import {CACHE_ARIES} from "../../../store/modules/cache-store";
import dayjs from "dayjs";

export default {
    props:["item"],
    computed: {
        ...mapGetters(CACHE_ARIES,["categoryMap", "paymentMap"]),
        updateDay: function() {
            if(this.item && !isEmpty(this.item.updated_at)) {
                return dayjs(this.item.updated_at).format("YYYY/MM/DD");
            }
            return "";
        },
        imagePath:function() {
            if(this.item && !isEmpty(this.item.item_image)) {
                return this.item.item_image;
            }
            return "";
        },
        payment:function() {
            if(this.item && !isEmpty(this.item.payment_id)) {
                return this.paymentMap[this.item.payment_id];
            }
            return "-";
        },
        price:function() {
            if(this.item && !isEmpty(this.item.selling_price)) {
                return "¥",num2Yen(this.item.selling_price);
            }
            return "";
        },
        affiliatable: function() {
            if(this.item && this.item.affiliatable) {
                return "ON";
            }
            return "OFF";
        },
        display:function() {
            if(this.item && !isEmpty(this.item.display_status)){
                switch(this.item.display_status) {
                    case 0 : return "公開"; 
                    case 1 : return "限定公開";
                    case 2 : return "非公開";
                }
            }
            return "";
        },
        status:function() {
            if(this.item && !isEmpty(this.item.identity_status)){
                switch(this.item.identity_status) {
                    case 0 : return "下書き"; 
                    case 1 : return "審査中";
                    case 2 : return "否認";
                    case 3 : return "承認";
                    case 4 : return "停止";
                }
            }
            return "下書き";
        },
        canCopy:function() {
            return this.item.identity_status == 3;
        }
    },
    methods: {
        preview:function () {
            if(this.canCopy) {
                this.$router.push({path:"/item/" + this.item.uuid });
            }
        },
        detail:function() {
            this.$emit("open-detail", this.item);
        },
        copy:function() {
            if(this.canCopy) {
                this.$emit("open-copy", this.item);
            }
        }
    }
}
</script>