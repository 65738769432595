var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search"),
      _vm._v(" "),
      _c("h1", [_vm._v(_vm._s(_vm.item.item_name))]),
      _vm._v(" "),
      _c("div", { staticClass: "product_box" }, [
        _c("div", { staticClass: "mainContent" }, [
          _c("img", {
            staticClass: "product-image",
            attrs: { src: _vm.item.item_image, alt: "商品画像" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "product-content" }, [
            _vm._v(_vm._s(_vm.item.detail))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "sidebarContent" }, [
          _c(
            "div",
            { staticClass: "priceBox" },
            [
              _c("p", [
                _c("span", { staticClass: "priceBoxPrice" }, [
                  _vm._v(_vm._s(_vm.price(_vm.item)))
                ]),
                _c("span", { staticClass: "priceBoxPrice-op" }, [
                  _vm._v("(税込)")
                ])
              ]),
              _vm._v(" "),
              _vm.item.pay_mode == 0
                ? _c(
                    "router-link",
                    {
                      staticClass: "buyButton",
                      attrs: { to: `/payment/bank/${_vm.item.uuid}` }
                    },
                    [_vm._v("銀行振込で購入する")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.pay_mode == 1
                ? _c(
                    "router-link",
                    {
                      staticClass: "buyButton",
                      attrs: { to: `/payment/credit/${_vm.item.uuid}` }
                    },
                    [_vm._v("クレジット決済で購入する")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "op-buy-style" }, [
                _vm._v("支払形式:")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "op-buy-style-text" }, [
                _vm._v(_vm._s(_vm.payment(_vm.item)))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "authorProfile" }, [
                _c(
                  "span",
                  { staticClass: "op-sell-style" },
                  [
                    _vm._v("出品者:"),
                    _c(
                      "router-link",
                      {
                        staticClass: "op-link",
                        attrs: { to: `/profile/${_vm.item.member_id}` }
                      },
                      [_vm._v("▶プロフィールへ")]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", { staticClass: "op-sell-style-text" }, [
                  _c("div", { staticClass: "author-block" }, [
                    _c("img", {
                      staticClass: "authorImage",
                      attrs: { src: _vm.item.member.image_icon }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "authorName" }, [
                      _vm._v(_vm._s(_vm.item.member.seller_name))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "op-sellstart-style" }, [
                _vm._v("出品開始日:")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "op-sellstart-style-text" }, [
                _vm._v(_vm._s(_vm.publishDay(_vm.item)))
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.item.affiliatable == 1 && _vm.item.affiliate_margin,
                      expression:
                        "item.affiliatable == 1 && item.affiliate_margin"
                    }
                  ]
                },
                [
                  _c("span", { staticClass: "op-aff-style" }, [
                    _vm._v("紹介料:"),
                    _c(
                      "div",
                      {
                        staticClass: "op-link",
                        on: {
                          click: function($event) {
                            return _vm.copyUrl(
                              _vm.logined
                                ? `/item/${_vm.item.uuid}?partner=${_vm.userID}`
                                : `a`
                            )
                          }
                        }
                      },
                      [_vm._v("▶ 紹介リンク")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "op-aff-style-text" }, [
                    _vm._v(_vm._s(_vm.item.affiliate_margin) + "%")
                  ])
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("その他の商品")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "otherProductBox" },
            _vm._l(_vm.linkitems, function(i, index) {
              return _c(
                "div",
                { key: index, staticClass: "item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "category",
                      attrs: { to: `/category/${i.category_id}`, tabindex: "0" }
                    },
                    [_vm._v(_vm._s(_vm.categoryName(i)))]
                  ),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: `/item/${i.uuid}` } }, [
                    _c("img", {
                      staticClass: "product-image",
                      attrs: { src: i.item_image, alt: "商品画像" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: `/item/${i.uuid}` } }, [
                    _c("h3", [_vm._v(_vm._s(i.item_name))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-block" }, [
                    _c(
                      "div",
                      { staticClass: "author-block" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: `/profile/${i.member_id}` } },
                          [
                            _c("img", {
                              staticClass: "authorImage",
                              attrs: { src: i.member.image_icon }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          { attrs: { to: `/profile/${i.member_id}` } },
                          [
                            _c("span", { staticClass: "authorName" }, [
                              _vm._v(_vm._s(i.member.seller_name))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "price-block" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "price",
                            attrs: { to: `/item/${i.uuid}`, tabindex: "0" }
                          },
                          [_vm._v(_vm._s(_vm.price(i)))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(_vm.publishDay(i)) +
                              "\n                                "
                          ),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    i.affiliatable == 1 && i.affiliate_margin,
                                  expression:
                                    "i.affiliatable == 1 && i.affiliate_margin"
                                }
                              ],
                              staticClass: "affi-percent"
                            },
                            [_vm._v(_vm._s(i.affiliate_margin) + "%")]
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }