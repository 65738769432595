<style lang="scss" scoped>
.content {
    padding: 0 1rem;
    .pass-rule {
        font-size: 12px;
    }
    span {
        color: #d60000;
        font-size: 12px;
    }
    button {
        width: 100%;
        height: 40px;
        background-color: #00BADB;
        border: none;
        color: #fff;
    }
    .e-message {
        color: #EF5350;
        font-size: 12px;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">パスワード再設定</h1>
        <div class="reg row">
            <div class="regMail regContent col-sm-6">
                <h2>新しいパスワードを入力してください。</h2>
                <div class="regMailForm">
                    <div class="mb-3">
                        <label class="form-label">パスワード<span>※</span></label>
                        <div class="pass-rule">8文字以上の半角英数字で入力してください。</div>
                        <input class="form-control" type="password" name="password" v-model="member.password">
                        <div class="e-message" v-if="eMessage">8文字以上の半角英数字で入力してください。</div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">パスワード再入力<span>※</span></label>
                        <input class="form-control" type="password" name="password_confirmation" v-model="member.password_confirmation">
                        <div class="e-message" v-if="eMessage2">入力されたパスワードが異なっています。</div>
                    </div>
                    <button class="mt-3" type="submit" @click="submitRegist" required>再設定</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import ErrorText from "../../components/error-text";
import {USER_ARIES} from "../../store/modules/user-store";
import axios from 'axios';

export default {
    components: {
        "error-text" : ErrorText,
    },
    data:function() {
        return {
            eMessage: false,
            eMessage2: false,
            member: {
                id: "",
                password: "",
                password_confirmation: "",
            },
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","origin"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        submitRegist:function() {
            if(this.member.password.match(/^([a-zA-Z0-9]{8,})$/)) {
                this.eMessage= false;
                if(this.member.password === this.member.password_confirmation) {
                    axios.put(`/password/${this.member.id}`, this.member);
                    this.$router.push("/")
                } else {
                    this.eMessage2 = true;
                }
            } else {
                this.eMessage= true;
            }
        },
    },
    mounted:function() {
        const str = this.$route.path;
        const id = str.substr(str.indexOf('id_') + 3);
        this.member.id = id
        
    }
}
</script>