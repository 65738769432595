var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.banners[0]
    ? _c(
        "div",
        { staticClass: "banner_box" },
        [
          _c(
            "Slick",
            { ref: "slick", attrs: { options: _vm.slickOptions } },
            _vm._l(_vm.banners, function(banner, index) {
              return _c("div", { key: index }, [
                _c(
                  "a",
                  { attrs: { href: banner.url_link, target: "_blank" } },
                  [
                    _c("img", {
                      attrs: {
                        src: `https://s3.us-east-2.amazonaws.com/image.digi-tab.net${
                          banner.image
                        }`,
                        alt: "バナー画像"
                      }
                    })
                  ]
                )
              ])
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }