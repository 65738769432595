<style lang="scss" scoped>
@import "../../../sass/variables";
.content {
    padding: 0 1rem;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">パートナー利用規約</h1>
        <div class="reg row">
            <div class="regMail regContent col-12">
                <div class="terms">
                    <term-area></term-area>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import TermBun from "./parts/term-partner.vue";

export default {
    components: {
        "term-area" : TermBun
    }
    
}
</script>