<style lang="scss" scoped>
.form-label {
    margin-bottom: 1px;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">会員ログイン</h1>
        <div class="reg row">
            <div class="regMail regContent col-sm-6">
                <h2>メールアドレスでログイン</h2>
                <div v-show="error" class="alert alert-danger" role="alert">{{errorMessage}}</div>
                <form action="/login" method="post" class="regMailForm">
                    <input type="hidden" name="_token" v-model="token" />
                    <div class="mb-3">
                        <label class="form-label">メールアドレス</label>
                        <input class="form-control form-control-lg" type="text" name="email" placeholder="info@example.com" required>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">パスワード</label>
                        <input class="form-control form-control-lg" type="password" name="password" placeholder="パスワード" required>
                    </div>
                    <input type="submit" value="ログインする" required>
                </form>
                <span class="like-link right" @click="move('/password/reset')">パスワードをお忘れの方はこちら</span>
                <h2 style="color:red;"></h2>
            </div>
        </div>
        <div class="row">
            <div class="loginOther col-12">
                <h2>初めてご利用になるお客様</h2>
                <span class="link" @click="move('/register')">無料会員登録はこちら</span>
            </div>
        </div>
        
        <div class="contentAfter">
            <br style="clear: both;">
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { USER_ARIES } from "../../store/modules/user-store";
import {token} from "../../common/fetch-wrapper";
import Api from "../../common/fetch-wrapper";

export default {
    data:function () {
        return {
            token: token,
            error: false,
            errorMessage: ""
        }
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                console.debug(this.$router.currentRoute.path);
                this.$router.push({path:path});
            }
        }
    },
    mounted: function() {
        if(this.errors.length > 0) {
            Api.get("/member/status/" + this.errors[0]).then(x => {
                if(x.user_status == 2) {
                    this.errorMessage = "このアカウントは現在停止されています。"
                } else {
                    this.errorMessage = "ログインに失敗しました。"
                }
                this.error = true;
            }, (d) => {
                if(JSON.stringify(d) == "{}") {
                    this.errorMessage = "ログインに失敗しました。"
                    this.error = true;
                }
            });

            this.removeError();
        } else {
            this.errorMessage = "ログインに失敗しました。"
            this.removeError();
        }
    }
}
</script>