var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageContent" },
    [
      _c("div", { staticClass: "content no-login" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("購入済み商品")]),
        _vm._v(" "),
        _c("div", { staticClass: "reg" }, [
          _c("div", { staticClass: "regMail regContent row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              _vm._l(_vm.list, function(i) {
                return _c("order-row", {
                  key: i.id,
                  attrs: { item: i },
                  on: {
                    loading: _vm.setLoading,
                    "apply-return": _vm.applyReturn
                  }
                })
              }),
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.signal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }