<style lang="scss" scoped>

.content {
    padding: 0 1rem;
}
.form-label {
    margin-bottom: 1px;
    span {
        color: #d60000;
        font-size: 12px;
    }
}
.pass-rule {
    font-size: 12px;
}
.twin-block {
    .form-control {
        display: inline-block;
        width: 48%;
        &:first-of-type {
            margin-right: 3%;
        }
    }
}
.terms {
    margin: 1rem 0;
    padding: 1rem;
    border: 1px solid #333;
    height: 10rem;
    overflow-y: auto;
}
.term-check {
    margin-bottom: 1rem;
}
input[type='submit'] {
    cursor: pointer;
    &:disabled {
        background-color: #eaeaea;
        cursor:unset;
    }
}
.form-rule {
    font-size: 12px;
}
@media screen and (max-width: 896px) {
    .twin-block {
        .form-control {
            &:first-of-type {
                margin: 0;
            }
        }
    }
    .terms {
        padding: 10px;
        h5 {
            font-size: 16px;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">新規会員登録</h1>
        <div class="reg">
            <div class="regMail regContent">
                <form action="/register" method="POST" class="regMailForm" @submit="submitRegist">
                    <input type="hidden" name="_token" v-model="token" />
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label class="form-label">メールアドレス<span>※</span></label>
                                <input class="form-control" type="text" name="email" placeholder="info@example.com" v-model="form.email">
                                <error-text names="email" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">パスワード<span>※</span></label>
                                <div class="pass-rule">8文字以上の半角英数字で入力してください。</div>
                                <input class="form-control" type="password" name="password" v-model="form.password">
                                <error-text names="password" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">パスワード再入力<span>※</span></label>
                                <input class="form-control" type="password" name="password_confirmation" v-model="form.password_confirmation">
                                <error-text names="password_confirmation" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">氏名<span>※</span></label>
                                <div class="twin-block">
                                    <input class="form-control" type="text" name="family_name" placeholder="姓" v-model="form.family_name">
                                    <input class="form-control" type="text" name="first_name" placeholder="名" v-model="form.first_name">
                                </div>
                                <error-text names="family_name" :errmsg="errMsgs"></error-text>
                                <error-text names="first_name" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">フリガナ<span>※</span></label>
                                <div class="twin-block">
                                    <input class="form-control" type="text" name="family_name_kana" placeholder="セイ" v-model="form.family_name_kana">
                                    <input class="form-control" type="text" name="first_name_kana" placeholder="メイ" v-model="form.first_name_kana">
                                </div>
                                <error-text names="family_name_kana" :errmsg="errMsgs"></error-text>
                                <error-text names="first_name_kana" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">法人名</label>
                                <input class="form-control" type="text" name="corp_name" v-model="form.corp_name">
                                <error-text names="corp_name" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">法人番号</label>
                                <input class="form-control" type="text" name="corp_reg_no" v-model="form.corp_reg_no">
                                <error-text names="corp_reg_no" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label class="form-label">電話番号</label>
                                <input class="form-control" type="tel" name="phone" v-model="form.phone">
                                <error-text names="phone" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">郵便番号</label>
                                <input class="form-control" type="text" name="zip" v-model="form.zip">
                                <error-text names="zip" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">住所１（都道府県）</label>
                                <input class="form-control" type="text" name="pref" v-model="form.pref">
                                <error-text names="pref" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">住所２（市区町村）</label>
                                <input class="form-control" type="text" name="city" v-model="form.city">
                                <error-text names="city" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">住所３（番地）</label>
                                <input class="form-control" type="text" name="address" v-model="form.address">
                                <error-text names="address" :errmsg="errMsgs"></error-text>
                            </div>
                            <div class="mb-3">
                                <label class="form-label">住所４（マンション名など）</label>
                                <input class="form-control" type="text" name="apartment" v-model="form.apartment">
                                <error-text names="apartment" :errmsg="errMsgs"></error-text>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="terms">
                                <h5>利用規約</h5>
                                <term-area></term-area>
                            </div>
                            <div class="term-check">
                                <label class="form-check-label"><input class="form-check-input" type="checkbox" v-model="termOk" true-value="1" false-value="0">利用規約に同意する</label>
                            </div>
                            <input type="submit" value="会員登録を確定" :disabled="termOk == '0'">
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { isEmpty } from '../../common/com-func';
import Api,{token} from "../../common/fetch-wrapper";
import ErrorText from "../../components/error-text";
import {USER_ARIES} from "../../store/modules/user-store";
import TermArea from "../service/parts/term";

export default {
    components: {
        "error-text" : ErrorText,
        "term-area" : TermArea
    },
    data:function() {
        return {
            error: false,
            token : token,
            form: {
                family_name: "",
                first_name: "",
                family_name_kana: "",
                first_name_kana: "",
                email: "",
                phone: "",
                zip: "",
                password: "",
                password_confirmation: "",
                corp_name: "",
                corp_reg_no: "",
                zip: "",
                pref: "",
                city: "",
                address: "",
                apartment : ""
            },
            termAccept: "0",
            errMsgs: {},
            checkEnd: false,
            termOk:"0"
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        },
        submitRegist:function(e) {
            if(this.checkEnd) {
                return true;
            }
            e.preventDefault();
            Api.post("/member/validate", this.createForm(), (d) => {
                if(d.ok) {
                    this.checkEnd = true;
                    this.$el.querySelector("form").submit();
                    return;
                }
                if(d.status === 400 || d.status === 422) {
                    d.json().then(x => {
                        this.errMsgs = x.errors;
                    });
                }
            });
        },
        createForm:function() {
            const form = new FormData();
            form.append("_token", this.token);
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        },
        errorCss:function(name) {
            return {
                "uk-form-danger" : this.errMsgs[name] && this.errMsgs[name][0]
            };
        }
    }

}
</script>