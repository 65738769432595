<style lang="scss" scoped>
$side-menu-width : 200px;
.main-area {
    display: flex;
    .side-menu {
        width: $side-menu-width;
    }
    .contents-area {
        width: calc(100% - 200px);
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        .auto-footer {
            margin-top: auto;
        }

        // ページ遷移
        .slide-body-enter-active {
            transition: all .3s ease;
        }
        .slide-body-enter, .slide-body-leave-to {
            transform: translateY(20px);
            opacity: 0;
        }
    }
    @media screen and (max-width: 896px) {
        .side-menu {
            width: 0;
        }
        .contents-area {
            width: 100%;
            margin: 0;
        }
    }
}
</style>
<template>
<div class="main-area">
    <!-- サイドメニュー -->
    <side-menu class="side-menu"></side-menu>
    <div class="contents-area">
        <!-- コンテンツ内ヘッダ -->
        <g-header></g-header>
        <!-- メインコンテンツ -->
        <transition name="slide-body" appear>
            <router-view class="container" @loading="setLoader"></router-view>
        </transition>
        <!-- コンテンツ内フッタ -->
        <g-footer class="auto-footer"></g-footer>
    </div>
    <g-loader :visible="loading"></g-loader>
</div>
</template>
<script>
import header from "./components/header";
import footer from "./components/footer";
import SideMenu from "./components/side-menu";
import Loader from "./components/loading";
import routers from "./views/route";
import stores from "./store/store-all";

export default {
    store : stores,
    router : routers,
    components: {
        "g-header" : header,
        "g-footer" : footer,
        "side-menu" : SideMenu,
        "g-loader" : Loader
    },
    data:function() {
        return {
            loading:false
        };
    },
    methods: {
        setLoader:function(v) {
            this.loading = v;
        }
    }
}
</script>