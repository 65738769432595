<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-member 4px solid;
    }
}
.content {
    padding: 0 1rem;
}
.btn-primary.main {
    min-width: 150px;
    background-color: $color-main-member;
    border-color: $color-main-member;
    &.right {
        float: right;
    } 
}
.form-label {
    margin-bottom: 1px;
}
.twin-block {
    .form-control {
        display: inline-block;
        width: 48%;
        &:first-of-type {
            margin-right: 3%;
        }
    }
}
@media screen and (max-width: 896px) {
    .twin-block {
        .form-control {
            &:first-of-type {
                margin: 0;
            }
        }
    }
    .btn-primary.main {
        width: 100%;
        margin: 15px 0 0;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">会員情報</h1>
        <div class="reg">
            <div class="regMail regContent row">
                <div class="col-sm-6">
                    <div class="mb-3 mt-3">
                        <p>会員ID：{{ form.id }}</p>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">メールアドレス</label>
                        <input class="form-control" type="text" name="email" placeholder="info@example.com" v-model="form.email">
                        <error-text names="email" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">氏名</label>
                        <div class="twin-block">
                            <input class="form-control" type="text" name="family_name" placeholder="姓" v-model="form.family_name">
                            <input class="form-control" type="text" name="first_name" placeholder="名" v-model="form.first_name">
                        </div>
                        <error-text names="family_name" :errmsg="errMsgs"></error-text>
                        <error-text names="first_name" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">フリガナ</label>
                        <div class="twin-block">
                            <input class="form-control" type="text" name="family_name_kana" placeholder="セイ" v-model="form.family_name_kana">
                            <input class="form-control" type="text" name="first_name_kana" placeholder="メイ" v-model="form.first_name_kana">
                        </div>
                        <error-text names="family_name_kana" :errmsg="errMsgs"></error-text>
                        <error-text names="first_name_kana" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">法人名</label>
                        <input class="form-control" type="text" name="corp_name" v-model="form.corp_name">
                        <error-text names="corp_name" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">法人番号</label>
                        <input class="form-control" type="text" name="corp_reg_no" v-model="form.corp_reg_no">
                        <error-text names="corp_reg_no" :errmsg="errMsgs"></error-text>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">電話番号</label>
                        <input class="form-control" type="tel" name="phone" v-model="form.phone">
                        <error-text names="phone" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">郵便番号</label>
                        <input class="form-control" type="text" name="zip" v-model="form.zip">
                        <error-text names="zip" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">住所１（都道府県）</label>
                        <input class="form-control" type="text" name="pref" v-model="form.pref">
                        <error-text names="pref" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">住所２（市区町村）</label>
                        <input class="form-control" type="text" name="city" v-model="form.city">
                        <error-text names="city" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">住所３（番地）</label>
                        <input class="form-control" type="text" name="address" v-model="form.address">
                        <error-text names="address" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">住所４（マンション名など）</label>
                        <input class="form-control" type="text" name="apartment" v-model="form.apartment">
                        <error-text names="apartment" :errmsg="errMsgs"></error-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main" @click="submitRegist">保存</button>
                    </div>
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main right" @click="move('/member/delete')">退会はこちら</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { isEmpty } from '../../common/com-func';
import Api,{token} from "../../common/fetch-wrapper";
import ErrorText from "../../components/error-text";
import {USER_ARIES} from "../../store/modules/user-store";
import MsgModal from "../../components/msg-modal";
import MsgToast from "../../components/msg-toast";

export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    data:function() {
        return {
            error: false,
            token : token,
            form: {
                id: "",
                family_name: "",
                first_name: "",
                family_name_kana: "",
                first_name_kana: "",
                email: "",
                phone: "",
                zip: "",
                corp_name: "",
                corp_reg_no: "",
                zip: "",
                pref: "",
                city: "",
                address: "",
                apartment : ""
            },
            termAccept: "0",
            errMsgs: {},
            signal: {},
            toastMsg: {}
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","origin"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        },
        submitRegist:function(e) {
            this.signal = {
                title: "確認",
                message: "会員情報を更新します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    Api.put("/member/info", this.createForm(), (d) => {
                        if(d.ok) {
                            this.toastMsg = {message:"更新が完了しました。"};
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        },
        createForm:function() {
            const form = new FormData();
            form.append("_token", this.token);
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        },
        errorCss:function(name) {
            return {
                "uk-form-danger" : this.errMsgs[name] && this.errMsgs[name][0]
            };
        },
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        },
    },
    mounted:function() {
        if(this.origin) {
            Object.keys(this.form).forEach(x => {
                if(this.origin[x]) {
                    this.form[x] = this.origin[x];
                }
            });
        }
    }

}
</script>