var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("会員退会")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg" }, [
        _c("div", { staticClass: "regContent" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h4", { staticClass: "mt-3" }, [
                _vm._v("アカウントを削除すると、元に戻すことはできません。")
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "mt-5 btn_box" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary main",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [_vm._v("キャンセル")]
                ),
                _vm._v(" "),
                _c("a", { attrs: { href: "/deletecomplete" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary main",
                      attrs: { type: "button" },
                      on: { click: _vm.delte }
                    },
                    [_vm._v("退会する")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h3", [_vm._v("DIGITABアカウントの削除")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "\n                            DIGITABアカウントを削除すると、DIGITAB上で購入した商品をダウンロードできなくなります。"
      ),
      _c("br"),
      _vm._v(
        "\n                            あなたが出品した商品及びその他情報も削除されます。\n                        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }