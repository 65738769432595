import CryptoJS from "crypto-js";
import dayjs from "dayjs";

/**
 * BASE64 encode
 * @param {*} myString 
 */
export function encodeBase64(myString) {
    // PROCESS
    const encodedWord = CryptoJS.enc.Utf8.parse(myString);
    return CryptoJS.enc.Base64.stringify(encodedWord);
}
/**
 * BASE64 decode
 * @param {*} encoded 
 */
export function decodeBase64(encoded) {
    // PROCESS
    const encodedWord = CryptoJS.enc.Base64.parse(encoded);
    return CryptoJS.enc.Utf8.stringify(encodedWord);
}

/**
 * AES暗号化
 * @param {*} word 
 * @param {*} pwd 
 */
export function encryptAes(word,pwd) {
    return CryptoJS.AES.encrypt(word, pwd).toString();
}
/**
 * AES復号
 * @param {*} word 
 * @param {*} pwd 
 */
export function decryptAes(word,pwd) {
    return CryptoJS.AES.decrypt(word, pwd).toString(CryptoJS.enc.Utf8);
}

/**
 * 時刻表示
 * @param {*} x 
 */
export function convertTime(x) {
    if(isEmpty(x)) {
        return "";
    }
    let time = dayjs().format("YYYY-MM-DD");
    time += ("T" + x);
    return dayjs(time).format("HH:mm");
}

/**
 * null1→空文字変換
 * @param {*} value 
 */
export function n2e(value,def = "") {
    if(isEmpty(value)) {
        return def;
    }
    return value;
}
/**
 * 空文字チェック
 * @param {*} value 
 */
export function isEmpty(value) {
    if(value === undefined || value === null) {
        return true;
    }
    return value.toString() === "";
}

/**
 * 金額表記
 * @param {*} value 
 * @returns 
 */
export function num2Yen(value) {
    if(isEmpty(value)) {
        return "¥0";
    }
    return "¥" + (+value).toLocaleString()
}

/**
 * ゼロ埋め
 * @param {*} num 
 * @param {*} keta 
 * @returns 
 */
export function zeroPadding(num, keta) {
    let form = "";
    for(let i = 0; i< keta;i++) {
        form += "0";
    }
    let v = n2e(num, 0);
    if(!isFinite(v)) {
        v = 0;
    }
    return (form + v).slice(-keta);
}