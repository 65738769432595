import { isEmpty,n2e,encryptAes,decryptAes,encodeBase64} from "../../common/com-func";
import Api , {token} from "../../common/fetch-wrapper";

// ユーザー情報
export const USER_ARIES = "userKey";

// ユーザー情報
let user = {
    family_name : "",
    first_name : ""
};
const userdom = document.querySelector("#user-json");
if(userdom) {
    user = JSON.parse(userdom.textContent);
    userdom.remove();
}

// サーバーエラー情報
let errMsgs = [];
const errdom = document.querySelector("#error-json");
if(errdom) {
    errMsgs = JSON.parse(errdom.textContent);
    errdom.remove();
}

export default {
    namespaced: true,
    state:() => ({
        userInfo: user,
        userInput: {},
        token: token,
        svrErr: errMsgs
    }),
    getters: {
        sessionKey: function(state) {
            return state.userInfo.sessionKey;
        },
        logined:function(state) {
            return !isEmpty(state.userInfo.loginKey);
        },
        userID:function(state) {
            return state.userInfo.origin.id;
        },
        userNameSim:function(state) {
            const basis = n2e(state.userInfo.family_name) + n2e(state.userInfo.first_name);
            if(isEmpty(basis)) {
                return ""
            }
            return basis;
        },
        userName:function(state) {
            const basis = n2e(state.userInfo.family_name) + n2e(state.userInfo.first_name);
            if(isEmpty(basis)) {
                return "ゲストさん"
            }
            return basis + "さん";
        },
        userIcon:function(state) {
            return n2e(state.userInfo.origin.image_icon);
        },
        orgId:function(state) {
            return n2e(state.userInfo.orgId);
        },
        firstName:function(state) {
            return n2e(state.userInfo.first_name);
        },
        familyName: function(state) {
            return n2e(state.userInfo.family_name);
        },
        email:function(state) {
            return n2e(state.userInfo.origin.email);
        },
        stripePubKey:function(state) {
            return state.userInfo.pubKey;
        },
        userInput:function(state) {
            return state.userInput;
        },
        origin: function(state) {
            return state.userInfo.origin;
        },
        sellerOK:function(state) {
            if(!state.userInfo.origin) {
                return false;
            }
            return state.userInfo.origin.owner_status === 1;
        },
        partnerOK:function(state) {
            if(!state.userInfo.origin) {
                return false;
            }
            return state.userInfo.origin.partner_status === 1;
        },
        stripeCardList:function(state) {
            if(!state.userInfo || !state.userInfo.cardList) {
                return [];
            }
            return state.userInfo.cardList.map(x => {
                return {
                    id : x.id,
                    name : x.brand + " **** **** **** " + x.last4 
                };
            });
        },
        squareAppId:function(state) {
            return state.userInfo.square.appId;
        },
        squareLocId:function(state) {
            return state.userInfo.square.locId;
        },
        paypalCId:function(state) {
            return state.userInfo.paypal.cid;
        },
        paypalSec:function(state) {
            return state.userInfo.paypal.sec;
        },
        errors: function(state) {
            return state.svrErr;
        }
    },
    mutations: {
        removeErr:function(state) {
            state.svrErr = [];
        },
        loadUserInput:function(state) {
            if(isEmpty(state.userInfo.loginKey)) {
                state.userInput = {};
                return;
            }
            try {
                const key = encodeBase64(state.userInfo.loginKey + "-user.input");
                const pwd = encodeBase64(key);
                const raw = localStorage.getItem(key);
                if(isEmpty(raw)) {
                    state.userInput = {};
                    return;
                }
                state.userInput = JSON.parse(decryptAes(raw,pwd));
            } catch(e) {
                state.userInput = {};
            }
        },
        saveUserInput:function(state, info) {
            if(isEmpty(state.userInfo.loginKey)) {
                state.userInput = {};
                return;
            }
            state.userInput = info;
            try {
                const key = encodeBase64(state.userInfo.loginKey + "-user.input");
                const pwd = encodeBase64(key);
                const data = encryptAes(JSON.stringify(info),pwd);
                localStorage.setItem(key, data);
            } catch(e) {
                console.error(e);
            }
        },
        saveMyself:function(state, info) {
            Object.keys(state.userInfo.origin).forEach(x => {
                if(info[x] !== undefined) {
                    state.userInfo.origin[x] = info[x];
                }
            });
        }
    },
    actions: {
        removeError:function({commit}) {
            commit("removeErr");
        },
        updateMyself:function({commit},info) { 
            commit("saveMyself", info);
        },
    }
};