<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-member 4px solid;
    }
}
.content {
    padding: 0 1rem;
    h4 {
        color: #f00;
        font-size: 18px;
    }
    .item_download {
        color: #444;
    }
    .btn {
        min-width: 150px;
    }
    .btn-primary.main {
        background-color: $color-main-member;
        border-color: $color-main-member;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">会員退会完了</h1>
        <div class="reg">
            <div class="regContent">
                <div class="row">
                    <div class="col-12">
                        <h3>DIGITABからの退会処理が完了しました。</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 mt-3">
                        <p>
                            ご利用いただきありがとうございました。
                        </p>
                        <div class="mt-5">
                            <button type="button" class="btn btn-primary main" @click="move('/')">TOPページへ</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    methods: {
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        },
    },
}
</script>