<style lang="scss" scoped>
.scroll.high {
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }
    h5 {
        font-size: 14px;
    }
    ul {
        padding-left: 22px;
        font-size: 13px;
        list-style: disc;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 896px) {
    p {
        font-size: 12px;
    }
}
</style>
<template>
<div class="scroll high">
    <h3>会社名</h3>
    <p>株式会社ソリッドアライズ</p>

    <h3>運営統括責任者</h3>
    <p>江口 潤</p>

    <h3>所在地</h3>
    <p>〒104-0054<br>
        東京都港区浜松町2丁目2−15 浜松町ダイヤビル2F</p>

    <h3>お問い合わせ</h3>
    <p><a href="mailto:support@digi-tab.com">support@digi-tab.com</a></p>

    <h3>営業日</h3>
    <p>月曜日～金曜日 12:00～19:00</p>

    <h3>販売商品・サービス</h3>
    <p>ソフトウェア、電子書籍、PDF<br>
        動画データ、オンラインサロン、画像、文章など</p>

    <h3>商品の発送方法、ご提供方法</h3>
    <p>オンライン上でのダウンロード、または配信</p>

    <h3>ホームページ</h3>
    <p>https://digi-tab.net</p>

    <h3>商品のご提供時期</h3>
    <p>原則として弊社によるお客様のご入金完了後即ご提供いたします。</p>

    <h3>商品代金以外の必要料金</h3>
    <p>銀行振り込みご利用の場合は、商品代金（税込表示）に加えて振り込み手数料がかかります。</p>

    <h3>ご注文方法</h3>
    <p>ホームページ上の専用申込フォームよりご注文ください。</p>


    <h3>お支払い方法銀行振込 / クレジットカード決済</h3>
    <p>ご返金にかかる費用お客様のご都合によるご返金の場合、各種手数料および振込手数料がすべてお客様負担となります。あらかじめご了承ください。
        プライバシーポリシーDigitab上で取り扱うお客様の個人情報につきまして、適切に保護することが社会的責務として重要と考えます。</p>
    【url】

    <h3>投資に係るリスクについて</h3>
    <p>◆株式の取引は、株価の変動等により損失が生じるおそれがあります。信用取引は、少額の委託保証金で多額の取引を行うことができることから、時として多額の損失が発生する可能性を有しています。</p>

    <p>◆外国為替保証金取引、（株価指数）CFD取引は、取引額（約定代金）に対して少額の必要保証金をもとに取引を行うため、必要保証金に比べ多額の利益を得ることもありますが、
        その一方で短期間のうちに多額の損失を被る可能性があります。また、通貨等の価格又は金融指標の数値の変動により損失が生ずるおそれがあり、かつその損失の額が預託した保証金の額を上回ることがあります。</p>

    <p>◆指数先物取引は、対象とする指数の変動等により上下しますので、これにより損失を被ることがあります。市場価格が予想とは反対の方向に変化したときには、比較的短期間
        のうちに証拠金の大部分、またはそのすべてを失うこともあります。その損失は証拠金の額だけに限定されません。また、指数先物取引は、少額の証拠金で多額の取引を行うことができることから、時として多額の損失を被る危険性を有しています。</p>

    <p>◆暗号資産関連取引は、取引対象である暗号資産等の価格変動により損失が生じることがあります。暗号資産の価格は、需給バランスの変化や、物価、法定通貨、
        他の市場の動向、天災地変、戦争、政変、法令・規制の変更、暗号資産に係る状況の変化、その他の予期せぬ事象や特殊な事象等による影響により、急激に変動、下落する可能性があり、価格がゼロとなる可能性があります。</p>

    <p>◆これら金融商品は、元本及び利益が保証されるものではありません。</p>
    <p>お取引を始めるにあたっては、ご利用の金融機関が発行する「契約締結前交付書面」等を熟読し、取引内容や仕組み、リスク等を十分にご理解いただいた上で、ご自身の判断にてお取引くださるようお願い致します。</p>

    <h3>当社の苦情処理措置、紛争解決措置について</h3>
    <p>商品への苦情、キャンセル、解約、および返金の申請は、出品者のプロフィールページに明記された特定商取引法に関する表記にもとづき購入者が特定商取引法に基づく表示の販売主体者に直接行うものとします。</p>
    <p>キャンセルおよび返金の可否の判断は、購入者の申請により特定商取引法に基づく表記の販売主体者が決定するものとし、運営元Digitabは関与しないものとします。</p>

    <h3>注意点</h3>
    <p>当ページに記載する「代金以外の必要料金」「注文方法」「お支払い方法・期限」「商品注文後のキャンセル」「返金にかかる費用」の各項目はDigitabで販売する全ての商品に適用されますが、商品毎にご案内がある場合は、商品毎に記載いたします。</p>
    <p>Digitabが販売者である場合は、商品ページにDigitabの「特定商取引に関する法律」に基づく表記を行ないます。</p>
    <p>出品者が販売者であり、且つ出品者が「事業者」である場合は、出品者の「特定商取引に関する法律」に基づく表記を行ないます。</p>
    <p>出品者が「事業者」に該当するかは出品者の判断によります。ただし、 経済産業省 特定商取引法の通達の改正について</p>
    <p>「インターネット・オークションにおける<a href="https://www.caa.go.jp/policies/policy/consumer_transaction/amendment/2016/pdf/amendment_171206_0001.pdf" target="new">「販売業者」に係るガイドライン」</a></p>
    <p>を鑑み「事業者」であることが明らかな出品者については、「事業者」として扱い開示請求があった場合は検討・審査後対応します。</p>
</div>
</template>
<script>
export default {
    
}
</script>