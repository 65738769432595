<style lang="scss" scoped>
#index-part {
    width: calc(100% + 24px);
    margin: 0 -12px;
    overflow-x: hidden;
    img{
        width: auto;
        max-width: 100%;
    }
    .frame {
        max-width: 800px;
        margin: 0 auto;
    }

    #sec2{
        margin-top: 20px;
        .flex{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap:wrap;
        margin-bottom: 30px;
        padding:0 20px;
        figure{
                width: 42%;
            }
        }
    }

    #sec3 figure {
        width: 100%;
        margin: 0 auto;
    }

    #sec4 {
        margin: 20px auto;
        .frame {
        background-color: #fff;
        padding: 25px 10px;
        }
        .sec4-box {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                padding: 0px;
                width: 46%;
                a{
                    display: block;
                    opacity: 1;
                    transition : .4s;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    &:hover {
                        padding-top: 0px;
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
    #sec5{
        background-color: #44d7ff;
        text-align: center;
        padding: 50px;
        .sec5-title{
            margin-bottom: 2rem;
        }
        .frame{
            position: relative;
            figure {
                &:nth-child(2) {
                    width: 300px;
                    position: absolute;
                    left: -250px;
                    bottom: 0;
                }

                &:nth-child(3) {
                    width: 300px;
                    position: absolute;
                    right: -250px;
                    top: 0;
                    z-index: 1;
                }
            }
        }
        .sec5-box {
            p {
                color: #fff;
                font-size: 1rem;
                line-height: 1.8rem;
                padding: 10px 0;
                margin: 0;
                font-weight: 800;
                font-style: italic;
            }
        }
    }
}
@media screen and (max-width: 1024px) {
    #index-part {
        .frame{
            max-width: 92%;
        }
        #sec2{
            margin-top: 10px;
            .flex{
                justify-content: space-around;
                margin-bottom: 20px;
                padding:0;
                figure{
                    width: 46%;
                }
            }
        }

        #sec3 figure {
            margin: 0 auto;
        }

        #sec4 {
            .frame {
                background-color: #fff;
                padding: 5px;
            }
            .sec4-box {
                display: flex;
                li {
                    width: 50%;
                    padding: 0px;
                }
            }
        }
        #sec5{
            padding: 50px 40px;
            .frame{
                figure {
                    &:nth-child(2) {
                        display: none;
                    }
                    &:nth-child(3) {
                        position: static;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                    }
                }
            }
            .sec5-box {
                margin-bottom: 10px;
                p {
                    color: #fff;
                    font-size: 0.8rem;
                    line-height: 1.4rem;
                    padding: 10px 0;
                    font-weight: normal;
                }
            }
        }
    }
}

/*共通*/
html {
  font-size: 10px;
}
body {
  background-color: #f8fafc;
}
img {
  width: 100%;
}
</style>
<template>
<div id="index-part">
    <section id="sec2">
        <div class="frame">
            <div class="flex">
                <figure><img :src="`${s3Url}/title.png`" alt="DIGITAB ヘルプページ　利用ガイド"></figure>
                <figure><img :src="`${s3Url}/logo.png`" alt="DIGITAB ヘルプページ　利用ガイド"></figure>
            </div>
        </div>
    </section>

    <section id="sec3">
        <div class="frame">
            <figure>
                <Banner />
                <a href="https://digi-tab.net/news/18"><img :src="`${s3Url}/bana.png`" alt="デジタブ ベータテストを実施中"></a>
            </figure>
        </div>
    </section>

    <section id="sec4">
        <div class="frame">
            <ul class="sec4-box">
                <li>
                    <router-link to="/category/all"><img :src="`${s3Url}/syohin-item.png`" alt="商品一覧"></router-link>
                </li>
                <li>
                    <router-link to="/news"><img :src="`${s3Url}/syohin-item2.png`" alt="お知らせ"></router-link>
                </li>
                <li>
                    <router-link to="/register"><img :src="`${s3Url}/syohin-item3.png`" alt="新規会員登録"></router-link>
                </li>
                <li>
                    <a href="https://digi-tab-support.com/"><img :src="`${s3Url}/syohin-item4.png`" alt="ヘルプセンター"></a>
                </li>
                <li>
                    <a href="https://digi-tab-support.com/help-manual-sell.html"><img :src="`${s3Url}/syohin-item5.png`" alt="出品のやり方"></a>
                </li>
                <li>
                    <a href="https://digi-tab-support.com/help-manual-partner.html"><img :src="`${s3Url}/syohin-item6.png`" alt="パートナーのやり方"></a>
                </li>
            </ul>
        </div>
    </section>

    <section id="sec4">
        <div class="frame" style="border: 3px solid red;">
            <h2 style="text-align: center;">Important Notice</h2>
            <p style="text-align: center;">重要なお知らせ</p>
            <p style="margin-top:20px;">
                平素よりDIGITABをご利用いただき、誠にありがとうございます。</br></br>

                この度、金融商品取引法に抵触する可能性のある商品に関しまして、一部の商品にトラブルが発生いたしました。</br>
                これに伴い、今後DIGITABでの上記に該当する商品の出品を禁止させていただきます。</br></br>

                ご利用の皆様にはご迷惑をおかけし申し訳ございません。</br>
                何卒ご理解とご協力を賜りますようお願い申し上げます。
            </p>
        </div>
    </section>

    <section id="sec5">
        <div class="frame">
            <div class="sec5-box">
                <div class="sec5-title"><img :src="`${s3Url}/subtitle.png`" alt="DIGITABとは"></div>
                <p>Digitabとは、電子書籍・画像・動画や学習教室加入の権利、製作依頼など無形商品を<br>幅広くを扱う代理販売サイトです。</p>
                <p>個人で無形商品（実際に形を持たない商品）を売るためには様々な準備が必要となり多<br>大な負担がかかります。</p>
                <p>Digitabでは、気軽に無形商品を販売・購入するためのサービスを行っています。</p>
            </div>
            <figure><img :src="`${s3Url}/imgitem.png`" alt="決済代行サービス"></figure>
            <figure><img :src="`${s3Url}/imgitem2.png`" alt="デジタブ"></figure>
        </div>
    </section>
</div>
</template>
<script>
import Banner from "./banner.vue";
export default {
    components: {
        Banner: Banner,
    },
    data:function() {
        return {
            s3Url: "https://s3.us-east-2.amazonaws.com/image.digi-tab.net/top-view"
        }
    }
}
</script>