var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageContent" },
    [
      _c("div", { staticClass: "content no-login" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("会員情報")]),
        _vm._v(" "),
        _c("div", { staticClass: "reg" }, [
          _c("div", { staticClass: "regMail regContent row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("div", { staticClass: "mb-3 mt-3" }, [
                _c("p", [_vm._v("会員ID：" + _vm._s(_vm.form.id))])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("メールアドレス")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.email,
                        expression: "form.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      name: "email",
                      placeholder: "info@example.com"
                    },
                    domProps: { value: _vm.form.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "email", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [_vm._v("氏名")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "twin-block" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.family_name,
                          expression: "form.family_name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "family_name",
                        placeholder: "姓"
                      },
                      domProps: { value: _vm.form.family_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "family_name", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.first_name,
                          expression: "form.first_name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "first_name",
                        placeholder: "名"
                      },
                      domProps: { value: _vm.form.first_name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "first_name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "family_name", errmsg: _vm.errMsgs }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "first_name", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("フリガナ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "twin-block" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.family_name_kana,
                          expression: "form.family_name_kana"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "family_name_kana",
                        placeholder: "セイ"
                      },
                      domProps: { value: _vm.form.family_name_kana },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form,
                            "family_name_kana",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.first_name_kana,
                          expression: "form.first_name_kana"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        name: "first_name_kana",
                        placeholder: "メイ"
                      },
                      domProps: { value: _vm.form.first_name_kana },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form,
                            "first_name_kana",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "family_name_kana", errmsg: _vm.errMsgs }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "first_name_kana", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("法人名")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.corp_name,
                        expression: "form.corp_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "corp_name" },
                    domProps: { value: _vm.form.corp_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "corp_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "corp_name", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("法人番号")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.corp_reg_no,
                        expression: "form.corp_reg_no"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "corp_reg_no" },
                    domProps: { value: _vm.form.corp_reg_no },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "corp_reg_no", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "corp_reg_no", errmsg: _vm.errMsgs }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("電話番号")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.phone,
                        expression: "form.phone"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "tel", name: "phone" },
                    domProps: { value: _vm.form.phone },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "phone", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "phone", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("郵便番号")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.zip,
                        expression: "form.zip"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "zip" },
                    domProps: { value: _vm.form.zip },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "zip", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "zip", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("住所１（都道府県）")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.pref,
                        expression: "form.pref"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "pref" },
                    domProps: { value: _vm.form.pref },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "pref", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "pref", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("住所２（市区町村）")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.city,
                        expression: "form.city"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "city" },
                    domProps: { value: _vm.form.city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "city", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "city", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("住所３（番地）")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.address,
                        expression: "form.address"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "address" },
                    domProps: { value: _vm.form.address },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "address", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "address", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("住所４（マンション名など）")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.apartment,
                        expression: "form.apartment"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: "apartment" },
                    domProps: { value: _vm.form.apartment },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "apartment", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "apartment", errmsg: _vm.errMsgs }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main",
                    attrs: { type: "button" },
                    on: { click: _vm.submitRegist }
                  },
                  [_vm._v("保存")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main right",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.move("/member/delete")
                      }
                    }
                  },
                  [_vm._v("退会はこちら")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.signal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }