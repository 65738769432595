<style lang="scss" scoped>
@import "../../../sass/variables";
h3 {
    color: $black-font;
    font-size: 28px;
    letter-spacing: 2px;
    padding: 20px 0;
    font-weight: normal;
}
.red {
    color: #ff6565;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
}
.textBox {
    background-color: #f3f3f3;
    padding: 24px;
    max-width: 100%;
    width: auto;
    border: 1px solid $color-main-free;
    color: $black-font;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        font-size: 100%;
        vertical-align: baseline;
        background: transparent;
    }
}
.btn-primary.main {
    min-width: 150px;
    background-color: $color-main-free;
    border-color: $color-main-free;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login" v-show="loaded">
        <h1 class="page-title">注文完了</h1>
        <div class="reg">
            <div class="regContent">
                <div class="row">
                    <div class="col-12">
                        <h3>ご注文が完了致しました。</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="bank" v-show="bankMode">
                            <p class="red">まだ購入は完了していません。</p>
                            <p>本日から<span class="strong">3日間以内</span>に振込完了してください。<br>
                            振込が行われなかった場合、注文がキャンセルされます。</p>
                        </div>
                        <p>あなた宛に確認メールが送信されています。ご確認ください。</p>
                        <p>ご利用いただき誠にありがとうございます。</p>
                        <div class="credit">
                            <button type="button" class="btn btn-primary main" @click="download" v-if="paytype == 0">ファイルダウンロード</button>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="textBox" v-show="bankMode">
                            <h4>振込先情報</h4>
                            <ul>
                                <li>金融機関名：{{ order.bank_name }}</li>
                                <li>金融機関コード：{{ order.bank_code }}</li>
                                <li>支店コード：{{ order.branch_code }}</li>
                                <li>支店名：{{ order.branch_name }}</li>
                                <li>口座番号：{{ order.bank_no }}</li>
                                <li>預金種別：{{ order.bank_type }}</li>
                                <li>口座名義：{{ order.bank_holder }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { saveAs } from "file-saver";
import { isEmpty } from '../../common/com-func';
import Api from "../../common/fetch-wrapper";

export default {
    data:function() {
        return {
            orderId: "",
            paytype: "0",
            order: {},
            loaded: false
        };
    },
    computed: {
        bankMode:function() {
            return this.paytype == "1" && !this.canDL;
        },
        creditMode:function() {
            return this.paytype == "0";
        },
        canDL:function() {
            if(isEmpty(this.orderId)) {
                return false;
            }
            return !isEmpty(this.order.dl_file_name_org) && this.order.status == "1"; 
        }
    },
    methods: {
        download:function() {
            this.$emit("loading",true);
            Api.getBlob("/order/download/" + this.orderId).then(x => {
                saveAs(x,this.order.dl_file_name_org);
            }).finally(() => {
                this.$emit("loading",false);
            });
        }
    },
    mounted:function() {
        this.paytype = this.$route.params.type;
        this.orderId = this.$route.params.id;
        if(["0","1"].indexOf(this.paytype) < 0) {
            // topページへとばす
            location.href = "/";
            return;
        }
        this.$emit("loading",true);
        Api.get("/order/" + this.orderId).then(x => {
            if(!x || isEmpty(x.order_id)) {
                location.href = "/";
                return;
            }
            this.order = x;
        }).finally(() => {
            this.$emit("loading",false);
            this.loaded = true;
        });
    }
}
</script>