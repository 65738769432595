var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "full-overlay"
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "windows8" }, [
      _c("div", { staticClass: "wBall", attrs: { id: "wBall_1" } }, [
        _c("div", { staticClass: "wInnerBall" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wBall", attrs: { id: "wBall_2" } }, [
        _c("div", { staticClass: "wInnerBall" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wBall", attrs: { id: "wBall_3" } }, [
        _c("div", { staticClass: "wInnerBall" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wBall", attrs: { id: "wBall_4" } }, [
        _c("div", { staticClass: "wInnerBall" })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "wBall", attrs: { id: "wBall_5" } }, [
        _c("div", { staticClass: "wInnerBall" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }