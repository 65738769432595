<style lang="scss" scoped>
@import "../../../../sass/variables";
.modal-header {
    display: block;
    .header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
    }
    .reject-message {
        margin: 1rem 0 0;
        p {
            color: #EF5350;
            font-size: 12px;
            margin: 0;
        }
    }
}
.btn.btn-primary {
    background-color: $color-main-seller;
    border-color: $color-main-seller;
    width: 10rem;
}
.img-area {
    margin: 5px 0;
    img {
        width:100%;
    }
}
.dl-area {
    margin: 8px 0;
    button {
        float: right;
    }
}
.dl-file-val {
    color: #EF5350;
    font-size: 12px;
}
.affiliate-margin {
    display: flex;
    align-items: center;
}
</style>
<template>
<div class="modal fade" tabindex="-1" aria-hidden="true" data-bs-backdrop="static">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <div class="header">
                    <h5 class="modal-title">商品詳細（状況：{{statusName}}）</h5>
                    <button type="button" class="btn-close" @click="close"></button>
                </div>
                <div class="reject-message" v-if="form.identity_status == 2">
                    <p>現在この商品は、否認されています。</p>
                    <p>アカウント登録時のメールアドレスに詳細が記載されていますのでご確認ください。</p>
                </div>
            </div>
            <!-- メインコンテンツ -->
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">商品名</label>
                            <input class="form-control" type="text" v-model="form.item_name" :disabled="!canPublish">
                            <error-text names="item_name" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">商品画像</label>
                            <input type="file" class="form-control" accept="image/png, image/jpeg" @change="changeImage" :disabled="!canPublish" />
                            <span>推奨画像サイズ:1200px × 300px 画像容量:2mb以下</span>
                            <error-text names="item_image_f" :errmsg="errMsgs"></error-text>
                            <error-text names="item_image" :errmsg="errMsgs"></error-text>
                            <div class="img-area" v-show="form.item_image != ''">
                                <img :src="form.item_image">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">商品説明</label>
                            <textarea rows="10" class="form-control" v-model="form.detail" :disabled="!canPublish"></textarea>
                            <error-text names="detail" :errmsg="errMsgs"></error-text>
                        </div>
                        <!-- <div class="mb-3">
                            <label class="form-label">特定商法取引に基づく表記</label>
                            <textarea rows="10" class="form-control" v-model="form.term"  :disabled="!canPublish"></textarea>
                            <error-text names="term" :errmsg="errMsgs"></error-text>
                        </div> -->
                    </div>
                    <div class="col-sm-6">
                        <div class="mb-3">
                            <label class="form-label">DL対象ファイル</label>
                            <input type="file" class="form-control" @change="changeDLFile" :disabled="!canPublish" />
                            <error-text names="dl_file_name_f" :errmsg="errMsgs"></error-text>
                            <error-text names="dl_file_name" :errmsg="errMsgs"></error-text>
                            <div class="dl-area" v-show="downloadable">
                                <span>ファイル名:{{form.dl_file_name_org}}</span>
                                <button type="button" class="btn btn-primary" @click="download">ダウンロード</button>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">価格</label>
                            <input class="form-control" type="number" v-model="form.selling_price" :disabled="!canPublish && !copy">
                            <error-text names="selling_price" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">カテゴリー選択</label>
                            <select class="form-select" v-model="form.category_id" :disabled="!canPublish">
                                <option value="">-</option>
                                <option v-for="c in categories" :key="c.id" :value="c.id">{{c.name}}</option>
                            </select>
                            <error-text names="category_id" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">支払い方式</label>
                            <select class="form-select" v-model="form.payment_id" :disabled="!canPublish">
                                <option v-for="c in payments" :key="c.id" :value="c.id">{{ c.name }}</option>
                            </select>
                            <error-text names="payment_id" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">決済設定</label>
                            <!--
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.pay_mode" value="0"> 銀行振込</label>
                            </div>
                            -->
                            <div v-for="c in banks" :key="c.id" class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.pay_mode" :value="[0,c.id]">銀行振込({{c.bank_holder}})
                                </label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.pay_mode" :value="[1,null]"> クレジット</label>
                            </div>
                            <error-text names="display_status" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">公開設定</label>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.display_status" value="0"> 公開</label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.display_status" value="1"> 限定公開</label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.display_status" value="2"> 非公開</label>
                            </div>
                            <error-text names="display_status" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">アフィリエイト機能</label>
                            <div class="form-check">
                                <label class="form-check-label"><input class="form-check-input" type="radio" v-model="form.affiliatable" value="0"> OFF</label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label"><input class="form-check-input" type="radio" v-model="form.affiliatable" value="1"> ON</label>
                            </div>
                            <error-text names="affiliatable" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">アフィリエイトマージン</label>
                            <div class="affiliate-margin col-sm-6">
                                <input class="form-control" type="text" v-model="form.affiliate_margin" :disabled="form.affiliatable == '0' || !canPublish && !copy">
                                <span>%</span>
                            </div>
                            <error-text names="affiliate_margin" :errmsg="errMsgs"></error-text>
                        </div>
                        <!-- <div class="mb-3">
                            <label class="form-label">返品設定</label>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.returnable" value="0" :disabled="!canPublish"> OFF</label>
                            </div>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input class="form-check-input" type="radio" v-model="form.returnable" value="1" :disabled="!canPublish"> ON</label>
                            </div>
                            <error-text names="returnable" :errmsg="errMsgs"></error-text>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">返品条件</label>
                            <textarea rows="5" class="form-control" v-model="form.return_cond" :disabled="form.returnable == '0' || !canPublish"></textarea>
                            <error-text names="affiliate_margin" :errmsg="errMsgs"></error-text>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="close">閉じる</button>
                <button type="button" class="btn btn-primary" v-if="canChange&&!copy" @click="decide(false)">保存</button>
                <button type="button" class="btn btn-primary" v-if="copy" @click="decide(false)">コピー</button>
                <button type="button" class="btn btn-primary" v-if="canPublish" @click="decide(true)">申請</button>
                <button type="button" class="btn btn-primary" v-if="canDelete" @click="deleteData">削除</button>
            </div>
        </div>
    </div>
    <msg-modal :signal="msgSignal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import Modal from "bootstrap/js/src/modal";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import { isEmpty } from '../../../common/com-func';
import Api from "../../../common/fetch-wrapper";
import ErrorText from "../../../components/error-text";
import {CACHE_ARIES} from "../../../store/modules/cache-store";
import MsgToast from "../../../components/msg-toast";
import MsgModal from "../../../components/msg-modal";


export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    props:["signal"],
    data:function() {
        return {
            modal:{},
            errMsgs:{},
            msgSignal: {},
            toastMsg: {},
            form: this.createNewForm(),
            itemId: "",
            status : "",
            copy: false,
            canChanging: false,
        }
    },
    computed: {
        ...mapGetters(CACHE_ARIES,["banks","categories", "payments"]),
        statusName:function() {
            switch(this.status) {
                case 0 : return "下書き"; 
                case 1 : return "審査中";
                case 2 : return "否認";
                case 3 : return "承認";
                case 4 : return "停止";
            }
            return "下書き";
        },
        downloadable:function() {
            return !isEmpty(this.form.dl_file_name_org);
        },
        canChange:function() {
            return [0,2,3].indexOf(this.form.identity_status) > -1 || this.canChanging;
        },
        canPublish:function() {
            return [0,2].indexOf(this.form.identity_status) > -1 || this.canChanging;
        },
        canDelete:function() {
            return !isEmpty(this.itemId) && this.canPublish;
        }
    },
    watch:{
        signal:function(nv) {
            this.canChanging = false;
            this.form = this.createNewForm();
            this.itemId = "";
            this.status = 0;
            if(nv.form) {
                Object.keys(this.form).forEach(k => {
                    if(nv.form[k]) {
                        this.form[k] = nv.form[k];
                    }
                });
                this.form.pay_mode = [nv.form.pay_mode,nv.form.bank_id];
                this.itemId = nv.form.id;
                this.status = nv.form.identity_status;
                this.copy = nv.copy;
            }
            this.errMsgs = "";
            this.modal.show();
        },
        "form.returnable": function(nv) {
            if(nv == 0) {
                this.form.return_cond = "";
            }
        },

    },
    methods: {
        createNewForm:function() {
            return {
                item_name : "", 
                item_image : "", 
                item_image_f : "", 
                dl_file_name : "", 
                dl_file_name_f : "", 
                dl_file_name_org : "",
                selling_price : "", 
                detail : "", 
                // term : "販売者名	\r\n-\r\n\r\n所在地\r\n-\r\n\r\n電話番号\r\n-\r\n\r\nメールアドレス\r\n-\r\n\r\n商品引き渡し方法\r\n-\r\n\r\n商品引き渡し時期\r\n-\r\n\r\n返品・不良品について\r\n-", 
                category_id : "", 
                payment_id : 1,
                pay_mode : [0,0],
                bank_id : null, 
                display_status : 0,
                affiliatable : 0, 
                affiliate_margin :"", 
                returnable : 0, 
                return_cond : "",
                identity_status: 0
            };
        },
        close:function() {
            this.modal.hide();
        },
        decide:function(sts = false) {
            this.form.affiliate_margin > 100 ? 0 : this.form.affiliate_margin;
            this.msgSignal = {
                title: "確認",
                message: "商品情報を保存します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    const isPost = isEmpty(this.itemId) || this.copy;
                    const url = "/seller/items" + (isPost ? "" : "/" + this.itemId);
                    const act = isPost ? Api.post : Api.put;

                    
                    ///送信前に情報を書き換え
                    this.form.bank_id = this.form.pay_mode[1];
                    this.form.pay_mode = this.form.pay_mode[0];

                    act(url, this.createPostForm(sts), (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.close();
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                                if(!this.copy) {
                                    this.canChanging = true;
                                }
                            });
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        },
        deleteData:function() {
            this.msgSignal = {
                title: "確認",
                message: "商品情報を削除します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    const url = "/seller/items/" + this.itemId;
                    Api.delete(url, (d) => {
                        if(d.ok) {
                            this.$emit("research");
                            this.close();
                            return;
                        }
                    })
                    this.$emit("loading", false);
                }
            };
        },
        createPostForm:function(sts = false) {
            if(sts) {
                this.form.identity_status = 1
            } else {
                if(this.form.identity_status != 3) {
                    this.form.identity_status = 0
                }
            }
            const form = new FormData();
            form.append("uuid","");
            if(this.copy) {
                form.append("org_id",this.itemId);
                this.form.identity_status = 3;
            }
            if(!this.form.item_image_f && this.form.item_image) {
                this.form.item_image_f = "copy.png";
            }
            if(!this.form.dl_file_name_f && this.form.dl_file_name) {
                this.form.dl_file_name_f = "copy.png";
            }
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        },
        changeImage:function(e) {
            this.form.item_image_f = e.target.files[0];
            const reader = new FileReader();
            reader.onload = () => {
                this.form.item_image = reader.result;
            };
            reader.readAsDataURL(this.form.item_image_f);
        },
        changeDLFile:function(e) {
            this.form.dl_file_name_f = e.target.files[0];
        },
        download:function() {
            this.$emit("loading", true);
            Api.getBlob("/seller/items/download/" + this.itemId).then(x => {
                saveAs(x,this.form.dl_file_name_org);
            }).finally(() => {
                this.$emit("loading", false);
            });
        }
    },
    mounted:function() {
        this.modal = new Modal(this.$el);
    }
}
</script>