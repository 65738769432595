<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.vue-table {
    padding: 30px 30px 0;
    .table-option {
        display: flex;
        justify-content: space-between;
        button {
            padding: 10px;
            border: none;
            background-color: $color-main-seller;
            color: #fff;
            &:hover {
                background-color: #8dd08d;
            }
        }
        form>div {
            width: 530px;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0 0 10px;
            font-size: 15px;
    
            .category-select {
                select {
                    height: 27px;
                    width: 160px;
                }
            }
            button {
                width: 160px;
                padding: 3px;
            }
        }
    }
    .item-total {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
        >div div {
            width: 120px;
            margin: 2px 1px;
            padding: 2px 0;
            border: 1px solid #000;
            text-align: center;
        }
        .total-text {
            font-weight: bold;
        }
        .total-reward {
            margin: 0 0 0 122px;
        }
    }
    .table-cell {
        border: 1px solid #000;
        padding: 2px;
        text-align: center;
        min-width: 4rem;
    }
    .item-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
@media screen and (max-width: 896px) {
    .pc_cont {
        display: none !important;
    }
    .vue-table {
        padding: 0;
        .table-option {
            display: initial;
            form>div {
                width: auto;
                justify-content: flex-start;
                button {
                    width: 50px;
                    padding: 3px;
                }
            }
            .csv_download {
                position: absolute;
                right: 15px;
                top: 55px;
            }
        }
        .item-total {
            >div div {
                width: 22vw;
            }
            .total-reward {
                margin: 0
            }
        }
    }
}
</style>
<template>
<div class="pageContent statistics">
    <div class="content no-login">
        <h1 class="page-title">統計情報</h1>
        <div class="vue-table">
            <div class="table-option">
                <form @submit.prevent="itemFind">
                    <div class="pc_cont">
                        <div>
                            <div>期間</div>
                            <input type="date" v-model="keyword.startDate">〜<input type="date" v-model="keyword.endDate">
                        </div>
                        <div class="category-select">
                            <div>カテゴリー</div>
                            <select type="text" v-model="keyword.category">
                                <option value="" selected></option>
                                <option v-for="(category, index) in categories" :key="index" :value="category.name">{{ category.name }}</option>
                            </select>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div class="pc_cont">商品名</div>
                            <input type="text" v-model="keyword.item">
                        </div>
                        <div class="pc_cont">
                            <div>ID検索</div>
                            <input type="text" v-model="keyword.id">
                        </div>
                        <div>
                            <button type="submit" class="btn btn-primary">検索</button>
                        </div>
                    </div>
                </form>
                <div class="csv_download">
                    <vue-json-to-csv :json-data="csvData" csv-title="統計情報" :labels="labels">
                        <button @click="downloadCSV">csvでダウンロード</button>
                    </vue-json-to-csv>
                </div>
            </div>
            <div class="item-total">
                <div>
                    <div class="total-text">表示数</div>
                    <div>{{ totalDidplayCounts }}</div>
                </div>
                <div>
                    <div class="total-text">購入数</div>
                    <div>{{ totalSalesQuantity }}</div>
                </div>
                <div class="pc_cont">
                    <div class="total-text">購入額</div>
                    <div>{{ price(totalBuyPrice) }}</div>
                </div>
                <div class="total-reward">
                    <div class="total-text">報酬額</div>
                    <div>{{ price(totalReward) }}</div>
                </div>
            </div>
            <vuetable 
                ref="vuetable"
                noDataTemplate = "統計情報がありません。"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                :data-manager="dataManager"
                :append-params="moreParams"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                class="table"
            >
                <div class="table-cell" slot="created_at" slot-scope="props">{{ createDay(props.rowData.created_at) }}</div>
                <div class="table-cell" slot="uuid" slot-scope="props">{{ props.rowData.uuid }}</div>
                <div class="table-cell item-name" slot="item_name" slot-scope="props">{{ props.rowData.item_name | omittedText }}</div>
                <div class="table-cell" slot="selling_price" slot-scope="props">{{ price(props.rowData.selling_price) }}</div>
                <div class="table-cell" slot="display_counts" slot-scope="props">{{ props.rowData.display_counts }}</div>
                <div class="table-cell" slot="sales_quantity" slot-scope="props">{{ props.rowData.sales_quantity }}</div>
                <div class="table-cell" slot="buy_price" slot-scope="props">{{ buyPrice(props.rowData) }}</div>
                <div class="table-cell" slot="price_ratio" slot-scope="props">{{ priceRatio(props.rowData) }}%</div>
                <div class="table-cell" slot="reward" slot-scope="props">{{ price(props.rowData.reward) }}</div>
            </vuetable>
            <div class="pagenation">
                <vuetable-pagination ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {Vuetable, VuetablePagination} from 'vuetable-2';
import VueJsonToCsv from "vue-json-to-csv";
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';
import {num2Yen} from "../../common/com-func";

export default {
    components: {
        VueJsonToCsv,
        Vuetable,
        VuetablePagination
    },
    data() {
        return {
            keyword: {
                startDate: "",
                endDate: "",
                id: "",
                item: "",
                category: "",
            },
            categories: {},
            fields: [
                {name: "created_at",title: '日付', sortField: 'created_at'},
                {name: "uuid",title: '商品ID', sortField: 'uuid'},
                {name: "item_name",title: '商品名'},
                {name: 'selling_price',title: '価格'},
                {name: 'display_counts',title: '表示数'},
                {name: 'sales_quantity',title: '購入数'},
                {name: 'buy_price',title: '購入額'},
                {name: 'price_ratio',title: '割合'},
                {name: 'reward',title: '報酬額'},
            ],
            moreParams: {
                sort: 'uuid',
                sort: 'created_at',
            },
            perPage: 30,
            data: [],
            totalDidplayCounts: 0,
            totalSalesQuantity: 0,
            totalBuyPrice: 0,
            csvTotalPrice: 0,
            totalReward: 0,
            csvData: [],
            labels: {
                created_at: { title: "日付" },
                uuid: { title: "商品ID" },
                item_name: { title: "商品名" },
                selling_price: { title: "価格" },
                display_counts: { title: "表示数" },
                sales_quantity: { title: "購入数" },
                buy_price: { title: "購入額" },
                price_ratio: { title: "割合" },
                reward: { title: "報酬額" },
            },
        }
    },
    watch: {
        data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
        }
    },
    created() {
        this.getItem();
        this.getCategories();
    },
    methods: {
        getItem() {
            axios.get("/item/self").then(response => {
            this.data = response.data;
            this.csvData = this.data;
            
            this.totalDidplayCounts = 0;
            this.totalSalesQuantity = 0;
            this.totalBuyPrice = 0;
            this.totalReward = 0;

            // 検索
            for(let i=0; i<this.data.length; i++ ) {
                const item_date = new Date(this.data[i].created_at);
                const startDate = new Date(this.keyword.startDate);
                const endDate = new Date(this.keyword.endDate);
                if(this.keyword.startDate != "" && this.data[i]) {
                    if(startDate - item_date >= 0) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.endDate != "" && this.data[i]) {
                    if(endDate - item_date < -86400000) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }

                if(this.keyword.item != "" && this.data[i]) {
                    if(!this.data[i].item_name.includes(this.keyword.item)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.id != "" && this.data[i]) {
                    if(!String(this.data[i].uuid).includes(this.keyword.id)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
                if(this.keyword.category != "" && this.data[i]) {
                    if(!this.data[i].category.name.includes(this.keyword.category)) {
                        this.data.splice(i, 1);
                        i = i-1;
                    }
                }
            }
            if(this.data.length == 0) {
                this.data = {};
            }

            for(let i=0; i<this.data.length; i++ ) { 
                this.data[i].reward = 0;
                this.data[i].sales_quantity = 0;
                if(this.data[i].order[0]) {
                    for(let ii=0; ii<this.data[i].order.length; ii++ ) {
                        this.data[i].reward += this.data[i].order[ii].earning_price;
                    }
                    this.data[i].sales_quantity = this.data[i].order.length;
                }

                this.totalDidplayCounts += this.data[i].display_counts;
                this.totalSalesQuantity += this.data[i].sales_quantity;
                this.totalBuyPrice += this.data[i].selling_price * this.data[i].sales_quantity;
                this.totalReward += this.data[i].reward;
            }

            for(let i=0; i<this.data.length; i++ ) {
                const csvData = this.csvData[i];
                csvData.created_at = dayjs(this.data[i].created_at).format('YYYY/MM/DD');
                csvData.buy_price = csvData.selling_price * csvData.sales_quantity;
                csvData.price_ratio = Math.round(csvData.selling_price * csvData.sales_quantity / this.totalBuyPrice * 1000)/10 + "%";
                csvData.reward = this.data[i].reward;
            }
            });
            
        },
        getCategories() {
            axios.get("/category/api").then(response => {
                this.categories = response.data;
            });
        },
        itemFind() {
            this.getItem();
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;
            let local = this.data;

            if (sortOrder.length > 0) {
                local = _.orderBy(
                local,
                sortOrder[0].sortField,
                sortOrder[0].direction
            );
        }

        pagination = this.$refs.vuetable.makePagination(
            local.length,
            this.perPage
            );
            let from = pagination.from - 1;
            let to = from + this.perPage;

            return {
                pagination: pagination,
                data: _.slice(local, from, to)
            };
        },
        getSortParam(sortOrder) {
            return sortOrder.map(function(sort) {
                return (sort.direction === 'desc' ? '-' : '') + sort.field
            }).join(',')
        },
        price:function(price) {
            return num2Yen(price);
        },
        createDay:function(date) {
            return dayjs(date).format('YYYY/MM/DD');
        },
        buyPrice:function(item) {
            return num2Yen(item.selling_price * item.sales_quantity);
        },
        priceRatio:function(item) {
            return Math.round(item.selling_price * item.sales_quantity / this.totalBuyPrice * 1000)/10;
        },
        downloadCSV () {
            this.csvData.push();
        }
    },
    filters: {
        omittedText(text) {
            // 10文字以降は...を表示する
            return text.length > 10 ? text.slice(0, 10) + "..." : text;
        },
    },

}
</script>