<style lang="scss" scoped>
</style>
<template>
  <div>
    <Search />
    <MainView />
  </div>
</template>
<script>
import Search from "./parts/search.vue";
import MainView from "./parts/mainView.vue";

export default {
  components: {
    Search: Search,
    MainView: MainView,
  },
};
</script>