<style lang="scss" scoped>
@import "../../../sass/variables";
h2 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3px;
    color: #565656;
    padding: 30px 45px 0;
    margin-bottom: 20px;
    &:before {
        content: "";
        position: relative;
        top: 1px;
        left: -14px;
        border-left: 4px solid;
        border-color: $color-main-free;
    }
}
.contents {
    padding: 0 30px;
    margin: 0 auto 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    .item {
        width: 24vw;
        padding: 20px;
        margin: 0 0 20px;
        background-color: #fff;
        border: 1px solid #cacaca;
        .item_image {
            max-width: 100%;
            height: 12vw;
            width: 22vw;
            object-fit: cover;
        }
        h3 {
            padding: 20px 0 12px;
            line-height: 1.3;
            letter-spacing: 1px;
            color: #333;
            font-size: 18px;
        }
        a {
            text-decoration: none;
        }
        .category {
            padding: 4px 18px;
            font-size: 10px;
            border: 1px solid #ccc;
            border-radius: 25px;
            color: #ababab;
        }
        p {
            height: 85px;
            font-size: 12px;
            line-height: 1.8;
            color: #ababab;
            letter-spacing: 1px;
            margin-top: 15px;
        }
        .info-block {
            display: flex;
            .author-block {
                margin-top: 12px;
                width: 75%;
                .authorImage {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 1px solid #ccc;
                    float: left;
                }
                .authorName {
                    font-size: 12px;
                    padding: 16px 10px;
                    display: block;
                    margin-left: 55px;
                    color: #ababab;
                }
            }
            .price-block {
                margin-top: 4px;
                padding: 12px 0px;
                width: 50%;
                text-align: center;
                .price {
                    background-color: $color-main-free;
                    border-color: $color-main-free;
                    color: #fff;
                    display: block;
                    width: 100px;
                    margin: 0 auto;
                    border-radius: 25px;
                    padding: 4px 0;
                    font-size: 13px;
                }
                .date {
                    font-size: 10px;
                    color: #ababab;
                    .affi-percent {
                        border-left: 1px dotted #ccc;
                        margin-left: 5px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 896px) {
    h2 {
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        padding: 20px 20px 5px;
        &:before {
            top: 0;
            left: -8px;
        }
    }
    .contents {
        padding: 0;
        margin: 0 auto 30px;
        .item {
            padding: 5px;
            width: 45vw;
            .item_image {
                height: 25vw;
                width: 42vw;
            }
            h3 {
                padding: 10px 0 0;
                font-size: 14px;
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            .categoryName {
                text-align: center;
            }
            p {
                height: 50px;
                font-size: 10px;
                line-height: 1.6;
                letter-spacing: normal;
                margin: 5px 0;
            }
            .info-block {
                display: block;
                .author-block {
                    margin: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    .authorImage {
                        height: 40px;
                        width: 40px;
                    }
                    .authorName {
                        margin: 0;
                        padding: 10px;
                    }
                }
                .price-block {
                    margin: 0;
                    padding: 5px 0;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    .price {
                        width: auto;
                        margin: 0;
                        padding: 1px 15px;
                    }
                    .date {
                        padding: 2px 0 0;
                    }
                }
            }
        }
    }
}
</style>

<template>
    <div>
        <Search :category_select="categoryNumber" :search_word="searchWord" />
        <h2>{{ category.name }}</h2>
        <div class="contents">
            <p v-show="search_items.length == 0">商品が見つかりませんでした</p>
            <div v-show="item.identity_status == 3" v-for="(item, index) in search_items" :key="index">
                <div class="item">
                    <router-link :to="`/item/${item.uuid}`"><img class="item_image" :src="item.item_image" alt="商品画像"></router-link>
                    <router-link :to="`/item/${item.uuid}`"><h3>{{ item.item_name }}</h3></router-link>
                    <div class="categoryName"><router-link :to="`/category/${category.id}`" class="category">{{ category.name }}</router-link></div>
                    <router-link :to="`/item/${item.uuid}`"><p>{{ item.detail | omittedText }}</p></router-link>
                    <div class="info-block">
                        <div class="author-block">
                            <router-link :to="`/profile/${item.member.id}`"><img class="authorImage" :src="`${item.member.image_icon}`"></router-link>
                            <router-link :to="`/profile/${item.member.id}`"><span class="authorName">{{ item.member.seller_name }}</span></router-link>
                        </div>
                        <div class="price-block">
                            <router-link :to="`/item/${item.uuid}`" class="price">¥{{ item.selling_price.toLocaleString() }}</router-link>
                            <span class="date">{{ item.updated_at }}
                                <span v-show="item.affiliatable == 1 && item.affiliate_margin" class="affi-percent">{{ item.affiliate_margin }}%</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Search from "./parts/search.vue";
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    components: {
        Search: Search,
    },
    props: {
        categoryId: Number,
    },
    data:function() {
        return {
            category: {},
            categoryNumber: {},
            searchWord: "",
        }
    },
    created() {
        this.getItems();
    },
    methods: {
        getItems() {
            axios.get("/category/api/" + this.categoryId).then(response => {
                this.category = response.data;

                for(let i=0; i<this.category.items.length; i++ ) {
                    this.category.items[i].updated_at = dayjs(this.category.items[i].updated_at).format('YYYY-MM-DD');
                }
            });
            this.categoryNumber = this.categoryId;
            if(this.$route.query.search){
                this.searchWord = this.$route.query.search;
            } else {
                this.searchWord = "";
            }
        },
    },
    computed: {
        search_items() {
            if(this.searchWord == "") {
                return this.category.items;
            } else {
                return Object.values(this.category.items).filter(item => {
                    return item.item_name.includes(this.searchWord) || item.detail.includes(this.searchWord)
                })
            }
        }
    },
    filters: {
        omittedText(text) {
            if (window.innerWidth >= 896) {
                // 80文字以降は...を表示する
                return text.length > 80 ? text.slice(0, 80) + "..." : text;
            } else {
                return text.length > 45 ? text.slice(0, 45) + "..." : text;
            }
        },
    },
    watch: {
        $route(to, from) {
            this.getItems(to.params.id);
        },
    },
}
</script>