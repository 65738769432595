var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loaded,
            expression: "loaded"
          }
        ],
        staticClass: "content no-login"
      },
      [
        _c("h1", { staticClass: "page-title" }, [_vm._v("注文完了")]),
        _vm._v(" "),
        _c("div", { staticClass: "reg" }, [
          _c("div", { staticClass: "regContent" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bankMode,
                        expression: "bankMode"
                      }
                    ],
                    staticClass: "bank"
                  },
                  [
                    _c("p", { staticClass: "red" }, [
                      _vm._v("まだ購入は完了していません。")
                    ]),
                    _vm._v(" "),
                    _vm._m(1)
                  ]
                ),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "あなた宛に確認メールが送信されています。ご確認ください。"
                  )
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("ご利用いただき誠にありがとうございます。")]),
                _vm._v(" "),
                _c("div", { staticClass: "credit" }, [
                  _vm.paytype == 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary main",
                          attrs: { type: "button" },
                          on: { click: _vm.download }
                        },
                        [_vm._v("ファイルダウンロード")]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.bankMode,
                        expression: "bankMode"
                      }
                    ],
                    staticClass: "textBox"
                  },
                  [
                    _c("h4", [_vm._v("振込先情報")]),
                    _vm._v(" "),
                    _c("ul", [
                      _c("li", [
                        _vm._v("金融機関名：" + _vm._s(_vm.order.bank_name))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("金融機関コード：" + _vm._s(_vm.order.bank_code))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("支店コード：" + _vm._s(_vm.order.branch_code))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("支店名：" + _vm._s(_vm.order.branch_name))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("口座番号：" + _vm._s(_vm.order.bank_no))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("預金種別：" + _vm._s(_vm.order.bank_type))
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("口座名義：" + _vm._s(_vm.order.bank_holder))
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h3", [_vm._v("ご注文が完了致しました。")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("本日から"),
      _c("span", { staticClass: "strong" }, [_vm._v("3日間以内")]),
      _vm._v("に振込完了してください。"),
      _c("br"),
      _vm._v(
        "\n                            振込が行われなかった場合、注文がキャンセルされます。"
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }