var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "pageContent" }, [
      _c("div", { staticClass: "content listing-login" }, [
        _c("h2", { staticClass: "h2Product page-title" }, [
          _vm._v("本人確認書類")
        ]),
        _vm._v(" "),
        _vm.origin.identity_status != 0
          ? _c("div", { staticClass: "identity-message" }, [
              _vm.origin.identity_status == 1
                ? _c("p", { staticClass: "identity-status" }, [
                    _vm._v("現在の状況：審査中")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.origin.identity_status == 2
                ? _c("div", [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _c("p", { staticClass: "message" }, [
                      _vm._v(_vm._s(_vm.origin.identity_message))
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.origin.identity_status == 3
                ? _c("p", { staticClass: "identity-status" }, [
                    _vm._v("現在の状況：審査完了")
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.origin.identity_status == 0 || _vm.origin.identity_status == 2
          ? _c("div", [
              _vm._m(2),
              _vm._v(" "),
              _c("div", [
                _c("form", { staticClass: "identification" }, [
                  _c("div", [
                    _c("label", { staticClass: "contentlabel" }, [
                      _vm._v("表面")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "identity_img1",
                      attrs: { type: "file" },
                      on: { change: _vm.uploadImage1 }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.identity_url1,
                            expression: "identity_url1"
                          }
                        ]
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.identity_url1,
                            alt: "本人確認書類(表)"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { staticClass: "contentlabel" }, [
                      _vm._v("裏面")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "identity_img2",
                      attrs: { type: "file" },
                      on: { change: _vm.uploadImage2 }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.identity_url2,
                            expression: "identity_url2"
                          }
                        ]
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.identity_url2,
                            alt: "本人確認書類(裏)"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("label", { staticClass: "contentlabel" }, [
                      _vm._v("保険証の裏・表、住民票")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      ref: "identity_img3",
                      attrs: { type: "file" },
                      on: { change: _vm.uploadImage3 }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.identity_url3,
                            expression: "identity_url3"
                          }
                        ]
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.identity_url3,
                            alt: "保険証の裏・表、住民票"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("※最大画像容量は5MB以内です")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { type: "button" },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("送付する")]
                  )
                ])
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "identity-status" }, [
      _vm._v("現在の状況："),
      _c("span", [_vm._v("再提出が必要です")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("再提出の理由"),
      _c("br"),
      _vm._v("------------------------------------------")
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mainContent" }, [
      _c("div", { staticClass: "content-box" }, [
        _c("p", { staticClass: "identity-text" }, [
          _vm._v("出品を行うには、本人確認書類の提出がかならず必要です。")
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "本人確認書類は、画像ファイルのアップロードでお送りいただきます。"
          ),
          _c("br"),
          _vm._v(
            "画像に不備があった場合は再度アップロードをお願いすることになりますので、お申し込みの前に下記内容をご確認ください。"
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "本人確認書類は、写真や記載内容がはっきりと読み取れる画像をお選びください。また全体が入るように撮影してください。"
          )
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v("ファイル形式:JPG"),
          _c("br"),
          _vm._v("\n                        ファイルサイズ:5MB以内")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v("※申し込みは日本国籍のみに限る")
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "h3title" }, [
          _vm._v("本人確認書類として利用できるもの")
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（1）運転免許証")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("1.顔写真、現住所の記載があるもの")]),
          _vm._v(" "),
          _c("li", [_vm._v("2.有効期限内のもの")]),
          _vm._v(" "),
          _c("li", [
            _vm._v("3.各都道府県公安委員会発行のもの（国際免許証は除く）")
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v("※免許証の場合、顔写真は必須になります。"),
          _c("br"),
          _vm._v(
            "\n                            　不鮮明もしくは顔の一部または全部が隠れて確認できない時は再度ご送付いただきます。"
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "※裏面をアップロードする際は、\n                            　必ず「臓器提供意思表示欄」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。\n                            　該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。"
          )
        ]),
        _vm._v(" "),
        _c("h4", [
          _vm._v(
            "（2）健康保険証または住民基本台帳カード ＋ 公共料金領収書・住民票のいずれか"
          )
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（3）日本国パスポート")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("1.現住所の記載があるもの")]),
          _vm._v(" "),
          _c("li", [_vm._v("2.有効期限内のもの")])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "※パスポートは必ず、「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。"
          ),
          _c("br"),
          _vm._v(
            "\n                            該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。"
          )
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（4）身体障害者手帳")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("1.現住所の記載があるもの")]),
          _vm._v(" "),
          _c("li", [_vm._v("2.有効期限内のもの")])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "※身体障害者手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。"
          ),
          _c("br"),
          _vm._v(
            "該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。"
          )
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（5）療育手帳")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("1.発行日の記載がある、または有効期限内のもの")]),
          _vm._v(" "),
          _c("li", [_vm._v("2.顔写真、生年月日、現住所の記載のあるもの")])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "※療育手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を\n                            　無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。"
          ),
          _c("br"),
          _vm._v(
            "該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。"
          )
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（6）精神障害者保健福祉手帳")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("1.発行日の記載がある、または有効期限内のもの")]),
          _vm._v(" "),
          _c("li", [_vm._v("2.顔写真、生年月日、現住所の記載のあるもの")])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "※精神障害者保健福祉手帳は必ず、「障がい名（障がいの種別）」「障がいの等級」「性別」を無地の紙等で覆い隠した（マスキング）状態でアップロードしてください。"
          ),
          _c("br"),
          _vm._v(
            "\n                            該当箇所が覆い隠されてない場合、該当書類は破棄し申し込みできません。"
          )
        ]),
        _vm._v(" "),
        _c("h4", [_vm._v("（7）マイナンバーカード（個人番号カード）")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v("1.マイナンバーカードの氏名・住所の記載面（カード表面)")
          ])
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "\n                            ※マイナンバー記載面（カード裏面）はご利用になれません。送付された場合は破棄させていただきます。"
          ),
          _c("br"),
          _vm._v(
            "\n                            ※カード表面に記載の「性別」「臓器提供意思」は無地の紙等で覆い隠した（マスキング）状態で送付ください。"
          ),
          _c("br"),
          _vm._v(
            "\n                            表示状態で送付された場合は破棄させていただきます。"
          ),
          _c("br"),
          _vm._v(
            "\n                            ※通知カードは本人確認書類としてご利用になれません。"
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }