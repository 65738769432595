<style lang="scss" scoped>
.content {
    padding: 0 1rem;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">パスワード再発行</h1>
        <div class="reg row">
            <div class="regMail regContent col-sm-6">
                <h2>アカウントに登録しているメールアドレスを入力してください。</h2>
                <div v-show="error" class="alert alert-danger" role="alert">再発行に失敗しました。メールアドレスを確認してください。</div>
                <form action="/password/email" method="post" class="regMailForm">
                    <input type="hidden" name="_token" v-model="token" />
                    <div class="mb-3">
                        <label class="form-label">メールアドレス</label>
                        <input class="form-control form-control-lg" type="text" name="email" v-model="mailaddress" required>
                    </div>
                    <input type="submit" value="再発行" required>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { USER_ARIES } from "../../store/modules/user-store";
import { token } from "../../common/fetch-wrapper";

export default {
    data:function() {
        return {
            mailaddress:"",
            error: false,
            token: token
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","email"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        }
    },
    mounted: function() {
        if(this.errors.length > 0) {
            this.error = true;
            this.removeError();
        }
        this.mailaddress = this.email;
        this.$forceUpdate();
    }
}
</script>