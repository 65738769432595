var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "pageContent" }, [
        _c("div", { staticClass: "content listing-login" }, [
          _c("h2", { staticClass: "h2Product page-title" }, [
            _vm._v("本人確認書類")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "infoArea" }, [
            _c("p", [
              _vm._v("送付が完了しました。"),
              _c("br"),
              _vm._v("審査終了まで、実営業日5日以内で審査が完了します。")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }