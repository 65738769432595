<style lang="scss" scoped>
.search-area {
    height: 39px;
    background-color: #C2C2C2;
    width: calc(100% + 24px);
    margin: 0 -12px;
    font-size: 12px;
    line-height: 22px;
    padding: 6px 62px;

    select {
        padding: 5px 10px;
        margin: 0 5px 0 0;
    }
    input, select {
        vertical-align: middle;
    }
    input[type=text] {
        margin-left: -5px;
        padding: 0;
        width: 250px;
    }
    .inputRap {
        display: inline-block;
        button {
            background-color: transparent;
            border: inherit;
            letter-spacing: 2px;
            font-size: 15px;
            padding: 0 5px;
            padding-right: 0;
            color: #565656;
            cursor: pointer;
            position: relative;
            top: 2px;
            img {
                padding: 0 0 2px;
            }
        }
    }
    .formText {
        border: 1px solid #707070;
        padding: 6px 25px;
        border-radius: 20px;
        letter-spacing: 1px;
        color: #565656;
        margin-left: 15px;
        position: relative;
        top: 2px;
        text-decoration: none;
    }
}
@media screen and (min-width: 896px) {
    .sp_cont {display: none;}
}
@media screen and (max-width: 896px) {
    .pc_cont {display: none;}
    .search-area {
        height: 26px;
        padding: 0;
        width: 100vw;
        form {
            position: fixed;
            z-index: 10;
            background-color: #C2C2C2;
            width: 100vw;
            select {
                margin: 0;
                padding: 5px 0;
                width: 160px;
            }
            input {
                margin: 0;
                width: calc(100vw - 189px) !important;
            }
        }
        .inputRap button {
            padding: 0 2px;
        }
    }
}
</style>

<template>
    <div class="search-area">
        <form v-on:submit.prevent="submit">
            <select v-model="categorySelectId">
                <option value="0">全てのカテゴリー</option>
                <option :value="category.id" v-show="category.items[0]" v-for="(category, index) in categories" :key="index">{{ category.name }}</option>
            </select>
            <input type="text" v-model="searchWord" value="">
            <div class="inputRap">
                <button class="pc_cont" type="submit">検索<img src="/img/search_icon.png" alt="検索アイコン"></button>
                <button class="sp_cont" type="submit"><img src="/img/search_icon.png" alt="検索アイコン"></button>
            </div>
            <!-- <a href="#" class="formText">条件を指定して検索</a> -->
        </form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        category_select: Number,
        search_word: String,
    },
    data:function() {
        return {
            categories: {},
            categorySelectId: 0,
            searchWord: "",
        }
    },
    created() {
        this.getCategories();
    },
    methods: {
        getCategories() {
            axios.get("/category/api").then(response => {
                this.categories = response.data;
            });
            if(this.category_select) {
                this.categorySelectId = this.category_select;
            }
            this.searchWord = this.search_word;
        },
        submit() {
            // カテゴリーが選択されてるか
            if(this.categorySelectId != 0) {
                this.$router.push({path: `/category/${this.categorySelectId}`, query: {search: this.searchWord}});
            } else if(this.categorySelectId == 0) {
                this.$router.push({path: "/category/all", query: {search: this.searchWord}});
            }
        }
    },
}
</script>