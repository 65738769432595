<style lang="scss" scoped>

</style>
<template>
<div class="position-fixed top-50 start-50">
    <div class="toast align-items-center text-white bg-primary border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body" v-html="message"></div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" @click="close"></button>
        </div>
    </div>
</div>

</template>
<script>
import Toast from "bootstrap/js/src/toast";

export default {
    props:["signal"],
    data: function() {
        return {
            toast: {},
            message: ""
        }
    },
    watch: {
        signal:function(nv) {
            this.message = nv.message;
            this.toast.show();
        }
    },
    methods: {
        close:function() {
            this.toast.hide();
        }
    },
    mounted:function() {
        this.toast = new Toast(this.$el.querySelector(".toast"));
    }
}
</script>