var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item-row row" }, [
    _c("div", { staticClass: "col-sm-3" }, [
      _c("img", {
        staticClass: "item-thumb",
        attrs: { src: _vm.item.item_image }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-7" }, [
      _c("div", [
        _c("span", { staticClass: "publish-day" }, [
          _vm._v("注文日：" + _vm._s(_vm.orderDate))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "publish-day" }, [
          _vm._v("購入NO：" + _vm._s(_vm.item.order_id))
        ])
      ]),
      _vm._v(" "),
      _c("div", [
        _c("span", { staticClass: "publish-day" }, [
          _vm._v("支払方法：" + _vm._s(_vm.payment))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "publish-day" }, [
          _vm._v("注文状況：" + _vm._s(_vm.status))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.item.item_name))]),
      _vm._v(" "),
      _c("div", { staticClass: "seller" }, [
        _c("img", {
          attrs: { src: _vm.seller_img },
          on: {
            click: function($event) {
              return _vm.profile(_vm.owner_id)
            }
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "user",
            on: {
              click: function($event) {
                return _vm.profile(_vm.owner_id)
              }
            }
          },
          [_vm._v(_vm._s(_vm.seller_name))]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "money" }, [
        _c("span", { staticClass: "pay-type" }, [
          _vm._v(_vm._s(_vm.payTypeName))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "price" }, [_vm._v(_vm._s(_vm.price))])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-2" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary main",
          attrs: { type: "button", disabled: !_vm.canDL },
          on: { click: _vm.download }
        },
        [_vm._v("ダウンロード")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary main download",
          attrs: { type: "button", disabled: !_vm.canDL },
          on: { click: _vm.detailDownload }
        },
        [_vm._v("領収書をダウンロード")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }