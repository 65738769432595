<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-transfer 4px solid;
    }
}
.content {
    padding: 0 1rem;
}
.btn-primary.main {
    background-color: $color-main-seller;
    border-color: $color-main-seller;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">支払い情報ー受取</h1>
        <div class="reg">
            <div class="regMail regContent row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">銀行コード</label>
                        <input class="form-control" type="text" v-model="form.bank_cd">
                        <error-text names="bank_cd" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">支店コード</label>
                        <input type="text" class="form-control" v-model="form.branch_cd">
                        <error-text names="branch_cd" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">口座番号</label>
                        <input class="form-control" type="text" v-model="form.account_no">
                        <error-text names="account_no" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">口座名義</label>
                        <input class="form-control" type="text" v-model="form.account_name">
                        <error-text names="account_name" :errmsg="errMsgs"></error-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main" @click="submitRegist">保存</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <br><br>
                        売上の支払いサイクルは、月末締め、翌々月10〜15日（土日と重なる場合は先送り）支払いとなります。<br>
                        売上報酬5000円以上でお支払いとなります。
                    </div>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { isEmpty, n2e } from '../../common/com-func';
import Api,{token} from "../../common/fetch-wrapper";
import ErrorText from "../../components/error-text";
import {USER_ARIES} from "../../store/modules/user-store";
import MsgModal from "../../components/msg-modal";
import MsgToast from "../../components/msg-toast";

export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    data:function() {
        return {
            error: false,
            token : token,
            form: {
                member_type:"2",
                bank_cd:"",
                branch_cd:"",
                account_no:"",
                account_name:""
            },
            errMsgs: {},
            signal: {},
            toastMsg: {}
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","origin"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        submitRegist:function(e) {
            this.signal = {
                title: "確認",
                message: "支払い情報を保存します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    Api.post("/transfer/pay", this.createForm(), (d) => {
                        if(d.ok) {
                            this.toastMsg = {message:"保存が完了しました。"};
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        },
        createForm:function() {
            const form = new FormData();
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        }
    },
    mounted:function() {
        // 初期表示
        this.$emit("loading", true);
        Api.get("/transfer/pay/" + this.form.member_type).then(x => {
            if(x) {
                Object.keys(this.form).forEach(k => {
                    if(x[k]) {
                        this.form[k] = n2e(x[k]);
                    }
                });
            }
        }).finally(() => {
            this.$emit("loading", false);
        });
    }
}
</script>