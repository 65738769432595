<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.content {
    padding: 0 1rem;
    
    .image-area {
        margin-bottom: 1rem;
        img {
            width: 100%;
        }
    }
    .seller {
        img {
            width: 75px;
            height: 75px;
            border-radius: 50%;
            border: 1px solid #ccc;
            float: left;
        }
        .user {
            font-size: 1.2rem;
            padding: 22px 10px;
            display: block;
            margin-left: 80px;
            color: $gray-font;
        }
    }
    .business {
        margin: 1rem 0;
        text-align: left;
    }
    .detail {
        border: 1px solid $border-color;
        padding: 0.5rem;
        white-space:pre-wrap;
        min-height: 8rem;
    }
    .row.item-area {
        margin: 1rem 0;
        .cards {
            padding: 1px;

            .item-cell {
                cursor: pointer;
                border: 1px solid $border-color;
                padding: 0.5rem;
                background-color: #fff;
                .category{
                    padding: 4px 18px;
                    font-size: 10px;
                    border: 1px solid $border-color;
                    border-radius: 25px;
                    color: $gray-font;
                    display: inline-block;
                    margin-bottom: 5px;
                }
                .footer {
                    display:flow-root;
                }
                .publish {
                    font-size: 15px;
                    letter-spacing: 1px;
                    color: $gray-font;
                    float: left;
                    margin-top: 3px;
                }
                .money {
                    background-color: $color-main-free;
                    border-color: $color-main-free;
                    text-align: center;
                    color: #fff;
                    display: block;
                    width: 100px;
                    margin: 0 auto;
                    border-radius: 25px;
                    padding: 4px 0;
                    font-size: 13px;
                    float: right;
                }
            }
        } 
    }
    
}

.btn-primary.main {
    background-color: $color-main-seller;
    border-color: $color-main-seller;
}
@media screen and (max-width: 896px) {
    .content {
        .seller {
            img {
                float: none;
            }
            .user {
                font-size: 14px;
                padding: 15px 5px;
                margin: 0;
            }
        }
        .business {
            width: 75vw;
        }
        .detail {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">出品者情報</h1>
        <div class="reg" v-show="loaded">
            <div class="regMail regContent">
                <div class="row">
                    <div class="col-12">
                        <div class="image-area">
                            <img :src="mem.image_header" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <div class="seller">
                            <img :src="mem.image_icon">
                            <span class="user">{{mem.seller_name}}</span>
                        </div>
                        <div class="business">
                            <div class="name">事業者名：{{mem.business_name}}</div>
                            <div class="name">メールアドレス：{{mem.publish_email}}</div>
                            <div class="name">連絡先：{{mem.business_contact}}</div>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="detail">{{mem.biography}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row item-area">
            <div v-for="i in items" :key="i.id" class="col-sm-3 cards">
                <div class="item-cell" @click="move(i)">
                    <div class="category">{{categoryName(i)}}</div>
                    <div class="image-area">
                        <img :src="i.item_image">
                    </div>
                    <div class="i-name">{{i.item_name}}</div>
                    <div class="footer">
                        <div class="publish">{{ pubDay(i) }}</div>
                        <div class="money">{{price(i)}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import { CACHE_ARIES } from "../../store/modules/cache-store";
import Api from "../../common/fetch-wrapper";
import { n2e,num2Yen,isEmpty } from "../../common/com-func";

export default {
    data:function() {
        return {
            loaded :false,
            mem: {
                seller_name : "", 
                image_icon : "", 
                image_header : "", 
                biography : "", 
                publish_email : "", 
                business_name : "", 
                business_contact : ""
            },
            items:[]
        }
    },
    computed: {
        ...mapGetters(CACHE_ARIES,["categoryMap"]),
    },
    methods: {
        ...mapActions(CACHE_ARIES,["loadCategories"]),
        editData:function(x) {
            Object.keys(this.mem).forEach(k => {
                this.mem[k] = n2e(x[k]);
            });
            this.items = !!x.items[0] ? x.items : [];
        },
        categoryName:function(item) {
            if(!isEmpty(item.category_id) && this.categoryMap[item.category_id]) {
                return this.categoryMap[item.category_id];
            }
            return "";
        },
        price:function(item) {
            return num2Yen(item.selling_price);
        },
        pubDay:function(item) {
            return dayjs(item.updated_at).format("YYYY-MM-DD");
        },
        move:function(item) {
            this.$router.push({path: "/item/" + item.uuid});
        }
    },
    mounted:function() {
        this.$emit("loading", true);
        Promise.all([
            Api.get("/profile/api/" + this.$route.params.id),
            this.loadCategories()
        ]).then(x => {
            this.editData(x[0]);
            this.loaded = true;
        }).finally(() => {
            this.$emit("loading", false);
        });
    }
    
}
</script>