<style lang="scss" scoped>
.news-area{
    background-color: #222226;
    color: #DCDCDC;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    padding: 0 0 0 30px;
    ul {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }
    li {
        display: flex;
    }
    .news-block {
        padding: 10px 0;
    }
    .news-cat {
        border-right: 1px dotted #707070;
        padding: 0px 25px;
    }
    .news-date {
        font-size: 10px;
        padding: 0 10px;
    }
    .news-date, .news-title {
        a {
            color: #fff;
            text-decoration: none;
        }
    }
    .login-menu {
        span {
            padding: 10px 15px;
            display: block;
            color: #fff;
            letter-spacing: 2px;
        }
        .register span{
            cursor: pointer;
            background-color: #00CCDB;
        }
        .login span{
            cursor: pointer;
            background-color: #00BADB;
        }
    }
}
.search-area{
    color: #DCDCDC;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    background-color: #C2C2C2;
    padding: 10px 30px;
    input[type=text] {
        margin-left: -5px;
        padding: 5px;
        width: 250px;
    }
    select {
        padding: 5px 10px;
    }
    input[type=submit] {
        background-color: transparent;
        border: inherit;
        letter-spacing: 2px;
        font-size: 15px;
        padding: 0 5px;
        padding-right: 0;
        color: #565656;
        cursor: pointer;
        position: relative;
        top: -1px;
    }
    .inputRap{
        display: inline-block;
        &:after{
            font-family: "Font Awesome 5 Free";
            content: "\f002";
            color: #565656;
            padding-left: 5px;
            position: relative;
            top: 2px;
        }
    }
    .fa-search{
        color: #565656;
        font-size: 14px;
        position: relative;
        top: 3px;
        left: 3px;
    }
    a.formText {
        border: 1px solid #707070;
        padding: 6px 25px;
        border-radius: 20px;
        letter-spacing: 1px;
        color: #565656;
        margin-left: 15px;
        position: relative;
        top: 2px;
    }
}
@media (max-width: 1260px){
    .news-area{
        padding: 0;
        .news-cat {
            display: none;
        }
        ul {
            display: block;
        }
        li.menu{
            display: flex;
        }
        .news-date{
            padding: 0 10px 0 45px;
        }
        .login{
            width: 50%;
            text-align: center;
        }
        .news-block {
            padding: 20px 0;
        }
    }
    .search-area {
        padding: 0;
        form {
            padding: 10px;
            select {
                width: 100%;
            }
        }
        input[type=text] {
            margin: 0;
            margin-top: 5px;
            margin-bottom: 12px;
            width: -webkit-fill-available;
        }
        input[type=submit] {
            padding: 0px 0px 0 5px;
        }
        a.formText{
            position: inherit;
            display: inline-block;
            width: calc(100% - 130px);
            text-align: center;
        }
    }
}
.logined-page-head {
    width: 100%;
    padding: 0 0 0 45px;
    background-color: #d0dfe2;
    .authorImage {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #ccc;
        margin-right: 15px;
        background-color: #fff;
    }
    .authorName {
        font-size: 12px;
        padding: 10px 0;
        display: inline-block;
        color: #fff;
    }
    .link {
        float: right;
        font-size: 11px;
        display: inline-block;
        padding: 10.5px 10px;
        color: #fff;
        background-color: #565656;
        cursor: pointer;
        &.out {
            background-color: #444;
        }
    }
}
@media screen and (max-width: 896px) {
    .pc_cont {display: none !important;}
        .news-area {
            position: fixed;
            z-index: 10;
            ul {
                padding: 0;
                .news-block {
                    padding: 5px 0;
                    .news-date {
                        padding: 5px 10px 0;
                    }
                    .news-title {
                        a {
                            display: inline-block;
                            width: 60vw;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            transform: translateY(4px);
                        }
                    }
                }
                .login-menu {
                    span {
                        width: 50vw;
                        padding: 5px 0;
                        text-align: center;
                    }
                }
            }
        }
        .no-login-margin {
            height: 70px;
        }
        .logined-page-head {
            position: fixed;
            z-index: 10;
        }
        .login-margin {
            height: 39px;
        }
}
</style>
<template>
<div class="inner-header">
    <!-- 認証前 -->
    <div class="news-area" v-show="!logined">
        <ul>
            <li class="news-block">
                <div class="news-cat">お知らせ</div>
                <div class="news-date"><router-link :to="`/news/${news.id}`">{{ news.start_date }}</router-link></div>
                <div class="news-title"><router-link :to="`/news/${news.id}`">{{ news.name }}</router-link></div>
            </li>
            <li class="login-menu" >
                <div class="register"><span @click="move('/register')">新規会員登録</span></div>
                <div class="login"><span @click="move('/login')">ログイン</span></div>
            </li>
        </ul>
    </div>
    <div class="no-login-margin" v-show="!logined"></div>
    <div class="logined-page-head" v-if="logined">
        <img v-show="userIcon" class="authorImage" :src="`${userIcon}`">
        <span class="authorName">{{userName}}</span>
        <span class="link out pc_cont" @click="logout">ログアウト</span>
        <span class="link pc_cont" @click="move('/')">トップページへ戻る</span>
    </div>
    <div class="login-margin" v-if="logined"></div>
    <!-- 検索用ヘッダ -->
    <div class="search-area" v-show="searchDisp">
        <form>
            <select>
                <option>全てのカテゴリー</option>
            </select>
            <input type="text" name="searchWord" value="">
            <div class="inputRap"><input class="searchSubmitButton" type="submit" value="検索"></div>
            <a href="#" class="formText">条件を指定して検索</a>
        </form>
    </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import {n2e} from "../common/com-func";
import { USER_ARIES } from "../store/modules/user-store";
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    data:function() {
        return {
            searchDisp : false,
            news: {},
        };
    },
    computed: {
        ...mapGetters(USER_ARIES,["logined","userName","userIcon"]),
    },
    methods: {
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
            }
        },
        logout:function() {
            const result = confirm("ログアウトしますか？");
            if(result) {
                location.href = "/member/logout";
            }
        },
        searchDisplay:function() {
            const path = n2e(this.$router.currentRoute.path);
            console.log("xxx" + this.$router.currentRoute.path);
            return ["/login", "/register"].indexOf(path) < 0;
        },
        getNews() {
            axios.get("/news/first").then(response => {
            this.news = response.data;
            
            this.news.start_date = dayjs(this.news.start_date).format('YYYY-MM-DD');
            });
        }
    },
    mounted:function() {
        this.getNews();
        // this.searchDisp = this.searchDisplay();
    }
}
</script>