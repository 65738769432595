var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        tabindex: "-1",
        "aria-hidden": "true",
        "data-bs-backdrop": "static"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("div", { staticClass: "header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("商品詳細（状況：" + _vm._s(_vm.statusName) + "）")
                ]),
                _vm._v(" "),
                _c("button", {
                  staticClass: "btn-close",
                  attrs: { type: "button" },
                  on: { click: _vm.close }
                })
              ]),
              _vm._v(" "),
              _vm.form.identity_status == 2
                ? _c("div", { staticClass: "reject-message" }, [
                    _c("p", [_vm._v("現在この商品は、否認されています。")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "アカウント登録時のメールアドレスに詳細が記載されていますのでご確認ください。"
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("商品名")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.item_name,
                            expression: "form.item_name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", disabled: !_vm.canPublish },
                        domProps: { value: _vm.form.item_name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "item_name", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "item_name", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("商品画像")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "file",
                          accept: "image/png, image/jpeg",
                          disabled: !_vm.canPublish
                        },
                        on: { change: _vm.changeImage }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("推奨画像サイズ:1200px × 300px 画像容量:2mb以下")
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "item_image_f", errmsg: _vm.errMsgs }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "item_image", errmsg: _vm.errMsgs }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.form.item_image != "",
                              expression: "form.item_image != ''"
                            }
                          ],
                          staticClass: "img-area"
                        },
                        [_c("img", { attrs: { src: _vm.form.item_image } })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("商品説明")
                      ]),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.detail,
                            expression: "form.detail"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { rows: "10", disabled: !_vm.canPublish },
                        domProps: { value: _vm.form.detail },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "detail", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "detail", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("DL対象ファイル")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: { type: "file", disabled: !_vm.canPublish },
                        on: { change: _vm.changeDLFile }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "dl_file_name_f", errmsg: _vm.errMsgs }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "dl_file_name", errmsg: _vm.errMsgs }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.downloadable,
                              expression: "downloadable"
                            }
                          ],
                          staticClass: "dl-area"
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "ファイル名:" + _vm._s(_vm.form.dl_file_name_org)
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "button" },
                              on: { click: _vm.download }
                            },
                            [_vm._v("ダウンロード")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("価格")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.selling_price,
                            expression: "form.selling_price"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "number",
                          disabled: !_vm.canPublish && !_vm.copy
                        },
                        domProps: { value: _vm.form.selling_price },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "selling_price",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "selling_price", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("カテゴリー選択")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.category_id,
                              expression: "form.category_id"
                            }
                          ],
                          staticClass: "form-select",
                          attrs: { disabled: !_vm.canPublish },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "category_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [_vm._v("-")]),
                          _vm._v(" "),
                          _vm._l(_vm.categories, function(c) {
                            return _c(
                              "option",
                              { key: c.id, domProps: { value: c.id } },
                              [_vm._v(_vm._s(c.name))]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "category_id", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("支払い方式")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.payment_id,
                              expression: "form.payment_id"
                            }
                          ],
                          staticClass: "form-select",
                          attrs: { disabled: !_vm.canPublish },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "payment_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.payments, function(c) {
                          return _c(
                            "option",
                            { key: c.id, domProps: { value: c.id } },
                            [_vm._v(_vm._s(c.name))]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "payment_id", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("決済設定")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.banks, function(c) {
                        return _c(
                          "div",
                          { key: c.id, staticClass: "form-check" },
                          [
                            _c("label", { staticClass: "form-check-label" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.pay_mode,
                                    expression: "form.pay_mode"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "radio" },
                                domProps: {
                                  value: [0, c.id],
                                  checked: _vm._q(_vm.form.pay_mode, [0, c.id])
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.$set(_vm.form, "pay_mode", [
                                      0,
                                      c.id
                                    ])
                                  }
                                }
                              }),
                              _vm._v(
                                "銀行振込(" +
                                  _vm._s(c.bank_holder) +
                                  ")\n                                "
                              )
                            ])
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.pay_mode,
                                expression: "form.pay_mode"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio" },
                            domProps: {
                              value: [1, null],
                              checked: _vm._q(_vm.form.pay_mode, [1, null])
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "pay_mode", [1, null])
                              }
                            }
                          }),
                          _vm._v(" クレジット")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "display_status", errmsg: _vm.errMsgs }
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("公開設定")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.display_status,
                                expression: "form.display_status"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio", value: "0" },
                            domProps: {
                              checked: _vm._q(_vm.form.display_status, "0")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "display_status", "0")
                              }
                            }
                          }),
                          _vm._v(" 公開")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.display_status,
                                expression: "form.display_status"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio", value: "1" },
                            domProps: {
                              checked: _vm._q(_vm.form.display_status, "1")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "display_status", "1")
                              }
                            }
                          }),
                          _vm._v(" 限定公開")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.display_status,
                                expression: "form.display_status"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio", value: "2" },
                            domProps: {
                              checked: _vm._q(_vm.form.display_status, "2")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "display_status", "2")
                              }
                            }
                          }),
                          _vm._v(" 非公開")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "display_status", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("アフィリエイト機能")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.affiliatable,
                                expression: "form.affiliatable"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio", value: "0" },
                            domProps: {
                              checked: _vm._q(_vm.form.affiliatable, "0")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "affiliatable", "0")
                              }
                            }
                          }),
                          _vm._v(" OFF")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("label", { staticClass: "form-check-label" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.affiliatable,
                                expression: "form.affiliatable"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "radio", value: "1" },
                            domProps: {
                              checked: _vm._q(_vm.form.affiliatable, "1")
                            },
                            on: {
                              change: function($event) {
                                return _vm.$set(_vm.form, "affiliatable", "1")
                              }
                            }
                          }),
                          _vm._v(" ON")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: { names: "affiliatable", errmsg: _vm.errMsgs }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", { staticClass: "form-label" }, [
                        _vm._v("アフィリエイトマージン")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "affiliate-margin col-sm-6" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.affiliate_margin,
                              expression: "form.affiliate_margin"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            disabled:
                              _vm.form.affiliatable == "0" ||
                              (!_vm.canPublish && !_vm.copy)
                          },
                          domProps: { value: _vm.form.affiliate_margin },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.form,
                                "affiliate_margin",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("%")])
                      ]),
                      _vm._v(" "),
                      _c("error-text", {
                        attrs: {
                          names: "affiliate_margin",
                          errmsg: _vm.errMsgs
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.close }
                },
                [_vm._v("閉じる")]
              ),
              _vm._v(" "),
              _vm.canChange && !_vm.copy
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.decide(false)
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.copy
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.decide(false)
                        }
                      }
                    },
                    [_vm._v("コピー")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canPublish
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.decide(true)
                        }
                      }
                    },
                    [_vm._v("申請")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canDelete
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                      on: { click: _vm.deleteData }
                    },
                    [_vm._v("削除")]
                  )
                : _vm._e()
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.msgSignal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }