<style lang="scss" scoped>
.scroll.high {
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    h4{
        font-size: 14px;
        margin-bottom: 10px;
    }
    ul {
        padding-left: 22px;
        font-size: 13px;
        list-style: disc;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 896px) {
    .scroll.high {
        h3 {
            font-size: 14px;
        }
        p {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="scroll high">
    <h3>第1条（本規約について）</h3>
    <p>パートナー利用規約（以下「本規約」といいます。）は、株式会社ソリッドアライズ（以下「当社」といいます。）が第2条１項で規定するパートナーに対して提供する、Digitab販売システム・アフィリエイトシステム・メールシステムおよびそれらに関連したサポートサービス（以下総称して「本サービス」といいます。）の利用について定めます。</p>
    <p>第2条で定義されるパートナーは、本規約の内容及び会員利用規約に同意して、本サービスを利用するものとします。</p>
    <p>本規約は、本サービスとの利用に関して当社とパートナーとの間に発生する一切の関係に適用します。</p>


    <h3>第2条（定義）</h3>
    <p>本規約に定める語句および定義は以下のとおりとします。</p>
    <ul>
        <li>「利用者」とは、本サービスを閲覧・利用する全ての個人・法人を指します。</li>
        <li>「会員」とは、本規約に同意して登録を申し込み、出品者（3項）・パートナー（4項）を含むアカウント（5項）を開設した者をいいます。</li>
        <li>「出品者」とは、当社との間で本サービスに関して、別に定める出品者利用規約に同意した会員のうち、本サービスを利用し、商品やサービスの販売等、電子商取引を行う会員をいいます。</li>
        <li>「パートナー」とは、本サービスに関する利用規約に同意して登録を申し込み、当社の承諾によりアフィリエイトシステム（6項）においてアフィリエイターとして登録された会員を指します。</li>
        <li>「アカウント」とは、本サービスにおいて、別に定めるパートナー利用規約に同意した会員で管理画面など本サービスの特定領域を利用するために、会員・出品者・パートナー会員に対して発行される資格のことをいいます。</li>
        <li>「アフィリエイトプログラム」とは、パートナーがウェブサイトその他の媒体からそれを訪れる訪問者に出品者の運営・管理するサイトへ訪れるように誘導し、訪問者に商品・サービスを購入させることとし、訪問者を紹介された出品者はパートナーに対し、当該成果に応じて各プログラムに規定された成功報酬を支払う仕組みをいいます。</li>
    </ul>
    <p>本規約は、当社が運営する、本サービスのパートナー用の利用条件を定めるものです。</p>


    <h3>第3条（本規約の改定）</h3>
    <p>当社は、本規約を任意に改定することができるものとし、会員はこれを承諾するものとします。</p>
    <p>改定後の規約については、当社が別途定める場合を除き、当社ウェブサイトに提示した時点より効力を生じるものとします。</p>


    <h3>第4条（規約の適用範囲）</h3>
    <p>本規約は、本サービスの利用に関して当社とパートナーとの間における関係性全てに適用します。<br>
    パートナーは、パートナー利用規約に同意した時点をもって本規約および当社が別途定める会員利用規約に同意したものとみなします。</p>


    <h3>第5条（サービスの内容）</h3>
    <p>当社は利用者に決済サービス、アフィリエイトシステムおよび各種インターネットサービスを提供するものとします。</p>
    <p>本サービスは、出品者が商品等を販売し、会員がそれを購入し、パートナーが当該商品等を紹介することで、商品等の販売を発展させていくものです。</p>
    <p>パートナーは、紹介により商品等の販売がされると報酬が支払われます。</p>
    <p>本サービスにおいて、出品者には利用料金が発生しますが、会員及びパートナーには利用料金は発生しません。</p>
    <p>当社は、本サービスにおいて、出品者、会員及びパートナーのマッチングを図ることを行うものであり、商品等の売買に関する売主、買主、紹介に関する委託者、受託者の地位に立つものではありません。また販売の促進について確約するものではありません。</p>
    <p>出品者は、当社に対する本サービスの利用料金及びパートナーへの報酬の支払いを行うために、会員が出品者に支払う代金を受領する権限を、当社に授与するものとします。</p>
    <p>パートナーは、出品者のパートナーへの報酬の支払いを行うために、会員が出品者に支払う代金を受領する権限を、当社に授与するものとします。</p>


    <h3>第6条（パートナー登録）</h3>
    <p>本サービスを会員として利用したい方は、本規約の内容を確認後、会員登録の申し込みを行なってください。なお、お電話・FAXによる会員登録申込みは原則として受け付けておりません。</p>
    <p>会員登録までの手順は、利用規約と同条件・同作業を行いその後パートナー利用規約に同意後、本人確認書類を提出し当社が当社規定に従って審査のうえで承諾し，当社からの本登録完了の表示をもって、パートナー登録は完了します。</p>
    <p>以下の各号を全て満たす場合でなければ、パートナー登録はできません。</p>
    <ul>
        <li>ねずみ講等の違法性を有する取引に関わっていないこと。</li>
        <li>反社会的勢力としての活動、交流、資本関係ないし資金の提供をしていないこと。</li>
        <li>社会的勢力を役員、従業員として雇用していないこと。</li>
        <li>登録申込時に虚偽の情報を入力しないこと。</li>
        <li>登録申込時に他者の情報を入力しないこと。</li>
        <li>digi-tab.netドメインからのメールを受信できない状態で登録申込を行なうこと。</li>
        <li>本規約を読み、遵守することを承認していること。</li>
        <li>過去に本サービスの会員登録を解除されていないこと。</li>
        <li>その他当社が登録を不適当と認める者でないこと。</li>
    </ul>


    <h3>第6条（登録情報の変更）</h3>
    <p>パートナーは自らの登録情報に変更が生じた場合には、速やかに管理画面の出品者情報を変更・修正するものとします。</p>
    <p>当社は、パートナーが第1項に定める変更登録を行なわなかったことにより生じた損害について、その一切の責任を負わないものとします。</p>
    <p>また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は変更登録前の情報に基づいて行われます。このような取引について登録情報の変更内容を出品者・会員に通知する必要がある場合には、パートナーより出品者・会員に直接ご連絡下さい。</p>


    <h3>第7条（権利の譲渡の禁止）</h3>
    <p>会員のログイン情報に関しては、第三者に貸し出しまたは売却、譲渡することはできません。ログイン情報の管理は、会員が責任を負うものとし、ログイン情報を使用して行われた全ての行為は、理由の如何を問わずそのログイン情報を登録した会員が全ての責任を負います。</p>


    <h3>第8条（サービス利用料 および銀行振込手数料）</h3>
    <p>決済サービス利用料は、出品者が負担するものとし、出品者が販売を行う商品およびサービスが会員に販売されるごとに手数料が発生しますが、パートナーに利用料・手数料などの費用は発生しません。</p>
    <p>また出品者の判断によりキャンセル・返品・返金が行われたとしても当社への手数料・パートナーへの報酬に関して出品者に返金することはなく出品者が負担するものとします。</p>


    <h3>第9条（会員の退会）</h3>
    <p>会員が退会を希望する場合には、当社に対し会員本人が会員情報の削除依頼を行ってください。所定の退会手続の終了後に、退会となります。なお、会員が5,000円未満の未払の譲渡対価が発生している状態で退会申請をされた場合、 当月末締め翌々月10～15日に登録指定口座に振込手数料を控除した金額を振り込むものとします。</p>
    <p>会員を退会した場合、当該会員がそれまでに購入した商品およびサービスを再入手することはできません。また購入・出品・パートナーに属するすべての情報を閲覧することができなくなります。</p>


    <h3>第10条（個人情報の取扱い）</h3>
    <p>当社は本サービスの運営につき会員の個人情報を取得した場合、当社の定めるプライバシーポリシーに基づいて個人情報を取り扱います。</p>
    <p>当社が会員から個人情報を取得する目的は特段の表示がない限り次の通りであり、会員は利用目的について同意するものとします。</p>
    <ul>
        <li>会員の本人確認及び各種連絡事項等本サービスの提供のため。</li>
        <li>会員の注文情報を、会員が注文した相手方出品者に提供するため。</li>
        <li>当社の業務遂行及び当社のサービス向上の目的のためにマーケティングデータとして使用するため。</li>
        <li>当社の提供するサービスや他のサービスに関する情報等の案内や広告のため。</li>
        <li>会員は、本サービスに関連して知り得た当社、出品者、パートナー会員及び他の会員の技術上、営業上、業務上等有用な情報を、登録期間中のみならず登録期間経過後においても、第三者に漏洩してはならないものとします。但し、既知となっている情報は除くものとします。</li>
    </ul>


    <h3>第11条（紹介及び報酬）</h3>
    <p>パートナーは、商品出品者が紹介可能と定めた商品についてのみ紹介をすることができます。<br>
    パートナーは、紹介によって商品等が販売された場合は、出品者が定めた報酬を受けることができます。<br>
    パートナーの報酬については、出品者が定めた金額に応じて決定されるものとします。なお、協議で決定した事項については、正確に当社に報告する義務を負うものとします。<br>
    パートナーに報酬が発生する紹介とは、会員が本サービス内に存在しているパートナー用に発行された紹介用リンクを経由してコンテンツの購入が行われた場合を指すものとし、パートナーによる口コミその他の行為を通して申し込みにつながったとしても、紹介リンクを経由していない場合はパートナーに報酬は発生しないものとします。</p>
    <p>出品者は、パートナーを利用して商品を販売した場合、パートナーへの報酬を支払う権限を当社に授与するものとし、当社が代理してパートナーに対して報酬の支払いを行います。</p>
    <p>当社は、出品者を代理してパートナーに紹介に関する報酬をて末締翌々月の10～15日、銀行手数料410円を差し引いた金額を支払います。</p>


    <h3>第12条（パートナーの責任）</h3>
    <p>パートナーは、本サービスの利用に関し一切の責任を負うものとし、本サービスの利用が、第三者の知的財産権等の侵害を行っていないことを当社に保証するものとします。</p>
    <p>前項の定めに関わらず、パートナーの本サービス利用に関して問題が発生した場合は、自己の費用と責任に於いてかかる問題を解決するものとし、当社は一切の責任を負わないものとします。</p>
    <p>パートナーは、自己の責任で本サービスの利用に関するデータのバックアップを取得しなければいけません。当社は、バックアップ未取得によるデータの喪失による責任を負いません。</p>
    <p>パートナーが、本サービスの利用によって当社に対して損害を与えたときは、当該損害を賠償するものとします。（要した弁護士費、和解費、損害賠償費等等の一切を含み、パートナーは、当社に対して、該当する問題の解決に用した一切の金銭的支出について損害賠償義務を負担するものとします。）</p>
    

    <h3>第13条（知的財産権等）</h3>
    <p>本サービスに関する知的財産権等は当社又は正当な権利を有する第三者に帰属します。パートナーは、他のパートナー、当社、出品者、その他第三者の権利侵害をすることのないよう利用方法を守って本サービスを利用してください。</p>


    <h3>第14条（登録の抹消）</h3>
    <p>当社は、パートナーに次の各号のいずれかに該当する事由が生じた場合には、何らの催告なしに直ちに登録を抹消することができるものとします。</p>
    <ol>
        <li>登録内容に虚偽があったなど登録申請段階において登録を承認すべきでない事情があったことが判明した場合</li>
        <li>重大な過失又は背信行為があった場合</li>
        <li>支払いの停止があった場合、又は仮差押、差押、競売、破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始の申立があった場合</li>
        <li>手形交換所の取引停止処分を受けた場合</li>
        <li>公租公課の滞納処分を受けた場合</li>
        <li>その他、本規約又は会員利用規約に違反した場合または当社が必要と判断した場合</li>
    </ol>


    <h3>第15条（秘密保持）</h3>
    <p>当社及びパートナーは、本サービスの利用に関して相手方から開示又は提供された機密情報を善良なる管理者の注意をもって取扱い、事前に書面により相手方の同意を得ることなく、本サービスの目的以外に使用し、又は第三者に開示又は提供してはならないものとします。</p>


    <h3>第16条（裁判管轄）</h3>
    <p>本規約は日本法に準拠し、それに則り解釈されます。</p>


    <p>株式会社ソリッドアライズ</p>
    <p>2021年11月16日:改定<br>
    2021年11月03日:制定</p>
</div>  
</template>
<script>
export default {
    
}
</script>