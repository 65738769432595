var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageContent" },
    [
      _c("div", { staticClass: "content no-login" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("出品者情報")]),
        _vm._v(" "),
        _c("div", { staticClass: "reg" }, [
          _c("div", { staticClass: "regMail regContent row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("アイコン画像")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "file", accept: "image/png, image/jpeg" },
                    on: { change: _vm.changeIcon }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("推奨画像サイズ:300px × 300px 画像容量:1mb以下")
                  ]),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "image_icon_f", errmsg: _vm.errMsgs }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.image_icon != "",
                          expression: "form.image_icon != ''"
                        }
                      ],
                      staticClass: "img-area"
                    },
                    [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: _vm.form.image_icon }
                      })
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("バナー画像")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    staticClass: "form-control",
                    attrs: { type: "file", accept: "image/png, image/jpeg" },
                    on: { change: _vm.changeHead }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("推奨画像サイズ:1200px × 300px 画像容量:2mb以下")
                  ]),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "image_header_f", errmsg: _vm.errMsgs }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.image_header != "",
                          expression: "form.image_header != ''"
                        }
                      ],
                      staticClass: "img-area"
                    },
                    [_c("img", { attrs: { src: _vm.form.image_header } })]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("プロフィール名")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.seller_name,
                        expression: "form.seller_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.seller_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "seller_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "seller_name", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("自己紹介・特定商取引法に基づく表示")
                  ]),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.biography,
                        expression: "form.biography"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { rows: "5" },
                    domProps: { value: _vm.form.biography },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "biography", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "biography", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("出品者公開メールアドレス")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.publish_email,
                        expression: "form.publish_email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.publish_email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "publish_email", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "publish_email", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("事業者名")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.business_name,
                        expression: "form.business_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.business_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "business_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "business_name", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("事業者連絡先")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.business_contact,
                        expression: "form.business_contact"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.business_contact },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form,
                          "business_contact",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "business_contact", errmsg: _vm.errMsgs }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main",
                    attrs: { type: "button" },
                    on: { click: _vm.submitRegist }
                  },
                  [_vm._v("保存")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main right",
                    attrs: { type: "button", disabled: !_vm.isCreated },
                    on: { click: _vm.profile }
                  },
                  [_vm._v("プロフィールを確認")]
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("msg-modal", {
        attrs: { signal: _vm.signal, profileUpdate: _vm.profileUpdate }
      }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }