var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pageContent" },
    [
      _c("div", { staticClass: "content no-login" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("支払い情報ー受取")]),
        _vm._v(" "),
        _c("div", { staticClass: "reg" }, [
          _c("div", { staticClass: "regMail regContent row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("銀行コード")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.bank_cd,
                        expression: "form.bank_cd"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.bank_cd },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "bank_cd", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "bank_cd", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("支店コード")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.branch_cd,
                        expression: "form.branch_cd"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.branch_cd },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "branch_cd", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "branch_cd", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("口座番号")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.account_no,
                        expression: "form.account_no"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.account_no },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "account_no", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "account_no", errmsg: _vm.errMsgs }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("label", { staticClass: "form-label" }, [
                    _vm._v("口座名義")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.account_name,
                        expression: "form.account_name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.form.account_name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "account_name", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("error-text", {
                    attrs: { names: "account_name", errmsg: _vm.errMsgs }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main",
                    attrs: { type: "button" },
                    on: { click: _vm.submitRegist }
                  },
                  [_vm._v("保存")]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._m(0)
          ])
        ])
      ]),
      _vm._v(" "),
      _c("msg-modal", { attrs: { signal: _vm.signal } }),
      _vm._v(" "),
      _c("msg-toast", { attrs: { signal: _vm.toastMsg } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                        売上の支払いサイクルは、月末締め、翌々月10〜15日（土日と重なる場合は先送り）支払いとなります。"
        ),
        _c("br"),
        _vm._v(
          "\n                        売上報酬5000円以上でお支払いとなります。\n                    "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }