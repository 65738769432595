var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", [_vm._v("404 エラーが発生しました。")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "一度時間をおいて再度試して頂くか、更新ボタンを押してページを読み込みなおしてください。"
        ),
        _c("br"),
        _vm._v("それでも解消されない場合、サポートにお問い合わせください。")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }