<style lang="scss" scoped>
@import '../../../../../node_modules/slick-carousel/slick/slick.css';
@import '../../../../../node_modules/slick-carousel/slick/slick-theme.css';
.banner_box {
    height: 12vw;
    margin: 0 0 5px;
    div img {
        height: 12vw;
        width: 100%;
        object-fit: cover;
    }
}
@media screen and (max-width: 896px) {
    .banner_box {
        height: 24vw;
        div img {
            height: 24vw;
        }
    }
}
</style>

<template>
    <div class="banner_box" v-if="banners[0]">
        <Slick ref="slick" :options="slickOptions">
            <div v-for="(banner, index) in banners" :key="index"><a :href="banner.url_link" target="_blank"><img :src="`https://s3.us-east-2.amazonaws.com/image.digi-tab.net${banner.image}`" alt="バナー画像"></a></div>
        </Slick>
    </div>
</template>

<script>
import axios from 'axios';
import Slick from 'vue-slick'

export default {
    components: {
        Slick
    },
    data:function() {
        return{
            banners: {},
            slickOptions:{
                arrows: false,    //スライドの矢印ボタン
                dots: false,      //ドットマーク
                autoplay: true,  //自動スライド
                autoplaySpeed: 5000,  //自動スライド間隔(ms)
                pauseOnFocus: false,  //ドットマークを押すとスライドショーが止まるのを防ぐ
            },
        }
    },
    created() {
        this.getBanners();
    },
    methods: {
        getBanners() {
            axios.get("/banner/api").then(response => {
                this.banners = response.data;

                for(let i=0; i<this.banners.length; i++ ) {
                    // identity_statusが1だったら削除
                    if(this.banners[i].display_flg == 1) {
                        this.banners.splice(i, 1);
                        i = i-1;
                    }
                }
            });
        },
    },
}
</script>