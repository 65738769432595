<style lang="scss" scoped>
.btn-primary {
    background-color: #00BADB;
    border-color: #00BADB;
    color: #fff;
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <div class="reg row">
            <div class="regMail regContent col-sm-6">
                <h2>入力されたEメールアドレスへのメール送信が完了しました。</h2>

                <button type="button" class="btn btn-primary" @click="toTop">トップに戻る</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    methods: {
        toTop:function() {
            this.$router.push({path:"/"});
        }
    }
}
</script>