import Api from "../../common/fetch-wrapper";

// 名前空間
export const CACHE_ARIES = "cacheKey";
// 定数マスタキャッシュ用
export default {
    namespaced: true,
    state: () => ({
        bankList: [],
        bankMap: {},
        categoryList: [],
        categoryMap: {},
        payments: [],
        paymentMap: {}
    }),
    getters: {
        banks : function(state) {
            return state.bankList;
        },
        bankMap : function(state) {
            return state.bankMap;
        },
        categories : function(state) {
            return state.categoryList;
        },
        categoryMap : function(state) {
            return state.categoryMap;
        },
        payments : function(state) {
            return state.payments;
        },
        paymentMap: function(state) {
            return state.paymentMap;
        }
    },
    mutations: {
        saveBankList:function(state, data) {
            state.bankList = data;
            state.bankMap = {};
            data.forEach(e => state.bankMap[e.id] = e.name);
        },
        saveCategoryList:function(state, data) {
            state.categoryList = data;
            state.categoryMap = {};
            data.forEach(e => state.categoryMap[e.id] = e.name);
        },
        savePayments:function(state, data) {
            state.payments = data;
            state.paymentMap = {};
            data.forEach(e => state.paymentMap[e.id] = e.name);
        }
    },
    actions: {
        loadBanks: ({commit, state}) => {
            if(state.bankList[0]) {
                return Promise.resolve([]);
            }
            return Api.get("/banks/list", []).then(x => commit("saveBankList", x));
        },
        loadCategories: ({commit, state}) => {
            if(state.categoryList[0]) {
                return Promise.resolve([]);
            }
            return Api.get("/category/list", []).then(x => commit("saveCategoryList", x));
        },
        loadPayments: ({commit, state}) => {
            if(state.payments[0]) {
                return Promise.resolve([]);
            }
            return Api.get("/paymthd/list").then(x => commit("savePayments", x));
        }
    }
}