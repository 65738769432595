<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-member 4px solid;
    }
}
.content {
    padding: 0 1rem;
}
.btn-primary.main {
    min-width: 150px;
    background-color: $color-main-member;
    border-color: $color-main-member;
    &:disabled {
        color: $gray-font;
        background-color: #eaeaea;
        border-color: #eaeaea;
        cursor:unset;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">購入済み商品</h1>
        <div class="reg">
            <div class="regMail regContent row">
                <div class="col-12">
                    <order-row v-for="i in list" :key="i.id" :item="i" @loading="setLoading" @apply-return="applyReturn"></order-row>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {mapActions} from "vuex";
import Api from "../../common/fetch-wrapper";
import {CACHE_ARIES} from "../../store/modules/cache-store";
import OrderRow from "./parts/order-row";
import MsgModal from "../../components/msg-modal";
import MsgToast from "../../components/msg-toast";

export default {
    components: {
        "order-row" : OrderRow,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    data:function() {
        return {
            list: [],
            signal: {},
            toastMsg: {}
        };
    },
    methods: {
        ...mapActions(CACHE_ARIES,["loadCategories","loadPayments"]),
        search:function() {
            return Api.get("/order/self").then( x => this.list = x);
        },
        research:function(onmsg = false) {
            this.setLoading(true);
            this.search().finally(() => {
                this.setLoading(false);
                if(onmsg) {
                    this.toastMsg = {message:"返品申請が完了しました。"};
                }
            });
        },
        setLoading:function(val) {
            this.$emit("loading", val);
        },
        applyReturn:function(id) {
            this.signal = {
                title: "確認",
                message: "返品申請を行います。よろしいですか？",
                callback:() => {
                    this.setLoading(true);
                    this.errMsgs = {};
                    let dmsg = false;
                    Api.put("/order/apply/return/" + id, undefined, (d) => {
                        if(d.ok) {
                            dmsg = true;
                            return;
                        }
                    }).finally(() => {
                        // 再検索
                        this.research(dmsg);
                    });
                }
            };
        }
    },
    mounted:function() {
        this.setLoading(true);
        Promise.all([
            this.search(),
            this.loadCategories(),
            this.loadPayments()
        ]).then(x => {
        }).finally(() => {
            this.setLoading(false);
        });
    }
}
</script>