import Vue from "vue";
import VueRouter from "vue-router";
import Api from "../common/fetch-wrapper";
import Top from "./top/index";
import NotFound from "./error/404";

import UserPayBank from "./payment/index";
import UserPayCredit from "./payment/credit";
import OrderResult from "./order/result";
import OrderSelf from "./order/self-list";

import Login from "./member/login";
import Register from "./member/register";
import ResetPw from "./member/reset-pw";
import MailSt from "./member/sent-mail";
import PwNew from "./member/set-pw";
import MyPage from "./member/home";
import MemberUpd from "./member/update";
import MemberCredit from "./member/credit";
import MemberDelete from "./member/delete";
import MemberDeleteCompleted from "./member/delete-complete";

import SellerRegist from "./seller/regist";
import SellerInfo from "./seller/update-seller";
import SellerIdentity from "./seller/identity";
import SellerIdentityComplete from "./seller/identity-complete";
import SellerItems from "./seller/items";
import SellerStatistics from "./seller/statistics"
import SellerBuyer from "./seller/buyer"
import SellerShow from "./seller/show"

import PartnerRegist from "./partner/regist";
import PartnerSale from "./partner/sale";

import TransferPay from "./transfer/pay";
import TransferDetail from "./transfer/detail";

import TermPage from "./service/term-page";
import TermSellerPage from "./service/term-seller-page";
import TermPartnerPage from "./service/term-partner-page";
import PolicyPage from "./service/policy-page";
import LawPage from "./service/law-page";

import CategoryItem from "./top/category-item";
import AllCategoryItem from "./top/all-category-item";
import ItemDetail from "./item/product";

import News from "./news/index";
import NewsShow from "./news/show";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes: [
        { component: Top, path: "/" },
        { component: NotFound, path: "*" },
        // 認証系
        { component: Login, path: "/login" },
        { component: Register, path: "/register" },
        { component: ResetPw, path: "/password/reset" },
        { component: PwNew, path: "/password/reset/:uuid" },
        { component: MailSt, path: "/password/email" },
        // 会員系
        { component: MyPage, path: "/member/home" },
        { component: MemberUpd, path: "/member/info" },
        { component: MemberCredit, path: "/member/credit" },
        { component: MemberDelete, path: "/member/delete" },
        { component: MemberDeleteCompleted, path: "/deletecomplete" },
        // 出品者系
        { component: SellerRegist, path: "/seller/regist" },
        { component: SellerInfo, path: "/seller/info"},
        { component: SellerIdentity, path: "/seller/identity"},
        { component: SellerIdentityComplete, path: "/seller/identity-complete"},
        { component: SellerItems, path: "/seller/items"},
        { component: SellerStatistics, path: "/seller/statistics"},
        { component: SellerBuyer, path: "/seller/buyer"},
        { component: SellerShow, path: "/profile/:id" },
        
        // パートナー系
        { component: PartnerRegist, path: "/partner/regist"},
        { component: PartnerSale, path: "/partner/sales"},
        
        // 支払い系
        { component: TransferPay, path: "/transfer/pay"},
        { component: TransferDetail, path: "/transfer/detail"},

        // 利用規約など
        { component: TermPage, path: "/service/term" },
        { component: TermSellerPage, path: "/service/term-seller" },
        { component: TermPartnerPage, path: "/service/term-partner" },
        { component: PolicyPage, path: "/service/policy" },
        { component: LawPage, path: "/service/law" },

        // 商品系
        { component: AllCategoryItem, path: "/category/all"},
        { component: CategoryItem, path: "/category/:id", props: route => ({ categoryId: Number(route.params.id) }) },
        { component: ItemDetail, path: "/item/:id"},

        // 注文
        { component: UserPayBank, path:"/payment/bank/:id" },
        { component: UserPayCredit, path:"/payment/credit/:id" },
        { component: OrderResult, path:"/order/complete/:type/:id" },
        { component: OrderSelf, path:"/order/selflist" },

        // お知らせ系
        { component:News, path: "/news" },
        { component:NewsShow, path: "/news/:id", props: route => ({ newsId: Number(route.params.id) }) },
    ]
});

router.beforeEach((to,from,next) => {
    // 遷移前に認証状態のチェックを行う
    Api.head(to.path).then(x => {
        if([401,403,419].indexOf(x) > -1) {
            // 直接遷移させて、遷移先を維持させる
            location.href = to.path;
            return;
        }
        window.scrollTo(0,0);
        next();
    });
});

// URL変更時に実行される
router.afterEach((to, from) => {
    if (typeof gtag === 'function') {
        gtag('config', 'G-GH84Y1XJWB', {'page_path': to.path});
    }
})

export default router;