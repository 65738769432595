<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-member 4px solid;
    }
}
.content {
    padding: 0 1rem;
    h4 {
        color: #f00;
        font-size: 18px;
    }
    .item_download {
        color: #444;
    }
    .btn {
        min-width: 150px;
    }
    a {
        margin: 0 0 0 30px;
        .btn-primary.main {
            background-color: $color-main-member;
            border-color: $color-main-member;
        }
    }
}
@media screen and (max-width: 896px) {
    .content {
        .btn_box {
            display: flex;
            justify-content: space-between;
            a {
                margin: 0;
            }
            .btn {
                min-width: 120px;
            }
        }
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">会員退会</h1>
        <div class="reg">
            <div class="regContent">
                <div class="row">
                    <div class="col-12">
                        <h3>DIGITABアカウントの削除</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h4 class="mt-3">アカウントを削除すると、元に戻すことはできません。</h4>
                        <p>
                            DIGITABアカウントを削除すると、DIGITAB上で購入した商品をダウンロードできなくなります。<br>
                            あなたが出品した商品及びその他情報も削除されます。
                        </p>
                        <div class="mt-5 btn_box">
                            <button type="button" class="btn btn-secondary main" @click="$router.go(-1)">キャンセル</button>
                            <a href="/deletecomplete"><button type="button" class="btn btn-primary main" @click="delte">退会する</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
export default {
    methods: {
        delte() {
            axios.delete("/member/info");
        }
    }
}
</script>