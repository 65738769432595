var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("出品者登録")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg row" }, [
        _c("div", { staticClass: "regMail regContent" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "terms" },
                [
                  _c("h5", [_vm._v("出品者利用規約")]),
                  _vm._v(" "),
                  _c("term-area")
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "action" }, [
                _c("a", { attrs: { href: "/seller/info" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary main",
                      attrs: { type: "button" },
                      on: { click: _vm.decide }
                    },
                    [_vm._v("規約に合意して登録する")]
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }