var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("div", { staticClass: "reg row" }, [
        _c("div", { staticClass: "regMail regContent col-sm-6" }, [
          _c("h2", [
            _vm._v("入力されたEメールアドレスへのメール送信が完了しました。")
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.toTop }
            },
            [_vm._v("トップに戻る")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }