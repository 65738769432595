<style lang="scss" scoped>
@import "../../../sass/variables";
h1 {
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 3px;
    color: #565656;
    padding: 30px 45px 0;
    margin-bottom: 20px;
    &:before {
        content: "";
        position: relative;
        top: 1px;
        left: -14px;
        border-left: 4px solid;
        border-color: $color-main-free;
    }
}
.vue-table {
    padding: 30px 30px 0;
    .start_date {
        color: $color-main-seller;
    }
    .name {
        width: 200px;
        color: #565656;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .body {
        color: #565656;
    }
    .action {
        width: 150px;
        button {
            width: 100px;
            margin: 0 25px;
            padding: 10px 0;
            border: none;
            background-color: $color-main-free;
            color: #fff;
            &:hover {
                background-color: #07c0ce;
            }
        }
    }
}
@media screen and (max-width: 896px) {
    h1 {
        font-size: 18px;
        letter-spacing: 2px;
        margin-bottom: 10px;
        padding: 20px 15px 0;
        &:before {
            top: 0;
            left: -8px;
        }
    }
    .vue-table {
        padding: 0;
        .start_date {
            font-size: 12px;
            padding: 5px 0 0;
        }
        .name {
            width: 160px;
            padding: 2px 0 0;
        }
        .action {
            width: 60px;
            button {
                width: 60px;
                margin: 0;
                padding: 3px 0;
            }
        }
    }
}
</style>
<template>
    <div class="news-index">
        <h1>お知らせ</h1>
        <div class="vue-table">
            <vuetable ref="vuetable"
                noDataTemplate = "お知らせがありません。"
                :api-mode="false"
                :fields="fields"
                :per-page="perPage"
                :data-manager="dataManager"
                pagination-path="pagination"
                @vuetable:pagination-data="onPaginationData"
                class="table"
            >
                <div slot="start_date" slot-scope="props" class="start_date">{{ props.rowData.start_date }}</div>
                <div slot="name" slot-scope="props" class="name">{{ props.rowData.name }}</div>
                <div slot="body" slot-scope="props" class="body">{{ props.rowData.body | omittedText }}</div>
                <div slot="actions" slot-scope="props" class="action">
                    <router-link :to="`/news/${props.rowData.id}`"><button>詳細</button></router-link>
                </div>
            </vuetable>
            <div class="pagenation">
                <vuetable-pagination ref="pagination"
                    @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {Vuetable, VuetablePagination} from 'vuetable-2';
import _ from "lodash";
import axios from 'axios';
import dayjs from 'dayjs';

export default {
    components: {
        Vuetable,
        VuetablePagination
    },
    data() {
        return {
            fields: [],
            pc_fields: [
                {name: "start_date",title: '日時'},
                {name: "name",title: '件名'},
                {name: "body",title: '内容'},
                {name: 'actions',title: ''}
            ],
            sp_fields: [
                {name: "start_date",title: '日時'},
                {name: "name",title: '件名'},
                {name: 'actions',title: ''}
            ],
            perPage: 5,
            data: [],
        }
    },
    watch: {
        data(newVal, oldVal) {
        this.$refs.vuetable.refresh();
        }
    },
    created() {
        this.getNews();
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        handleResize: function() {
            if (window.innerWidth >= 896) {
                this.fields = this.pc_fields
            } else {
                this.fields = this.sp_fields
            }
        },
        getNews() {
            axios.get("/news/api").then(response => {
            this.data = response.data;
            for(let i=0; i<this.data.length; i++ ) {
                
                // start_dateがnullなら配列を削除する
                const nowDate = dayjs();
                if(this.data[i].start_date == null) {
                    this.data.splice(i, 1)
                    i = i-1;
                }
                // start_dateが現在時刻よりも後か、end_dateが現在時刻よりも前か判断し配列を削除
                else if(nowDate.diff(this.data[i].start_date) <= 0 ) {
                    this.data.splice(i, 1)
                    i = i-1;
                } else if(nowDate.diff(this.data[i].end_date) >= 0) {
                    this.data.splice(i, 1)
                    i = i-1;
                } else {
                    this.data[i].start_date = dayjs(this.data[i].start_date).format('YYYY MM/DD');
                }
            }
            this.data = Object.values(this.data).sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
            });
        },
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page);
        },
        dataManager(sortOrder, pagination) {
            if (this.data.length < 1) return;
            let local = this.data;

            if (sortOrder.length > 0) {
                local = _.orderBy(
                local,
                sortOrder[0].sortField,
                sortOrder[0].direction
            );
        }

        pagination = this.$refs.vuetable.makePagination(
            local.length,
            this.perPage
        );
        let from = pagination.from - 1;
        let to = from + this.perPage;

        return {
            pagination: pagination,
            data: _.slice(local, from, to)
        };
        },
    },
    filters: {
        omittedText(text) {
            // 50文字以降は...を表示する
            return text.length > 50 ? text.slice(0, 50) + "..." : text;
        },
    },
}
</script>