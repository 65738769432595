<style lang="scss" scoped>
@import "../../sass/variables";
.menu-block {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    background: #fff;
    .logo-link {
        display: block;
        width:140px; 
        margin:40px 30px 0;
        cursor: pointer;
        img {
            width: 100%;
        }
    }
    h1 {
        padding: 30px;
  	    text-align: center;
    }
    .member-menus {
        .menu-item {
            border-bottom: 1px solid #eaeaea;
            margin: 7px 0;
            padding: 0.5rem;
            &:hover {
                background-color: #fafafa;
            }
            .menu-head {
                padding-left: 0.7rem;
                cursor: pointer;
                
                &.home {
                    border-left: 3px solid $color-main-free;
                }
                &.member {
                    border-left: 3px solid $color-main-member;
                }
                &.seller {
                    border-left: 3px solid $color-main-seller;
                }
                &.partner {
                    border-left: 3px solid $color-main-partner;
                }
                &.transfer {
                    border-left: 3px solid $color-main-transfer;
                }
                &.logout {
                    border-left: 3px solid #656565;
                }
            }
            .menu-body {
                ul {
                    list-style: none;
                    padding-left: 0.9rem;
                    margin: 10px 0;
                    li {
                        margin-bottom: 5px;
                        cursor: pointer;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .public-menu {
        .menu-item {
            border-bottom: 1px solid #eaeaea;
            margin: 7px 0 0;
            padding: 0.5rem;
            &:hover {
                background-color: #fafafa;
            }
            .menu-head {
                padding-left: 0.7rem;
                cursor: pointer;
                
                &.home {
                    border-left: 3px solid #b3b3b3;
                }
                &.help {
                    display: inline-block;
                    width: 100%;
                    color: #212529;
                    text-decoration: none;
                }
            }
        }
    }
}
@media screen and (min-width: 896px) {
    .burger-menu {display: none;}
}
@media screen and (max-width: 896px) {
    .pc_cont {display: none;}
    .menu-block {
        width: 100%;
    }
    .menu-btn{
        position: fixed;
        top: 20px;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 101;
        span, span:before, span:after {
            content: '';
            display: block;
            height: 3px;
            width: 25px;
            border-radius: 3px;
            background-color: #ffffff;
            position: absolute;
        }
        span {
            &:before {
                bottom: 8px;
            }
            &:after {
                top: 8px;
            }
        }
        &.is-active {
            span {
                background-color: rgba(255, 255, 255, 0);
            }
            span {
                &::before {
                    background-color: #565656;
                    bottom: 0;
                    transform: rotate(45deg);
                    transition: all 0.3s;
                }
                &::after {
                    background-color: #565656;
                    top: 0;
                    transform: rotate(-45deg);
                    transition: all 0.3s;
                }
            }
        }
    }
    .background {
        display: none;
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        &.is-active {
            display: block;
            opacity: 0.5;
        }
    }
    .menu{
        position: fixed;
        right: 0;
        z-index: 100;
        width: 60vw;
        height: 100vh;
        transform: translateX(100vw);
        transition: all .3s linear;
        &.is-active {
            transform: translateX(0);
        }
    }
}
</style>
<template>
<div>
    <!-- バーガーメニュー -->
    <div class="burger-menu">
        <div class="menu-btn" :class="{'is-active' : open }" @click="open=!open">
            <span></span>
        </div>
        <div class="background" :class="{'is-active' : open }" @click="bkClick"></div>
        <div class="menu" :class="{'is-active' : open }">
            <div class="menu-block">
                <!-- ヘッダロゴ -->
                <div>
                    <span class="logo-link" @click="move('/')"><img src="/img/digitab-logo.png" alt="ロゴ"></span>
                </div>
                <!-- メニュー -->
                <div class="public-menu">
                    <div class="menu-item">
                            <div class="menu-head home" @click="move('/category/all')"><span >商品一覧</span></div>
                    </div>
                </div>
                <div class="member-menus" v-if="logined">
                    <div class="menu-item">
                        <div class="menu-head home" @click="move('/member/home')"><span >ホーム</span></div>
                    </div>
                    <div class="menu-item">
                        <div class="menu-head member" @click="toggleMenu('member')"><span>会員</span></div>
                        <div class="menu-body" v-show="nowDisplay('member')">
                            <ul>
                                <li @click="move('/member/info')"><span>会員情報</span></li>
                                <li @click="move('/password/reset')"><span>パスワード</span></li>
                                <li @click="move('/member/credit')"><span>クレジットカード</span></li>
                                <li @click="move('/order/selflist')"><span>購入済み商品</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="menu-head seller" @click="toggleMenu('seller')"><span>出品</span></div>
                        <div class="menu-body" v-show="nowDisplay('seller')">
                            <ul>
                                <template v-if="!sellerOK">
                                <li @click="move('/seller/regist')"><span>出品登録</span></li>
                                </template>
                                <template v-else>
                                <li @click="move('/seller/info')"><span>出品者情報</span></li>
                                <!-- <li @click="move('/seller/pay')"><span>支払い情報・受取</span></li> -->
                                <li @click="move('/seller/identity')"><span>本人確認書類</span></li>
                                <li v-if="origin.identity_status == 3" @click="move('/seller/items')"><span>商品管理</span></li>
                                <li v-if="origin.identity_status == 3" @click="move('/seller/statistics')"><span>統計情報</span></li>
                                <li v-if="origin.identity_status == 3" @click="move('/seller/buyer')"><span>購入者情報</span></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="menu-head partner" @click="toggleMenu('partner')"><span>パートナー</span></div>
                        <div class="menu-body" v-show="nowDisplay('partner')">
                            <ul>
                                <template v-if="!partnerOK">
                                <li @click="move('/partner/regist')"><span>パートナー登録</span></li>
                                </template>
                                <template v-else>
                                <!-- <li @click="move('/partner/pay')"><span>支払い情報 - 受取</span></li> -->
                                <li @click="move('/partner/sales')"><span>売り上げ情報</span></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="menu-item" v-if="partnerOK || sellerOK">
                        <div class="menu-head transfer" @click="toggleMenu('transfer')"><span>振込・支払</span></div>
                        <div class="menu-body" v-show="nowDisplay('transfer')">
                            <ul>
                                <template>
                                <li @click="move('/transfer/pay')"><span>支払い情報 - 受取</span></li>
                                <li @click="move('/transfer/detail')"><span>支払明細書</span></li>
                                </template>
                            </ul>
                        </div>
                    </div>
                    <div class="menu-item">
                        <div class="menu-head logout" @click="logout"><span >ログアウト</span></div>
                    </div>
                </div>        
            </div>
        </div>
    </div>
    <div class="menu-block pc_cont">
        <!-- ヘッダロゴ -->
        <div>
            <span class="logo-link" @click="move('/')"><img src="/img/digitab-logo.png" alt="ロゴ"></span>
        </div>
        <!-- メニュー -->
        <div class="public-menu">
            <div class="menu-item">
                    <div class="menu-head home" @click="move('/category/all')"><span >商品一覧</span></div>
            </div>
        </div>
        <div class="member-menus" v-if="logined">
            <div class="menu-item">
                <div class="menu-head home" @click="move('/member/home')"><span >ホーム</span></div>
            </div>
            <div class="menu-item">
                <div class="menu-head member" @click="toggleMenu('member')"><span>会員</span></div>
                <div class="menu-body" v-show="nowDisplay('member')">
                    <ul>
                        <li @click="move('/member/info')"><span>会員情報</span></li>
                        <li @click="move('/password/reset')"><span>パスワード</span></li>
                        <!-- <li @click="move('/member/pay')"><span>支払い情報</span></li> -->
                        <li @click="move('/member/credit')"><span>クレジットカード</span></li>
                        <li @click="move('/order/selflist')"><span>購入済み商品</span></li>
                        <!-- <li @click="move('/member/subscription')"><span>継続商品</span></li> -->
                    </ul>
                </div>
            </div>
            <div class="menu-item">
                <div class="menu-head seller" @click="toggleMenu('seller')"><span>出品</span></div>
                <div class="menu-body" v-show="nowDisplay('seller')">
                    <ul>
                        <template v-if="!sellerOK">
                        <li @click="move('/seller/regist')"><span>出品登録</span></li>
                        </template>
                        <template v-else>
                        <li @click="move('/seller/info')"><span>出品者情報</span></li>
                        <!-- <li @click="move('/seller/pay')"><span>支払い情報・受取</span></li> -->
                        <li @click="move('/seller/identity')"><span>本人確認書類</span></li>
                        <li v-if="origin.identity_status == 3" @click="move('/seller/items')"><span>商品管理</span></li>
                        <li v-if="origin.identity_status == 3" @click="move('/seller/statistics')"><span>統計情報</span></li>
                        <li v-if="origin.identity_status == 3" @click="move('/seller/buyer')"><span>購入者情報</span></li>
                        <!-- <li @click="move('/seller/message')"><span>メッセージ</span></li> -->
                        </template>
                    </ul>
                </div>
            </div>
            <div class="menu-item">
                <div class="menu-head partner" @click="toggleMenu('partner')"><span>パートナー</span></div>
                <div class="menu-body" v-show="nowDisplay('partner')">
                    <ul>
                        <template v-if="!partnerOK">
                        <li @click="move('/partner/regist')"><span>パートナー登録</span></li>
                        </template>
                        <template v-else>
                        <!-- <li @click="move('/partner/pay')"><span>支払い情報 - 受取</span></li> -->
                        <!-- <li @click="move('/partner/info')"><span>新規登録</span></li>
                        <li @click="move('/partner/items')"><span>登録商品一覧</span></li> -->
                        <li @click="move('/partner/sales')"><span>売り上げ情報</span></li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="menu-item" v-if="partnerOK || sellerOK">
                <div class="menu-head transfer" @click="toggleMenu('transfer')"><span>振込・支払</span></div>
                <div class="menu-body" v-show="nowDisplay('transfer')">
                    <ul>
                        <template>
                        <li @click="move('/transfer/pay')"><span>支払い情報 - 受取</span></li>
                        <li @click="move('/transfer/detail')"><span>支払明細書</span></li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="public-menu">
            <div class="menu-item">
                <a class="menu-head home help" href='https://digi-tab-support.com/' target="blanc"><span>ヘルプ</span></a>
            </div>
        </div>      
    </div>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { USER_ARIES } from "../store/modules/user-store";

export default {
    data :function() {
        return {
            dispMenu: "",
            open: false,
        }
    },
    computed: {
        ...mapGetters(USER_ARIES,["logined","userName","sellerOK","partnerOK","origin"]),
    },
    methods: {
        bkClick:function() {
            this.open = false;
        },
        move:function(path) {
            if(this.$router.currentRoute.path !== path) {
                this.$router.push({path:path});
                this.open = false;
            }
        },
        nowDisplay:function(menu) {
            return this.dispMenu === menu;
        },
        toggleMenu:function(menu) {
            if(this.nowDisplay(menu)) {
                this.dispMenu = "";
            } else {
                this.dispMenu = menu;
            }
        },
        logout:function() {
            const result = confirm("ログアウトしますか？");
            if(result) {
                location.href = "/member/logout";
            }
        },
    }

    
}
</script>