<style lang="scss" scoped>
@import "../../../sass/variables";

.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
</style>
<template>
<div>
    <div class="pageContent">
        <div class="content listing-login">
            <h2 class="h2Product page-title">本人確認書類</h2>
            <div class="infoArea">
                <p>送付が完了しました。<br>審査終了まで、実営業日5日以内で審査が完了します。</p>
            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    methods: {
    }
}
</script>