var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "small",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.validate,
          expression: "validate"
        }
      ],
      staticClass: "error"
    },
    [_vm._v(_vm._s(_vm.errorMsg))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }