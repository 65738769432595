var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("会員退会完了")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg" }, [
        _c("div", { staticClass: "regContent" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 mt-3" }, [
              _c("p", [
                _vm._v(
                  "\n                            ご利用いただきありがとうございました。\n                        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mt-5" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary main",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.move("/")
                      }
                    }
                  },
                  [_vm._v("TOPページへ")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h3", [_vm._v("DIGITABからの退会処理が完了しました。")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }