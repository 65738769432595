<style lang="scss" scoped>
.form-label {
    margin-bottom: 1px;
}
.card-type {
    font-size: 11px;
    color: #888;
}
</style>
<template>
<div class="creadit-input">
    <div v-show="!!stripeCardList[0]" class="selected-area">
        <div class="form-check">
            <label class="form-check-label"><input class="form-check-input" type="radio" v-model="payMode" value="0"> 保存済みカード：{{cardName}}</label>
        </div>
        <div class="form-check" v-show="!!stripeCardList[0]">
            <label class="form-check-label"><input class="form-check-input" type="radio" v-model="payMode" value="1"> 新規登録/更新</label>
        </div>
    </div>
    <div class="input-area">
        <div class="row">
            <div class="col-12">
                <div class="mb-3">
                    <label class="form-label">カード番号</label>
                    <div class="card-type">JCB、VISA、MasterCard、AMERICAN EXPRESS、Diners Club、Discoverがご利用いただけます。</div>
                    <div id="card-number" class="form-control"></div>
                    <error-text names="cardNumber" :errmsg="errMsgs"></error-text>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="mb-3">
                    <label class="form-label">有効期限</label>
                    <div id="card-expiry" class="form-control"></div>
                    <error-text names="cardExpiry" :errmsg="errMsgs"></error-text>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="mb-3">
                    <label class="form-label">セキュリティコード</label>
                    <div id="card-cvc" class="form-control"></div>
                    <error-text names="cardCvc" :errmsg="errMsgs"></error-text>
                </div>
            </div>
        </div>
        <!-- <div class="card-type">JCB、VISA、MasterCard、AMERICAN EXPRESS、Diners Club、Discoverがご利用いただけます。</div>
        <div id="card-container"></div> -->
    </div>
</div>
</template>
<script>
import {loadStripe} from "@stripe/stripe-js";
import {mapGetters} from "vuex";
import ErrorText from "./error-text";
import {USER_ARIES} from "../store/modules/user-store";

export default {
    components: {
        "error-text" : ErrorText
    },
    data:function() {
        return {
            payMode: "1",
            stripe: {},
            complete: false,
            stripeOptions: {
                hidePostalCode: true
            },
            cardForm: {},
            state: {
                cardNumber: false,
                cardExpiry: false,
                cardCv: false
            },
            errMsgs: {
                cardNumber : {},
                cardExpiry: {},
                cardCvc : {}
            },
            // squareCard : {}
        };
    },
    computed: {
        ...mapGetters(USER_ARIES,["stripePubKey","stripeCardList"]),
        cardName:function() {
            if(!this.stripeCardList || !this.stripeCardList[0]) {
                return "";
            }
            return this.stripeCardList[0].name;
        }
    },
    watch: {
        payMode:function(nv) {
            if(nv === "0" && !!this.stripeCardList[0]) {
                this.$emit("decide-token",{ token:{id:this.stripeCardList[0].id} });
            }else if(nv === "1") {
                this.onInputComplete();
            }
        }
    },
    methods: {
        // initSquarePay: function() {
        //     const payments = window.Square.payments(this.squareAppId, this.squareLocId);
        //     payments.setLocale("ja").then(() => {
        //         payments.card({
        //             postalCode: ""
        //         }).then(x => {
        //             this.squareCard = x; 
        //             this.squareCard.addEventListener("escape",(ev) => {
        //                 this.tokenizeSquare();
        //             });
        //             this.squareCard.addEventListener("submit",(ev) => {
        //                 this.tokenizeSquare();
        //             });
        //             this.squareCard.addEventListener("focusClassRemoved",(ev) => {
        //                 this.tokenizeSquare();
        //             });
        //             this.squareCard.attach('#card-container').then(() => {});
        //         });
        //     });
        // },
        // tokenizeSquare:function() {
        //     this.squareCard.tokenize().then(x => {
        //         if (x.status == "OK") {
        //             this.$emit("decide-token",{token:{id:x.token}});
        //         }
        //     });
        // },
        onInputComplete: function(){
            if(this.completed()) {
                this.stripe.createToken(this.cardNumber).then(x => {
                    if(!x.error) {
                        this.$emit("decide-token",x);
                    }
                });
            } else {
                // 初期化する
                this.$emit("decide-token",{ token:{id:""} });
            }
        },
        completed:function() {
            return this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc;
        }
    },
    mounted:function() {
        this.payMode = !!this.stripeCardList[0] ? "0" : "1";
        loadStripe(this.stripePubKey).then(x => {
            this.stripe = x;
            const elements = this.stripe.elements({});
            const elementStyles = {
                base: {
                color: '#32325D',
                fontWeight: 500,
                fontSize: '1.2em',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                    color: '#CFD7DF'
                },
                ':-webkit-autofill': {
                    color: '#e39f48'
                }
                },
                invalid: {
                color: '#E25950',
                '::placeholder': {
                    color: '#FFCCA5'
                }
                }
            };
            const elementClasses = {
                focus: 'focused',
                empty: 'empty',
                invalid: 'invalid'
            };
            this.cardNumber = elements.create('cardNumber', {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardNumber.mount('#card-number');
            this.cardNumber.addEventListener('change', event => {
                this.state.cardNumber = event.complete;
                if (event.complete) {
                    this.errMsgs["cardNumber"] = {};
                    this.cardExpiry.focus();
                } else if (event.error) {
                    this.errMsgs["cardNumber"] = { 0:event.error.message};
                }
                this.onInputComplete();
            });
            this.cardExpiry = elements.create('cardExpiry', {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardExpiry.mount('#card-expiry');
            this.cardExpiry.addEventListener('change', event => {
                this.state.cardExpiry = event.complete;
                if (event.complete) {
                    this.errMsgs["cardExpiry"] = {};
                    this.cardCvc.focus();
                } else if (event.error) {
                    this.errMsgs["cardExpiry"] = { 0:event.error.message};
                }
                this.onInputComplete();
            });
            this.cardCvc = elements.create('cardCvc', {
                style: elementStyles,
                classes: elementClasses
            });
            this.cardCvc.mount('#card-cvc');
            this.cardCvc.addEventListener('change', event => {
                this.state.cardCvc = event.complete;
                if (event.complete) {
                    this.errMsgs["cardCvc"] = {};
                } else if (event.error) {
                    this.errMsgs["cardCvc"] = { 0:event.error.message};
                }
                this.onInputComplete();
            });
        });
        this.initSquarePay();
    },
}
</script>