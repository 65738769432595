<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-seller 4px solid;
    }
}
.content {
    padding: 0 1rem;
}

.img-area {
    margin: 5px 0;
    img {
        width:100%;
        &.icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #ccc;
        }
    }
}
.btn-primary.main {
    background-color: $color-main-seller;
    border-color: $color-main-seller;
}
@media screen and (max-width: 896px) {
    .btn-primary.main {
        width: 100%;
        margin: 15px 0 0;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">出品者情報</h1>
        <div class="reg">
            <div class="regMail regContent row">
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">アイコン画像</label>
                        <input type="file" class="form-control" accept="image/png, image/jpeg" @change="changeIcon" />
                        <span>推奨画像サイズ:300px × 300px 画像容量:1mb以下</span>
                        <error-text names="image_icon_f" :errmsg="errMsgs"></error-text>
                        <div class="img-area" v-show="form.image_icon != ''">
                            <img class="icon" :src="form.image_icon">
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">バナー画像</label>
                        <input type="file" class="form-control" accept="image/png, image/jpeg" @change="changeHead" />
                        <span>推奨画像サイズ:1200px × 300px 画像容量:2mb以下</span>
                        <error-text names="image_header_f" :errmsg="errMsgs"></error-text>
                        <div class="img-area" v-show="form.image_header != ''">
                            <img :src="form.image_header">
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="mb-3">
                        <label class="form-label">プロフィール名</label>
                        <input class="form-control" type="text" v-model="form.seller_name">
                        <error-text names="seller_name" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">自己紹介・特定商取引法に基づく表示</label>
                        <textarea rows="5" class="form-control" v-model="form.biography"></textarea>
                        <error-text names="biography" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">出品者公開メールアドレス</label>
                        <input class="form-control" type="text" v-model="form.publish_email">
                        <error-text names="publish_email" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">事業者名</label>
                        <input class="form-control" type="text" v-model="form.business_name">
                        <error-text names="business_name" :errmsg="errMsgs"></error-text>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">事業者連絡先</label>
                        <input class="form-control" type="text" v-model="form.business_contact">
                        <error-text names="business_contact" :errmsg="errMsgs"></error-text>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main" @click="submitRegist">保存</button>
                    </div>
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main right" @click="profile" :disabled="!isCreated">プロフィールを確認</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal" :profileUpdate="profileUpdate"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import { isEmpty } from '../../common/com-func';
import Api,{token} from "../../common/fetch-wrapper";
import ErrorText from "../../components/error-text";
import {USER_ARIES} from "../../store/modules/user-store";
import MsgModal from "../../components/msg-modal";
import MsgToast from "../../components/msg-toast";

export default {
    components: {
        "error-text" : ErrorText,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    data:function() {
        return {
            error: false,
            token : token,
            beforeForm: {
                image_icon: "",
                image_header: "",
                image_icon_f: "",
                image_header_f: "",
                biography : "販売者名	\r\n-\r\n\r\n所在地\r\n-\r\n\r\n電話番号\r\n-\r\n\r\nメールアドレス\r\n-\r\n\r\n商品引き渡し方法\r\n-\r\n\r\n商品引き渡し時期\r\n-\r\n\r\n返品・不良品について\r\n-", 
                seller_name : "",
                publish_email : "",
                business_name : "",
                business_contact : ""
            },
            form: {
                image_icon: "",
                image_header: "",
                image_icon_f: "",
                image_header_f: "",
                biography : "販売者名	\r\n-\r\n\r\n所在地\r\n-\r\n\r\n電話番号\r\n-\r\n\r\nメールアドレス\r\n-\r\n\r\n商品引き渡し方法\r\n-\r\n\r\n商品引き渡し時期\r\n-\r\n\r\n返品・不良品について\r\n-", 
                seller_name : "",
                publish_email : "",
                business_name : "",
                business_contact : ""
            },
            isCreated: false,
            errMsgs: {},
            signal: {},
            profileUpdate: false,
            toastMsg: {}
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["errors","origin","userID"])
    },
    methods: {
        ...mapActions(USER_ARIES, ["removeError"]),
        submitRegist:function(e) {
            this.profileUpdate = false;
            this.signal = {
                title: "確認",
                message: "出品者情報を登録します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    Api.put("/seller/update", this.createForm(), (d) => {
                        if(d.ok) {
                            this.toastMsg = {message:"登録が完了しました。"};
                            this.isCreated = true
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        },
        createForm:function() {
            const form = new FormData();
            form.append("_token", this.token);
            Object.keys(this.form).forEach(k => {
                if(!isEmpty(this.form[k])) {
                    form.append(k, this.form[k]);
                }
            });
            return form;
        },
        changeIcon:function(e) {
            this.form.image_icon_f = e.target.files[0];
        },
        changeHead:function(e) {
            this.form.image_header_f = e.target.files[0];
        },
        profile:function() {
            if(JSON.stringify(this.beforeForm) != JSON.stringify(this.form)) {
                this.profileUpdate = true;
                this.signal = {
                    title: "確認",
                    message: "保存しなかった内容は反映されません。保存しますか？",
                    callback:() => {
                        this.$emit("loading", true);
                        this.errMsgs = {};
                        Api.put("/seller/update", this.createForm(), (d) => {
                            if(d.ok) {
                                this.toastMsg = {message:"登録が完了しました。"};
                                this.isCreated = true
                                return;
                            }
                            if(d.status === 400 || d.status === 422) {
                                d.json().then(x => {
                                    this.errMsgs = x.errors;
                                });
                            }
                        }).finally(() => {
                            this.$emit("loading", false);
                            this.$router.push(`/profile/${this.userID}`);
                        });
                    },
                    update:() => {
                        this.$router.push(`/profile/${this.userID}`);
                    }
                };
            } else {
                this.$router.push(`/profile/${this.userID}`);
            }
        }
    },
    mounted:function() {
        if(this.origin) {
            Object.keys(this.form).forEach(x => {
                if(this.origin[x]) {
                    this.form[x] = this.origin[x];
                    this.beforeForm[x] = this.origin[x];
                }
            });
        }
        if(this.form.seller_name){
            this.isCreated = true
        }
    }
}
</script>