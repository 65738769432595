var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "mainFooter" }, [
    _c("div", { staticClass: "contentFooter" }, [
      _c("div", { staticClass: "footerMenu-logo" }, [
        _c(
          "span",
          {
            on: {
              click: function($event) {
                return _vm.move("/")
              }
            }
          },
          [
            _c("img", {
              attrs: { src: "/img/digitab-logo-white.png", alt: "ロゴ" }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footerMenu-content" }, [
        _c("ul", { staticClass: "menu" }, [
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/news")
                  }
                }
              },
              [_vm._v("お知らせ")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/service/term")
                  }
                }
              },
              [_vm._v("会員利用規約")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/service/term-seller")
                  }
                }
              },
              [_vm._v("出品者利用規約")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/service/term-partner")
                  }
                }
              },
              [_vm._v("パートナー利用規約")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/service/policy")
                  }
                }
              },
              [_vm._v("プライバシーポリシー")]
            )
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "span",
              {
                on: {
                  click: function($event) {
                    return _vm.move("/service/law")
                  }
                }
              },
              [_vm._v("特定商取引法に基づく表示")]
            )
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1)
        ])
      ])
    ]),
    _vm._v(" "),
    _vm._m(2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", [
        _c(
          "a",
          { attrs: { href: "https://digi-tab-support.com/", target: "blanc" } },
          [_vm._v("ヘルプ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("span", [
        _c(
          "a",
          {
            attrs: {
              href: "https://digi-tab-support.com/help-contact.html",
              target: "blanc"
            }
          },
          [_vm._v("お問い合わせ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "copyright" }, [
      _c("p", [_vm._v("COPYRIGHT ©DIGITAB , ALL RIGHT RESERVED")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }