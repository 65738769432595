<style lang="scss" scoped>
p {
    margin: 2rem;
}
</style>
<template>
  <div>
    <p>404 エラーが発生しました。</p>
    <p>一度時間をおいて再度試して頂くか、更新ボタンを押してページを読み込みなおしてください。<br>それでも解消されない場合、サポートにお問い合わせください。</p>
  </div>
</template>
<script>

export default {

};
</script>