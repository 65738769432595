<style lang="scss" scoped>
.scroll.high {
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    h4{
        font-size: 14px;
        margin-bottom: 10px;
    }
    ul {
        padding-left: 22px;
        font-size: 13px;
        list-style: disc;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 896px) {
    .scroll.high {
        h3 {
            font-size: 14px;
        }
        p {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="scroll high">
    <h3>第1条（本規約について）</h3>
    <p>出品者利用規約（以下「本規約」といいます。）は、株式会社ソリッドアライズ（以下「当社」といいます。）が第2条１項で規定する出品者に対して提供する、Digitab販売システム・アフィリエイトシステム・メールシステムおよびそれらに関連したサポートサービス（以下総称して「本サービス」といいます。）の利用について定めます。</p>
    <p>第2条で定義される出品者は、本規約の内容及び会員利用規約に同意して、本サービスを利用するものとします。</p>
    <p>本規約は、本サービスとの利用に関して当社と出品者との間に発生する一切の関係に適用します。</p>


    <h3>第2条（定義）</h3>
    <p>本規約に定める語句および定義は以下のとおりとします。</p>
    <ul>
        <li>「利用者」とは、本サービスを閲覧・利用する全ての個人・法人を指します。</li>
        <li>「会員」とは、本規約に同意して登録を申し込み、出品者（3項）・パートナー（4項）を含むアカウント（5項）を開設した者をいいます。</li>
        <li>「出品者」とは、当社との間で本サービスに関して、別に定める出品者利用規約に同意した会員のうち、本サービスを利用し、商品やサービスの販売等、電子商取引を行う会員をいいます。</li>
        <li>「パートナー」とは、本サービスに関する利用規約に同意して登録を申し込み、当社の承諾によりアフィリエイトシステム（6項）においてアフィリエイターとして登録された会員を指します。</li>
        <li>「アカウント」とは、本サービスにおいて、別に定めるパートナー利用規約に同意した会員で管理画面など本サービスの特定領域を利用するために、会員・出品者・パートナー会員に対して発行される資格のことをいいます。</li>
        <li>「アフィリエイトプログラム」とは、パートナーがウェブサイトその他の媒体からそれを訪れる訪問者に出品者の運営・管理するサイトへ訪れるように誘導し、訪問者に商品・サービスを購入させることとし、訪問者を紹介された出品者はパートナーに対し、当該成果に応じて各プログラムに規定された成功報酬を支払う仕組みをいいます。</li>
    </ul>
    <p>本規約は、当社が運営する、本サービスの出品者用の利用条件を定めるものです。</p>


    <h3>第3条（本規約の改定）</h3>
    <p>当社は、本規約を任意に改定することができるものとし、会員はこれを承諾するものとします。</p>
    <p>改定後の規約については、当社が別途定める場合を除き、当社ウェブサイトに提示した時点より効力を生じるものとします。</p>


    <h3>第4条（規約の適用範囲）</h3>
    <p>本規約は、本サービスの利用に関して当社と出品者との間における関係性全てに適用します。<br>
    出品者は、出品者利用規約に同意した時点をもって本規約および当社が別途定める会員利用規約に同意したものとみなします。</p>


    <h3>第5条（サービスの内容）</h3>
    <p>当社は利用者に決済サービス、アフィリエイトシステムおよび各種インターネットサービスを提供するものとします。</p>
    <p>出品される商品およびサービスに類する一切の著作権は、出品者に留保されるものとします。</p>
    <p>出品者は、出品者が運営するホームページまたはブログ等のメディアにおいて、商品紹介や購入の誘導を行う場合、必要がある場合には当該ホームページまたはブログ等のメディアにも「特定商取引に関する法律」に基づく表記を記載するものとします。</p>


    <h3>第6条（出品者登録)</h3>
    <p>本サービスを会員として利用したい方は、本規約の内容を確認後、会員登録の申し込みを行なってください。なお、お電話・FAXによる会員登録申込みは原則として受け付けておりません。</p>
    <p>会員登録までの手順は、利用規約と同一の登録作業を行い、その後出品利用規約に同意後、本人確認書類を提出し当社が当社規定に従って審査のうえで承諾し，当社からの本登録完了します。その後、出品者情報を入力し出品者登録は完了します。</p>
    <p>以下の各号を全て満たす場合でなければ、出品者登録はできません。</p>
    <ul>
        <li>ねずみ講等の違法性を有する取引に関わっていないこと。</li>
        <li>反社会的勢力としての活動、交流、資本関係ないし資金の提供をしていないこと。</li>
        <li>社会的勢力を役員、従業員として雇用していないこと。</li>
        <li>登録申込時に虚偽の情報を入力しないこと。</li>
        <li>登録申込時に他者の情報を入力しないこと。</li>
        <li>digi-tab.netドメインからのメールを受信できない状態で登録申込を行なうこと。</li>
        <li>本規約を読み、遵守することを承認していること。</li>
        <li>過去に本サービスの会員登録を解除されていないこと。</li>
        <li>その他当社が登録を不適当と認める者でないこと。</li>
    </ul>


    <h3>第7条（出品)</h3>
    <p>出品を行う前当社は出品者が販売する出品物に関して確認、ダウンロードする権利を有するものとしその商品・及び内容が当社基準において問題ないかを審査します。<br>
    また出品物が下記に該当する場合、販売を許可せず、またすでに出品して場合でも即時停止を行えるものとします。また繰り返し下記に該当する出品を行おうとした場合、アカウントの停止を行うものとします。</p>
    <ul>
        <li>物販及びそれに伴う引き換え権利として当社の許可なく販売体系をとっている商品。</li>
        <li>違法であるもの。</li>
        <li>性的な表現がされているもの。</li>
        <li>個人を誹謗中傷するもの、著しく他人を貶めると判断された商品。</li>
        <li>商標権や著作権などの知的財産権を侵害する商品。</li>
        <li>他者が作成し販売許可がない商品などの不正な経路で入手した商品。</li>
        <li>商品内容と一致しない実在しない商品。</li>
        <li>個人情報を含む商品ないし個人情報の不正利用した商品。</li>
        <li>出品者情報に正当性がないと判断した場合。</li>
        <li>出品に伴う特商法表記に正当性がないと判断した場合。</li>
        <li>その他、当社が不適切と判断したもの。</li>
    </ul>
    <p>審査が不許可となった場合、当社はその理由について明確に連絡しないものとします。</p>


    <h3>第8条（登録情報の変更）</h3>
    <p>出品者は自らの登録情報に変更が生じた場合には、速やかに管理画面の出品者情報を変更・修正するものとします。</p>
    <p>当社は、出品者が第1項に定める変更登録を行なわなかったことにより生じた損害について、その一切の責任を負わないものとします。</p>
    <p>また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は変更登録前の情報に基づいて行われます。このような取引について登録情報の変更内容を会員に通知する必要がある場合には、出品者より会員に直接ご連絡下さい。</p>


    <h3>第9条（権利の譲渡の禁止）</h3>
    <p>会員のログイン情報に関しては、第三者に貸し出しまたは売却、譲渡することはできません。ログイン情報の管理は、会員が責任を負うものとし、ログイン情報を使用して行われた全ての行為は、理由の如何を問わずそのログイン情報を登録した会員が全ての責任を負います。</p>


    <h3>第10条（サービス利用料 および銀行振込手数料）</h3>
    <p>決済サービス利用料は、出品者が負担するものとし、出品者が販売を行う商品およびサービスが会員に販売されるごとに以下の利用料として税込販売価格の10%が発生します。<br>
    なお、利用料算出の際、小数点以下の端数が発生した場合の端数については四捨五入にて処理するものとします。</p>
    <p>月末に売上を合算し5,000円以上の売上が発生していた場合、当社は出品者に対して翌々月の10～15日、銀行手数料410円を差し引きます。</p>
    <p>また出品者の判断によりキャンセル・返品・返金が行われたとしても当社への手数料・パートナーへの報酬に関して出品者に返金することはなく出品者が負担するものとします。<br>
    返金に伴う銀行振込の手数料に関しては、会員と出品者が話し合いの元決定するものとし、当社は一切責任を負わないものとします。</p>


    <h3>第11条（販売商品の免責）</h3>
    <ol>
        <li>出品された商品およびサービスに対して第三者の権利侵害に関する問題や何らかの法的問題が発生した場合は、出品者と購入した会員間で解決するものとし、当社は一切の責任を負わず、また、当社はその出品者の情報を、権利を侵害されたと主張する第三者に開示することができるものとします。<br>
        また、出品された商品およびサービスに起因して当社が第三者の権利を侵害したことを理由とし損害賠償等の請求を受けた時、出品を行った出品者の責任と費用負担においてこれを解決するものとします。（もし当社が対処した場合、要した弁護士費、和解費、損害賠償費等等の一切を含み、出品者は、当社に対して、当社が負担した一切の金銭的支出について損害賠償義務を負担するものとします。）</li>
        <li>出品者は、その出品した商品およびサービスについて瑕疵がないことを保証するものとします。会員より破損、不備、動作不良等のクレームが入った場合、出品者は、正常な商品およびサービスを再度会員に提供するか、或いは購入代金の返金に応じるものとします。当社は、上記商品およびサービスの瑕疵等について一切の責任は負わないものとします。</li>
        <li>商品およびサービスに関するサポート業務が発生した場合、出品者自身が対応するものとします。</li>
        <li>当社は、出品された商品およびサービスを審査することが出来るものとします。販売中の商品であっても、当社が不適切な商品やサービスと認めた場合、または第三者からクレームを受けた場合、出品者の了承を得ずに当社の判断のみで当該商品およびサービスを販売中止にすることができるものとします。</li>
    </ol>


    <h3>第12条（個人情報の取扱い）</h3>
    <p>当社は本サービスの運営につき会員の個人情報を取得した場合、当社の定めるプライバシーポリシーに基づいて個人情報を取り扱います。</p>
    <p>当社が会員から個人情報を取得する目的は特段の表示がない限り次の通りであり、会員は利用目的について同意するものとします。</p>
    <ul>
        <li>会員の本人確認及び各種連絡事項等本サービスの提供のため。</li>
        <li>会員の注文情報を、会員が注文した相手方出品者に提供するため。</li>
        <li>当社の業務遂行及び当社のサービス向上の目的のためにマーケティングデータとして使用するため。</li>
        <li>当社の提供するサービスや他のサービスに関する情報等の案内や広告のため。</li>
    </ul>
    <p>会員は、本サービスに関連して知り得た当社、出品者、パートナー会員及び他の会員の技術上、営業上、業務上等有用な情報を、登録期間中のみならず登録期間経過後においても、第三者に漏洩してはならないものとします。但し、既知となっている情報は除くものとします。</p>


    <h3>第13条（免責事項）</h3>
    <p>利用者は当社が行う本サービスの特性として、以下の事項に起因する損害が利用者に発生し得ることを了承し、かかる損害につき当社はその責任を負わないことに同意します。</p>
    <ol>
        <li>通信環境の障害等に起因する一時的なサービス停止。</li>
        <li>本件サイトにおいて提供するサービスにかかわるシステム上の不具合に起因する機会の喪失。</li>
        <li>当社の管理する本サービスを運用するためのサーバー内のディスククラッシュによるデータの消失。</li>
        <li>利用権限認証等通常行われる保護措置の範囲を超えた不正利用行為。</li>
    </ol>


    <h3>第14条（アップロードデータについて）</h3>
    <ol>
        <li>本サービスでアップロードされた出品者商品のデータは、返却いたしません。</li>
        <li>出品者は、本規約第21条で定める契約期間を経過した後も、会員に対する障害対応等を目的として出品者商品データが当社に保管されることを承認するものとします。</li>
    </ol>


    <h3>第15条（保証）</h3>
    <p>当社は、本サービスにおける公開情報等について、完全性、正確性、有用性等の保証をいたしません。</p>


    <h3>第16条（仕様変更など）</h3>
    <p>本規約に定める内容や本サービスの仕様等について変更または修正等を行う場合には、当社における事前の書面、もしくは、本件サイトにおける告知による通知を行うものとします。</p>


    <h3>第17条（支払）</h3>
    <p>当社は出品者に対し、会員による支払金額から、決済サービス利用料、アフィリエイト手数料を控除した税込金額を、月末締めで翌々月10-15日（その日が金融機関休業日にあたる場合はその翌営業日とします）に登録指定口座に振り込むものとします。なお、振込手数料は出品者の負担とします。また、当社から出品者に対する支払については、以下の各号の定めに従うものとします。</p>
    <ol>
        <li>1回当たりの振込手数料は、その振込金額にかかわらず、また、振込指定口座がいずれの金融機関・支店であるかを問わず、一律410円とします。</li>
        <li>支払金額の合計金額が5,000円未満の場合は、翌月以降の支払へと繰り延べられるものとします。</li>
        <li>出品者名と口座名義が異なる場合、出品者に本人確認する場合がございます。その場合、本人確認作業が終了するまでお支払いは延期されます。</li>
        <li>入力間違いなどで登録指定口座に不備があり、そのことが原因でお支払いができなかった場合、当社は一切責任を負うことはありません。また、複数回の振込を実施した場合は、その振込手数料は出品者の負担とします。</li>
        <li>出品者の口座情報に空白または不備があり、報酬振込が出来ない場合、当社より登録メールアドレス宛に登録情報修正依頼のメールを送信しています。最初の修正依頼のメール送信後、3ヶ月間の更新が無い場合、報酬の受取を放棄したものとします。</li>
        <li>会員が当社に対し支払を遅延した場合は、当社は利用者に対し支払を留保することがあります。また、会員が当社に支払を行わない場合は、当社は利用者に対する支払を行わないことがあります。また、会員が当社に購入代金その他の対価の支払を遅延した後に当該対価の支払が実施された場合において、当該対価の支払回収までに当社が要した費用は利用者の負担となりますので、当社は当該費用を利用者への報酬から差し引くことができるものとし、その結果として利用者に対する報酬を支払わないことがあります。これらについて当社は一切の責任を負わないものとします。</li>
        <li>会員がクーリング・オフの適用を申し出た場合、当社は出品者に対する支払いは行いません。</li>
    </ol>


    <h3>第18条（キャンセル、返金に関して）</h3>
    <p>キャンセルおよび返金の可否は、会員の依頼を元に特定商取引法に基づく表示の販売主体者が決定するものとし当社は一切関与せず責任を負わないものとします。<br>
    また会員・出品者間で返品・返金が両社同意の元発生したとしても当社に支払った本サービス利用料、手数料、アフィリエイターへの報酬、その他金銭の返還はなく、返金の際の手数料を含め出品者が負担することとします。<br>
    実際キャンセル・返品・返金を行うとする場合、当社は一切責任を負わないものとし、万一トラブルが生じた場合には、会員と出品者の間で解決するものとします。<br>
    法令によるクーリング・オフ対象商品は、購入後のキャンセルが生じる可能性があることを出品者は理解するものとします。</p>
    <p>過入金、誤入金を行ってしまった場合、会員は当社へと問い合わせの連絡を行ってください。過入金、誤入金で当社から会員に向けて連絡はいたしません。</p>
    <p>過入金、誤入金の確認が取れた場合、会員の指定した国内の口座に返金にのみ対応致します。また振込事務手数料は会員が負担するものとします。過入金、誤入金が複数件に渡る場合は、合算して送金することとします。</p>
    <p>また、資金のプール・蓄積、取り置き前払い、他注文への充当なども受付いたしません。振込日より3ヵ月経過しても連絡がない場合は、返金を行いません。</p>
    <p>会員が誤って商品を購入した場合でも、出品者および当社は返品、解約など、取引に関する責任を負わないものとします。</p>


    <h3>第19条（承認）</h3>
    <p>出品者は以下の事項についても承諾するものとします。</p>
    <ol>
        <li>アフィリエイトシステム販売が標準機能になっていること。</li>
        <li>アフィリエイトシステムに1ティア制を採用しアフィリエイト報酬とすること。</li>
        <li>本件サイトにおいてもアフィリエイト報酬が発生すること。</li>
        <li>出品者より譲渡された商品やサービスには、当社が適切と思われる変更、修正、改善を行う場合があること。</li>
        <li>当社が出品者より譲渡された商品やサービスのテストダウンロードを実施すること。</li>
    </ol>


    <h3>第20条（機密保持）</h3>
    <p>当社および会員は、本規約の内容および本契約に関連して開示を受けたまたは知り得た相手方の技術上、販売上その他業務上の一切の情報（以下「機密情報」という）につき最大限の注意をもって秘密を保持し、事前に相手方の書面による承諾を得ることなく第三者に開示・漏洩してはならないものとします。ただし、公知の事実もしくは当事者が独自に知り得た事項についてはこの限りではありません。なお、当社および会員は自社の従業員に機密を保持すべき義務を遵守させるため、適切な措置をとるものとします。<br>
    また、会員は、本契約に基づき提供された情報を元に、競業する事業を行わないものとし、これに反した場合は、本規約第２4条の規定に関わらずその現実に発生した損害の賠償に加えて、当該違反行為に起因してその発生を阻害された当社の得べかりし利益（逸失利益）についても、賠償する責任を負うものとします。</p>


    <h3>第21条（裁判管轄）</h3>
    <p>本規約は日本法に準拠し、それに則り解釈されます。</p>


    <p>株式会社ソリッドアライズ</p>
    <p>2021年11月03日:改定<br>
    2021年10月23日:制定</p>
</div>  
</template>
<script>
export default {
    
}
</script>