var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent statistics" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("統計情報")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vue-table" },
        [
          _c("div", { staticClass: "table-option" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.itemFind.apply(null, arguments)
                  }
                }
              },
              [
                _c("div", { staticClass: "pc_cont" }, [
                  _c("div", [
                    _c("div", [_vm._v("期間")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keyword.startDate,
                          expression: "keyword.startDate"
                        }
                      ],
                      attrs: { type: "date" },
                      domProps: { value: _vm.keyword.startDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.keyword,
                            "startDate",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v("〜"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keyword.endDate,
                          expression: "keyword.endDate"
                        }
                      ],
                      attrs: { type: "date" },
                      domProps: { value: _vm.keyword.endDate },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.keyword, "endDate", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "category-select" }, [
                    _c("div", [_vm._v("カテゴリー")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.keyword.category,
                            expression: "keyword.category"
                          }
                        ],
                        attrs: { type: "text" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.keyword,
                              "category",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "", selected: "" } }),
                        _vm._v(" "),
                        _vm._l(_vm.categories, function(category, index) {
                          return _c(
                            "option",
                            { key: index, domProps: { value: category.name } },
                            [_vm._v(_vm._s(category.name))]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", [
                    _c("div", { staticClass: "pc_cont" }, [_vm._v("商品名")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keyword.item,
                          expression: "keyword.item"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.keyword.item },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.keyword, "item", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pc_cont" }, [
                    _c("div", [_vm._v("ID検索")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.keyword.id,
                          expression: "keyword.id"
                        }
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.keyword.id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.keyword, "id", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "csv_download" },
              [
                _c(
                  "vue-json-to-csv",
                  {
                    attrs: {
                      "json-data": _vm.csvData,
                      "csv-title": "統計情報",
                      labels: _vm.labels
                    }
                  },
                  [
                    _c("button", { on: { click: _vm.downloadCSV } }, [
                      _vm._v("csvでダウンロード")
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "item-total" }, [
            _c("div", [
              _c("div", { staticClass: "total-text" }, [_vm._v("表示数")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.totalDidplayCounts))])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "total-text" }, [_vm._v("購入数")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.totalSalesQuantity))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "pc_cont" }, [
              _c("div", { staticClass: "total-text" }, [_vm._v("購入額")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.price(_vm.totalBuyPrice)))])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "total-reward" }, [
              _c("div", { staticClass: "total-text" }, [_vm._v("報酬額")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.price(_vm.totalReward)))])
            ])
          ]),
          _vm._v(" "),
          _c("vuetable", {
            ref: "vuetable",
            staticClass: "table",
            attrs: {
              noDataTemplate: "統計情報がありません。",
              "api-mode": false,
              fields: _vm.fields,
              "per-page": _vm.perPage,
              "data-manager": _vm.dataManager,
              "append-params": _vm.moreParams,
              "pagination-path": "pagination"
            },
            on: { "vuetable:pagination-data": _vm.onPaginationData },
            scopedSlots: _vm._u([
              {
                key: "created_at",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(_vm.createDay(props.rowData.created_at)))
                  ])
                }
              },
              {
                key: "uuid",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(props.rowData.uuid))
                  ])
                }
              },
              {
                key: "item_name",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell item-name" }, [
                    _vm._v(
                      _vm._s(_vm._f("omittedText")(props.rowData.item_name))
                    )
                  ])
                }
              },
              {
                key: "selling_price",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(_vm.price(props.rowData.selling_price)))
                  ])
                }
              },
              {
                key: "display_counts",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(props.rowData.display_counts))
                  ])
                }
              },
              {
                key: "sales_quantity",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(props.rowData.sales_quantity))
                  ])
                }
              },
              {
                key: "buy_price",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(_vm.buyPrice(props.rowData)))
                  ])
                }
              },
              {
                key: "price_ratio",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(_vm.priceRatio(props.rowData)) + "%")
                  ])
                }
              },
              {
                key: "reward",
                fn: function(props) {
                  return _c("div", { staticClass: "table-cell" }, [
                    _vm._v(_vm._s(_vm.price(props.rowData.reward)))
                  ])
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagenation" },
            [
              _c("vuetable-pagination", {
                ref: "pagination",
                on: { "vuetable-pagination:change-page": _vm.onChangePage }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("検索")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }