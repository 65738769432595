var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-area" },
    [
      _c("side-menu", { staticClass: "side-menu" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "contents-area" },
        [
          _c("g-header"),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "slide-body", appear: "" } },
            [
              _c("router-view", {
                staticClass: "container",
                on: { loading: _vm.setLoader }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("g-footer", { staticClass: "auto-footer" })
        ],
        1
      ),
      _vm._v(" "),
      _c("g-loader", { attrs: { visible: _vm.loading } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }