<style lang="scss" scoped>
@import "../../../sass/variables";
.item-area {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 1rem;
    .item-thumb {
        width : 100%;
    }
    .publish-day {
        margin-right: 2rem;
        font-size: 10px;
    }
    .category {
        padding: 4px 18px;
        font-size: 10px;
        border: 1px solid $border-color;
        border-radius: 25px;
        color: $gray-font;
    }
    .title {
        margin: 15px 0;
        color: #565656;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .seller {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #ccc;
            float: left;
        }
        .user {
            font-size: 12px;
            padding: 16px 10px;
            display: block;
            margin-left: 55px;
            color: $gray-font;
        }
    }
    .money {
        margin-top: 1rem;
        .pay-type {
            font-size: 15px;
            color: $black-font;
            font-weight: bold;
            margin-right: 5px;
        }
        .price {
            font-size: 18px;
            color: $blue-font;
            font-weight: bold;
        }
    }
}
.pay-area {
    padding: 1rem;
    background-color: #fff;
    border-bottom: 1px solid $border-color;
    .error-box {
        border: 2px solid $red-font;
        padding: 1rem;
        margin: 0 0 2rem;
        .error-title {
            color: $red-font;
            font-size: 1.1rem;
            font-weight: bold;
        }
        .error-description {
            color: $red-font;
            margin: 0;
        }
    }
}
// 購入部分
.priceBox {
    padding: 32px 35px;
    background-color: #fff;
    border: 2px solid $color-main-free;
    h3 {
        font-size: 17px;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }
    .v-pricebox-op {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $black-font;
        padding: 10px 0 5px;
        margin-bottom: 20px;
        span {
            letter-spacing: 1px;
            font-weight: bold;
            color: $black-font;
            &.price {
                font-size: 20px;
                color: $blue-font;
            }
        }
    }
    .buyButton {
        display: block;
        text-align: center;
        padding: 10px 0;
        background-color: $color-main-free;
        color: #fff;
        border-radius: 25px;
        font-size: 14px;
        letter-spacing: 1px;
        margin: auto;
        width: 65%;
        border: 1px solid white;
        border-radius: 25px;
        height: 50px;
        &:disabled {
            color: $gray-font;
            background-color: #eaeaea;
            border-color: #eaeaea;
            cursor:unset;
        }
    }
    .warn-msg {
        color : $red-font;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">お支払い</h1>
        <div class="row" v-show="!first">
            <div class="col-sm-8">
                <!-- 商品情報 -->
                <div class="item-area row">
                    <div class="col-sm-4">
                        <img class="item-thumb" :src="item.item_image" />
                    </div>
                    <div class="col-sm-8">
                        <div>
                            <span class="publish-day">{{item.updated_at}}</span>
                            <span class="category">{{categoryName}}</span>
                        </div>
                        <div class="title">{{item.item_name}}</div>
                        <div class="seller">
                            <img :src="item.seller_img">
                            <span class="user">{{item.seller_name}}</span>
                        </div>
                        <div class="money">
                            <span class="pay-type">{{payTypeName}}</span>
                            <span class="price">{{price}}</span>
                        </div>
                    </div>
                </div>
                <!-- 購入方法情報 -->
                <div class="pay-area row" v-show="!notSell">
                    <div v-show="errMsgs" class="error-box">
                        <p class="error-title">{{errMsgs.description}}</p>
                        <p class="error-description">{{errMsgs.nextUserAction}}</p>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-check">
                            <label class="form-check-label"><input class="form-check-input" type="radio" v-model="payMode" value="0"> クレジット</label>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="credit-info" v-show="payMode == '0'">
                            <credit-area :item="item" @decide-token="getToken"></credit-area>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 購入情報 -->
            <div class="col-sm-4">
                <div class="priceBox">
                    <h3>商品合計(税込)</h3>
                    <div class="v-pricebox-op">
                        <span class="v-p-op">小計</span>
                        <span>{{price}}</span>
                    </div>
                    <div class="v-pricebox-op">
                        <span class="v-p-op">合計</span>
                        <span class="price">{{price}}</span>
                    </div>						
                    <button type="button" class="buyButton" @click="decide" v-if="!notSell" :disabled="!canBuy">注文を確定する</button>
                    <div class="warn-msg" v-show="notSell">{{notSellMsg}}</div>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal"></msg-modal>
</div>
</template>
<script>
import dayjs from "dayjs"; 
import {mapGetters, mapActions} from "vuex";
import Api from "../../common/fetch-wrapper";
import {isEmpty,num2Yen} from "../../common/com-func";
import {USER_ARIES} from "../../store/modules/user-store";
import {CACHE_ARIES} from "../../store/modules/cache-store";
import CreditInput from "../../components/credit-input";
import MsgModal from "../../components/msg-modal";

export default {
    components: {
        "msg-modal" : MsgModal,
        "credit-area" : CreditInput
    },
    data:function() {
        return {
            first:true,
            signal: {},
            errMsgs: "",
            itemId: "",
            item: {
                id: "",
                uuid: "",
                item_name : "", 
                item_image : "",  
                selling_price : "",
                saling_fee_price : "",
                earning_price : "",
                affiliate_price : "",
                category_id : "", 
                payment_id : "",
                bank_id : null,
                owner_id : "",
                ordered : false,
                seller_name: "",
                seller_img: "",
                updated_at: ""
            },
            cardToken: "", 
            payMode: "0"
        };
    },
    computed: {
        ...mapGetters(USER_ARIES, ["origin"]),
        ...mapGetters(CACHE_ARIES,["categoryMap", "paymentMap"]),
        categoryName:function() {
            if(!isEmpty(this.item.category_id) && this.categoryMap[this.item.category_id]) {
                return this.categoryMap[this.item.category_id];
            }
            return "";
        },
        payTypeName:function() {
            if(!isEmpty(this.item.payment_id) && this.paymentMap[this.item.payment_id]) {
                return this.paymentMap[this.item.category_id];
            }
            return "";
        },
        price:function() {
            return num2Yen(this.item.selling_price);
        },
        notSell:function() {
            return this.item.owner_id == this.origin.id;
        },
        notSellMsg:function() {
            if(this.item.owner_id == this.origin.id) {
                return "自身の出品商品のため購入できません。";
            }
            return "";
        },
        canBuy:function() {
            if(this.payMode === "0" && isEmpty(this.cardToken)) {
                return false;
            }
            return true;
        }
    },
    methods: {
        ...mapActions(CACHE_ARIES,["loadCategories","loadPayments"]),
        editItem:function(item) {
            this.item.id = item.id;
            this.item.uuid = item.uuid;
            this.item.item_name = item.item_name;
            this.item.item_image = item.item_image;
            this.item.selling_price = item.selling_price;
            this.item.saling_fee_price = item.selling_price * 0.1
            this.item.category_id = item.category_id;
            this.item.payment_id = item.payment_id;
            this.bank_id = item.bank_id;
            this.item.updated_at = dayjs(item.updated_at).format("YYYY/MM/DD");
            this.item.owner_id = item.member_id;
            this.item.ordered = item.ordered == 1;
            if(item.member) {
                this.item.seller_name = item.member.seller_name;
                this.item.seller_img = item.member.image_icon;
            }
            this.first = false;
            if(this.item.uuid == this.$cookies.get('itemCookie')) {
                this.item.affiliate_price = (item.selling_price-this.item.saling_fee_price) * item.affiliate_margin * 0.01
                this.item.earning_price = (item.selling_price-this.item.saling_fee_price) - this.item.affiliate_price
            } else {
                this.item.earning_price = item.selling_price-this.item.saling_fee_price
            }
        },
        getToken:function(x) {
            this.cardToken = x.token.id;
            console.log(x.token);
        },
        decide:function() {
            let message = "注文を確定します。よろしいですか？";
            if(this.item.ordered) {
                message = "こちらは一度購入したことがある商品です。再度購入を行いますか？";
            }
            this.signal = {
                title: "確認",
                message: message,
                callback:() => {
                    this.$emit("loading", true);
                    Api.post("/payment/order", this.createForm(), (d) => {
                        if(d.ok) {
                            d.json().then(x => {
                                location.href = "/order/complete/" + this.payMode + "/" + x.orderId;
                            });
                            return;
                        }
                        if(d.status === 400 || d.status === 422 || d.status === 500) {
                            const { getDeclineDescription } = require('stripe-utils')
                            const { code } = getDeclineDescription("try_again_later")
                            this.errMsgs = code.translations.ja_JP;
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        },
        decideForce:function(tid) {
            this.$emit("loading", true);
            this.cardToken = tid;
            Api.post("/payment/order", this.createForm(), (d) => {
                if(d.ok) {
                    d.json().then(x => {
                        location.href = "/order/complete/" + this.payMode + "/" + x.orderId;
                    });
                    return;
                }
                if(d.status === 400 || d.status === 422 || d.status === 500) {
                    const { getDeclineDescription } = require('stripe-utils')
                    const { code } = getDeclineDescription("try_again_later")
                    this.errMsgs = code.translations.ja_JP;
                }
            }).finally(() => {
                this.$emit("loading", false);
            });
        },
        createForm:function() {
            const form = new FormData();
            form.append("item_id", this.item.id);
            form.append("card_token", this.cardToken);
            form.append("pay_type", this.payMode)
            if(this.item.uuid == this.$cookies.get('itemCookie')) {
                form.append("partner_id", this.$cookies.get('partnerCookie'));
            }
            form.append("affiliate_price", this.item.affiliate_price);
            form.append("earning_price", this.item.earning_price);
            return form;
        }
    },
    mounted:function() {
        this.itemId = this.$route.params.id;
        this.$emit("loading",true);
        Promise.all([
            Api.get("/item/api/" + this.itemId),
            this.loadCategories(),
            this.loadPayments()
        ]).then(x => {
            if(isEmpty(x[0].id)) {
                location.href = "/";
                return;
            }
            this.editItem(x[0]);
        }).finally(() => {
            this.$emit("loading",false);
        });
    }
}
</script>