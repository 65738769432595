var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c(
      "nav",
      {
        staticStyle: { "--bs-breadcrumb-divider": "'>'" },
        attrs: { "aria-label": "breadcrumb" }
      },
      [
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            {
              staticClass: "breadcrumb-item",
              on: {
                click: function($event) {
                  return _vm.move("/")
                }
              }
            },
            [_vm._v("トップ")]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass: "breadcrumb-item active",
              attrs: { "aria-current": "page" }
            },
            [_vm._v("マイページ")]
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "row" }, [
        _c("h1", { staticClass: "page-title" }, [_vm._v("お知らせ")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "regMail regContent col-12" },
          [
            _vm._l(this.news, function(news, index) {
              return _c("div", { key: index, staticClass: "news" }, [
                _c(
                  "p",
                  {
                    on: {
                      click: function($event) {
                        return _vm.move(`/news/${news.id}`)
                      }
                    }
                  },
                  [
                    _c("span"),
                    _vm._v(_vm._s(news.start_date) + ": " + _vm._s(news.name))
                  ]
                )
              ])
            }),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.noNewsFlag,
                    expression: "noNewsFlag"
                  }
                ],
                staticClass: "no-news"
              },
              [_vm._v(_vm._s(_vm.noNewsText))]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "link-news" }, [
              _c(
                "p",
                {
                  on: {
                    click: function($event) {
                      return _vm.move("/news")
                    }
                  }
                },
                [_c("span"), _vm._v("一覧はこちら")]
              )
            ])
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }