var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("会員ログイン")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg row" }, [
        _c("div", { staticClass: "regMail regContent col-sm-6" }, [
          _c("h2", [_vm._v("メールアドレスでログイン")]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.error,
                  expression: "error"
                }
              ],
              staticClass: "alert alert-danger",
              attrs: { role: "alert" }
            },
            [_vm._v(_vm._s(_vm.errorMessage))]
          ),
          _vm._v(" "),
          _c(
            "form",
            {
              staticClass: "regMailForm",
              attrs: { action: "/login", method: "post" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.token,
                    expression: "token"
                  }
                ],
                attrs: { type: "hidden", name: "_token" },
                domProps: { value: _vm.token },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.token = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "submit", value: "ログインする", required: "" }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "like-link right",
              on: {
                click: function($event) {
                  return _vm.move("/password/reset")
                }
              }
            },
            [_vm._v("パスワードをお忘れの方はこちら")]
          ),
          _vm._v(" "),
          _c("h2", { staticStyle: { color: "red" } })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "loginOther col-12" }, [
          _c("h2", [_vm._v("初めてご利用になるお客様")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "link",
              on: {
                click: function($event) {
                  return _vm.move("/register")
                }
              }
            },
            [_vm._v("無料会員登録はこちら")]
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(2)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("メールアドレス")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-lg",
        attrs: {
          type: "text",
          name: "email",
          placeholder: "info@example.com",
          required: ""
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mb-3" }, [
      _c("label", { staticClass: "form-label" }, [_vm._v("パスワード")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control form-control-lg",
        attrs: {
          type: "password",
          name: "password",
          placeholder: "パスワード",
          required: ""
        }
      })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contentAfter" }, [
      _c("br", { staticStyle: { clear: "both" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }