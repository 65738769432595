<style lang="scss" scoped>
.scroll.high {
    h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    h4{
        font-size: 14px;
        margin-bottom: 10px;
    }
    ul {
        padding-left: 22px;
        font-size: 13px;
        list-style: disc;
        margin-top: 5px;
        margin-bottom: 10px;
    }
}
@media screen and (max-width: 896px) {
    .scroll.high {
        h3 {
            font-size: 14px;
        }
        p {
            font-size: 12px;
        }
    }
}
</style>
<template>
<div class="scroll high">
    <h3>第1条（本規約について）</h3>
    <p>会員利用規約（以下「本規約」といいます。）は、株式会社ソリッドアライズ（以下「当社」といいます。）が第２条１項で規定する会員に対して提供する、Digitab販売システム・アフィリエイトシステム・メールシステムおよびそれらに関連したサポートサービス（以下総称して「本サービス」といいます。）の利用について定めます。</p>
    <p>第2条で定義される会員は、本規約と当社が定めるプライバシーポリシーの内容に同意して、本サービスを利用するものとします。</p>
    <p>本規約は、本サービスとの利用に関して当社と会員との間に発生する一切の関係に適用します。</p>


    <h3>第2条（定義）</h3>
    <ul>
        <li>本規約に定める語句および定義は以下のとおりとします。</li>
        <li>「利用者」とは、本サービスを閲覧・利用する全ての個人・法人を指します。</li>
        <li>「会員」とは、本規約に同意して登録を申し込み、出品者（3項）・パートナー（4項）を含むアカウント（5項）を開設した者をいいます。</li>
        <li>「出品者」とは、当社との間で本サービスに関して、別に定める出品者利用規約に同意した会員のうち、本サービスを利用し、商品やサービスの販売等、電子商取引を行う会員をいいます。</li>
        <li>「パートナー」とは、本サービスに関する利用規約に同意して登録を申し込み、当社の承諾によりアフィリエイトシステム（6項）においてアフィリエイターとして登録された会員を指します。</li>
        <li>「アカウント」とは、本サービスにおいて、別に定めるパートナー利用規約に同意した会員で管理画面など本サービスの特定領域を利用するために、会員・出品者・パートナー会員に対して発行される資格のことをいいます。</li>
        <li>「アフィリエイトプログラム」とは、パートナーがウェブサイトその他の媒体からそれを訪れる訪問者に出品者の運営・管理するサイトへ訪れるように誘導し、訪問者に商品・サービスを購入させることとし、訪問者を紹介された出品者はパートナーに対し、当該成果に応じて各プログラムに規定された成功報酬を支払う仕組みをいいます。</li>
        <li>本規約は、当社が運営する、本サービスの会員全てにたいしての利用条件を定めるものです。</li>
    </ul>


    <h3>第3条（本規約の改定）</h3>
    <p>当社は、本規約を任意に改定することができるものとし、会員はこれを承諾するものとします。</p>
    <p>改定後の規約については、当社が別途定める場合を除き、当社ウェブサイトに提示した時点より効力を生じるものとします。</p>


    <h3>第4条（規約の適用範囲）</h3>
    <p>本規約は、本サービスの利用に関して当社と会員との間における関係性全てに適用します。</p>


    <h3>第5条（サービスの内容）</h3>
    <p>当社は利用者に決済サービス、アフィリエイトシステムおよび各種インターネットサービスを提供するものとします。</p>
    <p>本サービスにおいて、出品者には利用料金が発生しますが、会員には利用料金は発生しません。</p>
    <p>当社は、会員に対し、第１項に関するサポートサービスを提供します。<br>
    会員から当社に支払われた金額について払い戻し等を行わないものとします。</p>
    <p>会員が決済方法を第三者に不正利用されたことにより発生した会員の不利益および損害について、当社は何らの責任も負わないものとします。</p>


    <h3>第6条（会員登録）</h3>
    <p>本サービスを会員として利用したい方は、本規約の内容を確認後、会員登録の申し込みを行なってください。なお、お電話・FAXによる会員登録申込みは原則として受け付けておりません。</p>
    <p>会員希望者が新規登録をクリックして申し込み、当社が当社規定に従って審査のうえで承諾し，当社からの本登録完了の電子メールの送信をもって、会員登録は完了します。</p>
    <p>以下の各号を全て満たす場合でなければ、会員登録はできません。</p>

    <h4>１．利用者は、当社に対し、利用者並びに利用者の役員および従業員が、次の各号のいずれにも該当しないことを表明し、かつ将来に わたっても該当しないことを確約する。</h4>
    <ol>
        <li>暴力団、暴力団員、暴力団員でなくなったときから5年を経過しない者、暴力団準構 成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下「暴力団員等」という。）</li>
        <li>暴力団員等が経営を支配し、又は暴力団員等が経営に実質的に関与していると認められる関係を有すること</li>
        <li>不当に暴力団員等を利用していると認められる関係を有すること</li>
        <li>暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること</li>
        <li>その他暴力団員等と社会的に非難されるべき関係を有すること</li>
    </ol>

    <h4>２．利用者は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約する。</h4>
    <ol>
        <li>暴力的な要求行為</li>
        <li>法的な責任を超えた不当な要求行為</li>
        <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為</li>
        <li>風説を流布し、偽計を用い又は威力を用いて乙の信用を毀損し、又は乙の業務を妨害する行為</li>
        <li>その他前各号に準ずる行為</li>
    </ol>

    <h4>①会員資格</h4>
    <p>本規約に同意のうえ所定の会員登録の申し込みをされた個人および法人は、所定の会員登録の申し込み後、一定の手順後会員としての資格を有します。<br>
    会員登録手続は、会員となるご本人が行ってください。代理による登録は一切認められません。なお、過去に会員資格が取り消された方や当社が相応しくないと判断した方からの会員登録の申し込みについてはお断りする場合があります。</p>
    <p>申請を拒否されたことに関して発生したあらゆる事態に対し、当社では一切の責任は負わず、当該の申請拒否に関する理由等の説明や公開の義務を負わないものとします。</p>
    <p>本サービスは18歳以上の、日本語を理解し、読み書きできる方で、当社の本規約を遵守することを約束していただける方であれば誰でも申し込むことができます。ただし、18歳以上20歳未満の方は、法定代理人の同意が必要です。</p>

    <h4>②会員情報の入力</h4>
    <p>会員登録手続の際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。</p>
    <p>当社は次の各号に該当する場合、会員のニックネーム使用を制限できるものとし、ログイン停止または削除の処理を行う場合があります。</p>
    <p>公序良俗に反するニックネーム<br>
    他の会員および第三者のプライバシーを侵害する恐れのあるニックネーム<br>
    当社が相応しくないと判断した方若しくは暴力団関係者からの会員申込</p>


    <h3>第7条（登録情報の変更）</h3>
    <p>会員は自らの登録情報に変更が生じた場合には、速やかに管理画面の会員情報を変更・修正するものとします。</p>
    <p>当社は、会員が第1項に定める変更登録を行なわなかったことにより生じた損害について、その一切の責任を負わないものとします。</p>
    <p>また、変更登録がなされた場合でも、変更登録前にすでに手続がなされた取引は変更登録前の情報に基づいて行われます。このような取引について登録情報の変更内容を出品者に通知する必要がある場合には、会員より出品者に直接ご連絡下さい。</p>


    <h3>第8条（権利の譲渡の禁止）</h3>
    <p>会員のログイン情報に関しては、第三者に貸し出しまたは売却、譲渡することはできません。ログイン情報の管理は、会員が責任を負うものとし、ログイン情報を使用して行われた全ての行為は、理由の如何を問わずそのログイン情報を登録した会員が全ての責任を負います。</p>


    <h3>第9条（サービス利用料 および銀行振込手数料）</h3>
    <p>決済サービス利用料は、出品者が負担するものとし、出品者が販売を行う商品およびサービスが会員に販売されるごとに手数料が発生しますが、会員に利用料・手数料などの費用は発生しません。<br>
    なお、購入の際に銀行振込を選択された場合の銀行振込手数料は購入を行い会員が負担するものとします。<br>
    また返品・返金に伴う銀行振込手数料の負担に関しては、会員と出品者が話し合いの元決定するものとし、当社は一切責任を負わないものとします。</p>


    <h3>第10条（会員の退会）</h3>
    <p>当社は、理由を問わず、支払われた料金の払い戻しは行いません。<br>
    会員が退会を希望する場合には、当社に対し会員本人が会員情報の削除依頼を行ってください。所定の退会手続の終了後に、退会となります。なお、会員が5,000円未満の未払の譲渡対価が発生している状態で退会申請をされた場合、 当月末締め翌々月10～15日に登録指定口座に振込手数料を控除した金額を振り込むものとします。</p>
    <p>当社は、下記の事由が発生した場合、当該会員に何ら通知することなく、ただちに本サービスの利用を一時停止し、または、登録解除することができます。</p>
    <ul>
        <li>本規約に違反した場合。</li>
        <li>違法行為を行なった場合。</li>
        <li>会員宛の電子メールが届かない等の理由により、電子メールによる連絡が不能と判断された場合。</li>
        <li>その他当社が必要と判断した場合。</li>
    </ul>
    <p>本条により会員に損害が生じたとしても、当社は一切の賠償責任を負わないものとします。</p>
    <p>会員を退会した場合、当該会員がそれまでに購入した商品およびサービスを再入手することはできません。また購入・出品・パートナーに属するすべての情報を閲覧することができなくなります。</p>


    <h3>第11条（パスワードの管理等）</h3>
    <p>当社が会員に発行したパスワードについては、会員はこれを第三者に開示または漏洩することのないように十分な注意義務をもって取り扱うものとします。パスワードの管理の不備、使用上の過誤、第三者の使用による損害および責任は、会員がすべて負担するものとします。</p>
    <p>パスワードを他者に利用された場合又はその管理を怠ったために損害が発生した場合の責任は全て会員自身が負うものとします。</p>
    <p>第三者が会員のパスワードを用いて本サービスを利用した場合には、当社はこれを会員本人によるサービスの利用とみなし、それらが盗用、不正使用その他の事情により会員以外の者が利用し会員が損害を被ったとしても、その一切の賠償責任を負わないものとします。</p>


    <h3>第12条（個人情報の取扱い）</h3>
    <p>当社は本サービスの運営につき会員の個人情報を取得した場合、当社の定めるプライバシーポリシーに基づいて個人情報を取り扱います。</p>
    <p>当社が会員から個人情報を取得する目的は特段の表示がない限り次の通りであり、会員は利用目的について同意するものとします。</p>
    <ul>
        <li>会員の本人確認及び各種連絡事項等本サービスの提供のため。</li>
        <li>会員の注文情報を、会員が注文した相手方出品者に提供するため。</li>
        <li>当社の業務遂行及び当社のサービス向上の目的のためにマーケティングデータとして使用するため。</li>
        <li>当社の提供するサービスや他のサービスに関する情報等の案内や広告のため。</li>
    </ul>
    <p>会員は、本サービスに関連して知り得た当社、出品者、パートナー会員及び他の会員の技術上、営業上、業務上等有用な情報を、登録期間中のみならず登録期間経過後においても、第三者に漏洩してはならないものとします。但し、既知となっている情報は除くものとします。</p>


    <h3>第13条（取引およびキャンセル・返品・返金に関して）</h3>
    <p>キャンセルおよび返金の可否は、会員の依頼を元に特定商取引法に基づく表示の販売主体者が決定するものとし当社は一切関与せず責任を負わないものとします。<br>
    また会員・出品者間で返品・返金が両社同意の元発生したとしても当社に支払った本サービス利用料、手数料、アフィリエイターへの報酬、その他金銭の返還はなく、返金の際の手数料を含め出品者が負担することとします。<br>
    実際キャンセル・返品・返金を行うとする場合、当社は一切責任を負わないものとし、万一トラブルが生じた場合には、会員と出品者の間で解決するものとします。<br>
    法令によるクーリング・オフ対象商品は、購入後のキャンセルが生じる可能性があることを出品者は理解するものとします。</p>
    <p>会員が銀行振込を選択肢、入金が営業日3日を経過しても入金が行われなかった場合、商品の購入がキャンセルされます。この場合会員・出品者・パートナーに手数料などは発生せず、また報酬も発生しません。</p>
    <p>過入金、誤入金を行ってしまった場合、会員は当社へと問い合わせの連絡を行ってください。過入金、誤入金で当社から会員に向けて連絡はいたしません。</p>
    <p>過入金、誤入金の確認が取れた場合、会員の指定した国内の口座に返金にのみ対応致します。また振込事務手数料は会員が負担するものとします。過入金、誤入金が複数件に渡る場合は、合算して送金することとします。</p>
    <p>また、資金のプール・蓄積、取り置き前払い、他注文への充当なども受付いたしません。振込日より3ヵ月経過しても連絡がない場合は、返金を行いません。</p>
    <p>会員が誤って商品を購入した場合でも、出品者および当社は返品、解約など、取引に関する責任を負わないものとします。</p>


    <h3>第14条（サービスの中断・停止等）</h3>
    <p>当社は、本サービスを常に良好な状態でご利用いただくために、次の各号に該当する事由が生じたときは，システムの保守作業を行います。</p>
    <ul>
        <li>システムに負荷が集中した場合</li>
        <li>サービスの運営に支障が生じると当社が判断した場合</li>
        <li>会員のセキュリティを確保する必要が生じた場合</li>
        <li>その他当社が保守の必要があると判断した場合</li>
    </ul>
    <p>当社は，前項に定める保守を実施する際に必要があるときは，事前に通知することなく、本サービスの全部または一部の提供を中断または停止する等の必要な措置を取ることができるものとします。この場合に会員に生じた損害について、当社は一切責任を負わないものとします。</p>


    <h3>第15条（利用者に対する通知方法）</h3>
    <p>当社は会員に対する通知または告知はE-mailによる通知その他当社が適当と認める方法により行うことができるものとします。<br>
    前項に基づくE-mailによる通知は当社が会員の登録メールアドレスに通知を発したとき到達したものとします。</p>


    <h3>第16条（データの保存について）</h3>
    <p>会員は，ダウンロードしたデータを，自己の責任と負担において保存，管理するものとします。</p>
    <p>当社は、ダウンロードしたデータの保存，管理について一切の責任を負わず，データの毀損，滅失によって会員に損失が生じた場合でも，一切補償いたしません。</p>


    <h3>第17条（禁止行為）</h3>
    <p>会員は，次の各号に定める行為を行なってはなりません。</p>
    <ul>
        <li>法令または本規約に違反する行為。</li>
        <li>当社または第三者の権利、利益、名誉等を損ねる行為。</li>
        <li>青少年の心身に悪影響を及ぼす恐れがある行為、その他公序良俗に反する行為。</li>
        <li>他の会員その他の第三者に迷惑となる行為や不快感を抱かせる行為。</li>
        <li>虚偽の情報を入力する行為。</li>
        <li>有害なコンピュータプログラム、メール等を送信または書き込む行為。</li>
        <li>当社のサーバーその他のコンピュータに不正にアクセスする行為。</li>
        <li>アカウントを第三者に貸与・譲渡すること、または第三者と共用する行為。</li>
        <li>同一の個人または法人による、複数の会員登録行為。</li>
        <li>故意・過失に関わらず、当社または第三者の営業を妨害する行為、またはそのおそれのある行為。</li>
        <li>申込の際に法定代理人の同意等を得ずに登録する行為。</li>
        <li>18歳未満であるのに会員として登録する行為。</li>
        <li>18歳以上20歳未満であり、申込の際に法定代理人の同意を得ずに登録する行為。</li>
        <li>クレジットカードの不正利用その他不正な決済行為。</li>
        <li>根拠のないクレーム、苦情を呈する行為。</li>
        <li>当サービスを利用した取引に関して、販売者である出品者へ月に3回以上、キャンセル、返品、返金依頼を行なう行為。</li>
        <li>パートナー会員に対する報酬の発生を目的とした不正な注文および無料オファーへの応募行為。</li>
        <li>商品の購入および無料オファーへの応募に応じて、パートナー会員から現金や金券などの還元、付与を受ける行為。</li>
        <li>本サービスの利用によって知り得た本サービス、当社、出品者及びパートナー会員に関する技術上、営業上、業務上等有用な情報を、第三者に開示、提供または漏洩する行為。</li>
        <li>本サービスに関連して、本サービス外での販売契約を目的として、出品者と直接取引を行う行為。</li>
        <li>digi-tab.netドメインからのメールを受信しない行為。</li>
        <li>その他前各号に準じる行為。</li>
    </ul>
    <p>禁止行為に該当するか否かは当社が事前に定めた判断基準に従って判断するものとします。<br>
    各号に違反した場合は、アカウントの停止ないし退会の処置が為される場合があります。</p>


    <h3>第18条（免責）</h3>
    <p>当サービスは本サービスの利用により会員に特定の効果が生じることを保証するものではありません。</p>
    <p>システム及び決済サービスの中断・遅滞・中止・データの毀損，滅失、データへの不正アクセスその他当社の責めによらない事由により会員に生じた損害について、当社は一切責任を負わないものとします。</p>
    <p>天災地変等当社の責に帰さない事由により生じた損害、当社の予見の有無を問わず、特別の事情から生じた損害、逸失利益を含む間接損害について賠償責任を負わないものとします。</p>
    <p>当社のウェブページ、サーバー、ドメインなどから送られる電子メール、コンテンツにコンピューターウイルス等の有害なものが含まれていないこと、またこれらのためのセキュリティ方法が十分に提供されていることについて、当社は一切保証いたしません。</p>
    <p>当社は、会員に対し、次に掲げる損害及びそれに付随する２次的なデータの漏洩、損失等の損害について責任を負わないものとします。</p>
    <ol>
        <li>当社が提供していないプログラムによって生じる損害。</li>
        <li>当社以外の第三者による不正な行為によって生じる損害。</li>
        <li>ハッカー及びクラッカーによるサーバーへの侵入または攻撃等の行為による損害。</li>
        <li>当社が本サービスのために用いるサーバーにおいて生じた事情により生じる損害。</li>
        <li>本サービスのバグや障害によって生じる損害。決済サービス・サーバーのバグや障害によって生じる損害。</li>
    </ol>
    <p>当社は、本サービスに会員がアップロードするデータ・情報に関しては、一切責任を負わないものとします。本サービスにアップロードするデータ・情報の正確性や当該情報をアップデートする責任は、全て会員にあります。</p>
    <p>出品者、出品者との取引内容・取扱商品・商品およびサービス・ページ上の記載内容・各種コンテンツの内容、出品者における個人情報の取扱いなどにつきましては、当該出品者に直接お問合せください。<br>
    これらに関する内容の真偽、正確性、最新性、有用性、信頼性、適法性、安全性、第三者の権利を侵害していないことについて、当社は一切保証いたしません。<br>
    訪問者のcookie情報が常に正常に反映されることについて、当社は一切保証いたしません。</p>
    <p>本サービスにおける情報等が、完全性、正確性、有用性を有することを、当社は一切保証いたしません。<br>
    当社は、会員が本規約に違反したことによって生じた損害について一切の責任を負わないものとします。<br>
    会員・出品者・パートナー会員の三者間において紛争が生じた場合には、当事者間で解決するものとし、当社は、一切の責任を負わないものとします。</p>
    <p>当社は、本サイトでの広告の表示・掲載を、第三者について行う場合があります。会員が、本サイトでの広告を通じて第三者から商品・サービスを購入する等、第三者と何らかの契約をされる場合、当該契約の当事者は会員と第三者であり、当社はいかなる責任も負いません。</p>


    <h3>第19条（連絡方法）</h3>
    <p>会員と当社の間の連絡は、電子メール、郵送、電話等の通信手段にて行われるものとし、連絡手段が電子メールや郵送の場合には当社から発信してから通常到達すべき時に会員に到達したものとみなします。</p>
    <p>会員は第1項に定める連絡を拒否できないものとします。ただし、当社から会員に本サービスに関する情報などのメールマガジンその他の広告メールを配信する場合には、配信前に会員から事前の承諾を得るものとします。</p>


    <h3>第20条（サービスの停止、変更、修正、追加、削除）</h3>
    <p>当社は、その判断によりいつでも本サービスの内容を停止、変更、修正、追加、削除することができるものとします。</p>


    <h3>第21条（損害賠償）</h3>
    <p>当社は、会員が本規約に違反することによって当社に損害（合理的な範囲での弁護士費用を含みますが、これに限られません。）が発生した場合、当該会員に対し、賠償を請求することができ、会員は、当該請求に直ちに応じなければならないものとします。</p>


    <h3>第22条（知的財産権の帰属及びライセンス）</h3>
    <p>当社や各出品者が会員に提供するコンテンツ、技術、すべての画像（バナーや標章なども含みます。以下総称して「コンテンツ等」といいます）に関する著作権、商標権、意匠権その他の知的財産権（以下総称して「知的財産権等」といいます）は、すべて提供する側に帰属するものとし、会員は商品ないしサービスの購入の範囲内でのみその利用を許諾されているものとします。</p>
    <p>また、 会員は、コンテンツ等の利用を第三者に許諾することはできず、権利者の書面による事前の許可なくして，コンテンツ等の内容に一切の修正・変更はできないものとします。</p>
    <p>会員と第三者との間で知的財産権等に関する紛争が生じた場合、当社は一切の責任を負わないものとし、当該紛争により当社が損害を被ったときは，当社は当該会員に対して損害賠償請求を行なうことができるものとします。</p>


    <h3>第23条（本規約の効力）</h3>
    <p>本規約は、会員が本サービスの会員登録をした時点から会員の登録解除時点まで当社及び会員を拘束します。<br>
    ただし、第11条、第12条、第20条、第22条、第23条は、会員の登録解除後も当社及び会員を拘束します。</p>


    <h3>第24条（分離可能性）</h3>
    <p>本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>


    <h3>第25条（裁判管轄）</h3>
    <p>本規約は日本法に準拠し、それに則り解釈されます。</p>

    <p>株式会社ソリッドアライズ</p>
    <p>2021年11月16日:改定<br>
    2021年11月03日:改定<br>
    2021年10月23日:改定<br>
    2021年10月02日:制定</p>
</div>  
</template>
<script>
export default {
    
}
</script>