var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "news-index" }, [
    _c("h1", [_vm._v("お知らせ")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "vue-table" },
      [
        _c("vuetable", {
          ref: "vuetable",
          staticClass: "table",
          attrs: {
            noDataTemplate: "お知らせがありません。",
            "api-mode": false,
            fields: _vm.fields,
            "per-page": _vm.perPage,
            "data-manager": _vm.dataManager,
            "pagination-path": "pagination"
          },
          on: { "vuetable:pagination-data": _vm.onPaginationData },
          scopedSlots: _vm._u([
            {
              key: "start_date",
              fn: function(props) {
                return _c("div", { staticClass: "start_date" }, [
                  _vm._v(_vm._s(props.rowData.start_date))
                ])
              }
            },
            {
              key: "name",
              fn: function(props) {
                return _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(props.rowData.name))
                ])
              }
            },
            {
              key: "body",
              fn: function(props) {
                return _c("div", { staticClass: "body" }, [
                  _vm._v(_vm._s(_vm._f("omittedText")(props.rowData.body)))
                ])
              }
            },
            {
              key: "actions",
              fn: function(props) {
                return _c(
                  "div",
                  { staticClass: "action" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: `/news/${props.rowData.id}` } },
                      [_c("button", [_vm._v("詳細")])]
                    )
                  ],
                  1
                )
              }
            }
          ])
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pagenation" },
          [
            _c("vuetable-pagination", {
              ref: "pagination",
              on: { "vuetable-pagination:change-page": _vm.onChangePage }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }