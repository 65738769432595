var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pageContent" }, [
    _c("div", { staticClass: "content no-login" }, [
      _c("h1", { staticClass: "page-title" }, [_vm._v("パスワード再設定")]),
      _vm._v(" "),
      _c("div", { staticClass: "reg row" }, [
        _c("div", { staticClass: "regMail regContent col-sm-6" }, [
          _c("h2", [_vm._v("新しいパスワードを入力してください。")]),
          _vm._v(" "),
          _c("div", { staticClass: "regMailForm" }, [
            _c("div", { staticClass: "mb-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "pass-rule" }, [
                _vm._v("8文字以上の半角英数字で入力してください。")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member.password,
                    expression: "member.password"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "password", name: "password" },
                domProps: { value: _vm.member.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.member, "password", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.eMessage
                ? _c("div", { staticClass: "e-message" }, [
                    _vm._v("8文字以上の半角英数字で入力してください。")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.member.password_confirmation,
                    expression: "member.password_confirmation"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "password", name: "password_confirmation" },
                domProps: { value: _vm.member.password_confirmation },
                on: {
                  input: function($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.member,
                      "password_confirmation",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _vm.eMessage2
                ? _c("div", { staticClass: "e-message" }, [
                    _vm._v("入力されたパスワードが異なっています。")
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "mt-3",
                attrs: { type: "submit", required: "" },
                on: { click: _vm.submitRegist }
              },
              [_vm._v("再設定")]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("パスワード"),
      _c("span", [_vm._v("※")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "form-label" }, [
      _vm._v("パスワード再入力"),
      _c("span", [_vm._v("※")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }