<style lang="scss" scoped>

$font-color : #808080;
.mainFooter {
	background-color: #222226;
	.contentFooter {
		max-width: 768px;
		margin: 0 auto;
		padding: 40px 0;
		display: flex;
	}
	.footerMenu-content {
		color: #fff;
		width: 50%;
		display: flex;
		margin-top: 40px;
		ul li {
			list-style: none;
			color: $font-color;
			font-size: 13px;
			padding-bottom: 6px;
			cursor: pointer;
			&:before {
				content: "◆";
				padding-right: 5px;
			}
			span {
				text-decoration: underline;
				a {
					color: #808080;
				}
			}
		}
	}
	.footerMenu-logo, .footerMenu-content {
		width: 50%;
	}
	.footerMenu-logo {
		span {
			display: block;
			width:140px;
			margin:40px 30px 0;
			cursor: pointer;
			img {
				width: 100%;
			}
		}
	}
	.copyright {
		padding: 20px 0;
		p {
			text-align: center;
			color: $font-color;
			font-size: 13px;
			font-weight: bold;
		}
	}
}
@media screen and (max-width: 896px) {
	.mainFooter {
		.contentFooter {
			.footerMenu-content {
				ul {
					padding: 0 0 5px;
				}
			}
		}
		.copyright {
			p {
				font-size: 10px;
			}
		}
	}
}
</style>
<template>
<footer class="mainFooter">
	<div class="contentFooter">
		<div class="footerMenu-logo">
			<span @click="move('/')"><img src="/img/digitab-logo-white.png" alt="ロゴ" ></span>
		</div>
		<!-- 下部固定 -->
		<div class="footerMenu-content">
			<ul class="menu">
				<li><span @click="move('/news')">お知らせ</span></li>
				<!-- <li><span @click="move('/guidance')">ご利用ガイド</span></li>
				<li><span @click="move('/inquery')">よくある質問</span></li> -->
				<li><span @click="move('/service/term')">会員利用規約</span></li>
				<li><span @click="move('/service/term-seller')">出品者利用規約</span></li>
				<li><span @click="move('/service/term-partner')">パートナー利用規約</span></li>
				<li><span @click="move('/service/policy')">プライバシーポリシー</span></li>
				<li><span @click="move('/service/law')">特定商取引法に基づく表示</span></li>
				<li><span><a href='https://digi-tab-support.com/' target="blanc">ヘルプ</a></span></li>
				<li><span><a href='https://digi-tab-support.com/help-contact.html' target="blanc">お問い合わせ</a></span></li>
			</ul>
		</div>
	</div>
	<div class="copyright">
		<p>COPYRIGHT ©DIGITAB , ALL RIGHT RESERVED</p>
	</div>
</footer>
</template>
<script>
export default {
	methods :{
		move:function(path) {
			if(this.$router.currentRoute.path !== path) {
				this.$router.push({path:path});
			}
		}
	}
}
</script>