<style lang="scss" scoped>
@import "../../../sass/variables";
.page-title {
    &:before{
        border-left: $color-main-member 4px solid;
    }
}
.form-control {
    height: 30px;
}
.content {
    padding: 0 1rem;
}
.btn-primary.main {
    min-width: 150px;
    background-color: $color-main-member;
    border-color: $color-main-member;
    &:disabled {
        color: $gray-font;
        background-color: #eaeaea;
        border-color: #eaeaea;
        cursor:unset;
    }
}
</style>
<template>
<div class="pageContent">
    <div class="content no-login">
        <h1 class="page-title">クレジット情報</h1>
        <div class="reg">
            <div class="regContent">
                <div class="row">
                    <div class="col-sm-6">
                        <!-- 入力コンポーネント -->
                        <credit-area @decide-token="getToken"></credit-area>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-primary main" @click="deleteCredit" :disabled="disableDecide">削除</button>
                        <button type="button" class="btn btn-primary main" @click="submitRegist" :disabled="disableDecide">保存</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <msg-modal :signal="signal"></msg-modal>
    <msg-toast :signal="toastMsg"></msg-toast>
</div>
</template>
<script>
import {mapGetters} from "vuex";
import { isEmpty } from '../../common/com-func';
import Api from "../../common/fetch-wrapper";
import {USER_ARIES} from "../../store/modules/user-store";
import MsgModal from "../../components/msg-modal";
import MsgToast from "../../components/msg-toast";
import CreditInput from "../../components/credit-input";

export default {
    components: {
        "credit-area" : CreditInput,
        "msg-modal" : MsgModal,
        "msg-toast" : MsgToast
    },
    data: function (){
        return {
            creditToken:{},
            errMsgs: {},
            signal: {},
            toastMsg: {}
        };
    },
    computed: {
        ...mapGetters(USER_ARIES,["stripePubKey"]),
        disableDecide:function() {
            return isEmpty(this.creditToken.id);
        }
    },
    methods: {
        getToken:function(x) {
            this.creditToken = x.token;
        },
        deleteCredit:function() {
            this.signal = {
                title: "確認",
                message: "クレジット情報を削除します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    Api.delete("/member/credit/delete", (d) => {
                        if(d.ok) {
                            this.$router.go({path: this.$router.currentRoute.path, force: true})
                            this.toastMsg = {message:"削除が完了しました。"};
                            return;
                        }
                    })
                    this.$emit("loading", false);
                }
            };
        },
        submitRegist:function() {
            this.signal = {
                title: "確認",
                message: "クレジット情報を更新します。よろしいですか？",
                callback:() => {
                    this.$emit("loading", true);
                    this.errMsgs = {};
                    const form = new FormData();
                    form.append("card_token", this.creditToken.id);
                    Api.put("/member/credit", form, (d) => {
                        if(d.ok) {
                            this.$router.go({path: this.$router.currentRoute.path, force: true})
                            this.toastMsg = {message:"更新が完了しました。"};
                            return;
                        }
                        if(d.status === 400 || d.status === 422) {
                            d.json().then(x => {
                                this.errMsgs = x.errors;
                            });
                        }
                    }).finally(() => {
                        this.$emit("loading", false);
                    });
                }
            };
        }
    },
    mounted:function() {
        
    }

}
</script>